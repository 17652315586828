@charset "utf-8";

html {height:100%;}
body {margin:0;padding:0;height:100%;}

* {letter-spacing:-1px;line-height:100%;box-sizing: border-box;margin:0;padding:0;}

article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {display:block}

#hd ul, nav ul, #ft ul {margin:0;padding:0;list-style:none}
legend {position:absolute;margin:0;padding:0;font-size:0;line-height:0;text-indent:-9999em;overflow:hidden}
label, input, button, select, img {vertical-align:middle; margin-bottom: 0px !important;}
input, button {margin:0 !important;padding:0;font-size:1em}
button {cursor:pointer}

select {margin:0;outline: none;}
p {margin:0;padding:0;word-break:break-all}
hr, .sr-only {display:none}
pre {overflow-x:scroll;font-size:1.1em}
a:link, a:visited, a:focus, a:active {text-decoration:none}
a:hover {text-decoration:underline}
a {text-decoration: none !important; color:#333;}
table {border-collapse: collapse;}
ul {
	padding: 0px !important;
	margin: 0px !important;
	list-style-type: none;
}

.clearfix::after {
  content: "";
  display: block;
  clear: both;
}

.cp {cursor:pointer;}

.cb {clear:both;}
.fl {float:left;}
.fr {float:right !important;}

.vt {vertical-align:top;}
.vm {vertical-align:middle;}
.vb {vertical-align:bottom;}

.of_auto{overflow:auto;}
.of_scroll{overflow:scroll !important;}

.bn {background:none !important; border:0 none !important;}

.fb {font-weight:bold;}
.fbr {font-weight:bolder;}
.fn {font-weight:normal;}

.pr {position:relative;}
.pa {position:absolute;}

.di {display:inline;}
.db {display:block;}

.brnon{border:none;}
.bold {font-weight: bold;}

::placeholder {
  color: #999;
}

.accessibility{visibility:hidden; position:absolute; overflow:hidden; height:0; width:0; font-size:0; margin:0; padding:0;}

/* font */
.fgd {
  font-family: guilm, dotum !important;
}

.f8 {font-size:8px !important;}
.f10{font-size:10px !important;}
.f11{font-size:11px !important;}
.f12{font-size:12px !important;}
.f13{font-size:13px !important;}
.f14{font-size:14px !important;}
.f15{font-size:15px !important;}
.f16{font-size:16px !important;}
.f17{font-size:17px !important;}
.f18{font-size:18px !important;}
.f19{font-size:19px !important;}
.f20{font-size:20px !important;}
.f21{font-size:21px !important;}
.f22{font-size:22px !important;}
.f24{font-size:24px !important;}
.f25{font-size:25px !important;}
.f28{font-size:28px !important;}
.f30{font-size:30px !important;}
.f32{font-size:32px !important;}
.f36{font-size:36px !important;}
.f42{font-size:42px !important;}
.f48{font-size:48px !important;}
.f54{font-size:54px !important;}

.ls_01 {letter-spacing:-1px;}
.ls_02 {letter-spacing:-2px;}
.ls_001 {letter-spacing:1px;}
.ws_01 {word-spacing:-1px;}
.ws_001 {word-spacing:1px;}

.wwp {word-wrap:break-word;}

/*margin,padding*/
.m0 {margin:0}
.m10 {margin:10px}
.m20 {margin:20px}

.mt0 {margin-top:0px !important;}
.mt2 {margin-top:2px !important;}
.mt3 {margin-top:3px !important;}
.mt4 {margin-top:4px !important;}
.mt5 {margin-top:5px !important;}
.mt7 {margin-top:7px !important;}
.mt8 {margin-top:8px !important;}
.mt10 {margin-top:10px !important;}
.mt12 {margin-top:12px !important;}
.mt13 {margin-top:13.5px !important;}
.mt14 {margin-top:14px !important;}
.mt15 {margin-top:15px !important;}
.mt16 {margin-top:16px !important;}
.mt17 {margin-top:17px !important;}
.mt19 {margin-top:19px !important;}
.mt20 {margin-top:20px !important;}
.mt25 {margin-top:25px !important;}
.mt30 {margin-top:30px !important;}
.mt35 {margin-top:35px !important;}
.mt40 {margin-top:40px !important;}
.mt45 {margin-top:45px !important;}
.mt50 {margin-top:50px !important;}
.mt53 {margin-top:53px !important;}
.mt55 {margin-top:55px !important;}
.mt60 {margin-top:60px !important;}
.mt70 {margin-top:70px !important;}
.mt80 {margin-top:80px !important;}

.mr0 {margin-right:0px !important;}
.mr2 {margin-right:2px !important;}
.mr3 {margin-right:3px !important;}
.mr4 {margin-right:4px !important;}
.mr5 {margin-right:5px !important;}
.mr6 {margin-right:6px !important;}
.mr10 {margin-right:10px !important;}
.mr12 {margin-right:12px !important;}
.mr15 {margin-right:15px !important;}
.mr20 {margin-right:20px !important;}
.mr25 {margin-right:25px !important;}
.mr30 {margin-right:30px !important;}
.mr35 {margin-right:35px !important;}
.mr40 {margin-right:40px !important;}
.mr45 {margin-right:45px !important;}
.mr55 {margin-right:55px !important;}
.mr60 {margin-right:60px !important;}

.mb0 {margin-bottom:0px !important;}
.mb3 {margin-bottom:3px !important;}
.mb4 {margin-bottom:4px !important;}
.mb5 {margin-bottom:5px !important;}
.mb10 {margin-bottom:10px !important;}
.mb11 {margin-bottom:11px !important;}
.mb15 {margin-bottom:15px !important;}
.mb17 {margin-bottom:17px !important;}
.mb18 {margin-bottom:18px !important;}
.mb20 {margin-bottom:20px !important;}
.mb21 {margin-bottom:21px !important;}
.mb25 {margin-bottom:25px !important;}
.mb30 {margin-bottom:30px !important;}
.mb35 {margin-bottom:35px !important;}
.mb40 {margin-bottom:40px !important;}
.mb45 {margin-bottom:45px !important;}
.mb50 {margin-bottom:50px !important;}
.mb70 {margin-bottom:70px !important;}
.mb80 {margin-bottom:80px !important;}

.ml0 {margin-left:0px !important;}
.ml2 {margin-left:2px !important;}
.ml3 {margin-left:3px !important;}
.ml5 {margin-left:5px !important;}
.ml6 {margin-left:6px !important;}
.ml7 {margin-left:7px !important;}
.ml10 {margin-left:10px !important;}
.ml15 {margin-left:15px !important;}
.ml17 {margin-left:17px !important;}
.ml18 {margin-left:18px !important;}
.ml19 {margin-left:19px !important;}
.ml20 {margin-left:20px !important;}
.ml25 {margin-left:25px !important;}
.ml30 {margin-left:30px !important;}
.ml35 {margin-left:35px !important;}
.ml40 {margin-left:40px !important;}
.ml42 {margin-left:42px !important;}
.ml45 {margin-left:45px !important;}
.ml50 {margin-left:50px !important;}
.ml52 {margin-left:52px !important;}
.ml55 {margin-left:55px !important;}
.ml60 {margin-left:60px !important;}
.ml63 {margin-left:63px !important;}
.ml65 {margin-left:65px !important;}
.ml80 {margin-left:80px !important;}
.ml120 {margin-left:120px !important;}

.pl0 {padding-left:0px !important;}
.pl3 {padding-left:3px !important;}
.pl5 {padding-left:5px !important;}
.pl7 {padding-left:7px !important;}
.pl10 {padding-left:10px !important;}
.pl15 {padding-left:15px !important;}
.pl20 {padding-left:20px !important;}
.pl23 {padding-left:23px !important;}
.pl25 {padding-left:25px !important;}
.pl28 {padding-left:28px !important;}
.pl30 {padding-left:30px !important;}
.pl35 {padding-left:35px !important;}
.pl45 {padding-left:45px !important;}
.pl55 {padding-left:55px !important;}
.pl100 {padding-left:100px !important;}
.pl120 {padding-left:120px !important;}
.pl150 {padding-left:150px !important;}
.pl200 {padding-left:200px !important;}

.pr0 {padding-right:0px !important;}
.pr3 {padding-right:3px !important;}
.pr5 {padding-right:5px !important;}
.pr8 {padding-right:8px !important;}
.pr10 {padding-right:10px !important;}
.pr15 {padding-right:15px !important;}
.pr17 {padding-right:17px !important;}
.pr20 {padding-right:20px !important;}
.pr25 {padding-right:25px !important;}
.pr27 {padding-right:27px !important;}
.pr30 {padding-right:30px !important;}
.pr35 {padding-right:35px !important;}
.pr40 {padding-right:40px !important;}
.pr50 {padding-right:50px !important;}
.pr56 {padding-right:56px !important;}
.pr60 {padding-right:60px !important;}
.pr65 {padding-right:65px !important;}

.pt0 {padding-top:0px !important;}
.pt1 {padding-top:1px !important;}
.pt2 {padding-top:2px !important;}
.pt3 {padding-top:3px !important;}
.pt4 {padding-top:4px !important;}
.pt5 {padding-top:5px !important;}
.pt6 {padding-top:6px !important;}
.pt7 {padding-top:7px !important;}
.pt8 {padding-top:8px !important;}
.pt10 {padding-top:10px !important;}
.pt12 {padding-top:12px !important;}
.pt15 {padding-top:15px !important;}
.pt16 {padding-top:16px !important;}
.pt18 {padding-top:18px !important;}
.pt20 {padding-top:20px !important;}
.pt25 {padding-top:25px !important;}
.pt30 {padding-top:30px !important;}
.pt35 {padding-top:35px !important;}
.pt40 {padding-top:40px !important;}
.pt50 {padding-top:50px !important;}
.pt120 {padding-top:120px !important;}
.pt181 {padding-top:181px}

.pb0 {padding-bottom:0px !important;}
.pb3 {padding-bottom:3px !important;}
.pb5 {padding-bottom:5px !important;}
.pb6 {padding-bottom:6px !important;}
.pb7 {padding-bottom:7px !important;}
.pb8 {padding-bottom:8px !important;}
.pb10 {padding-bottom:10px !important;}
.pb15 {padding-bottom:15px !important;}
.pb20 {padding-bottom:20px !important;}
.pb25 {padding-bottom:25px !important;}
.pb30 {padding-bottom:30px !important;}
.pb35 {padding-bottom:35px !important;}
.pb40 {padding-bottom:40px !important;}
.pb45 {padding-bottom:45px !important;}
.pb50 {padding-bottom:50px !important;}
.pb70 {padding-bottom:70px !important;}

.p0 {padding:0 !important}
.p2 {padding:2px !important;}
.p3 {padding:3px !important;}
.p5 {padding:5px !important;}
.p7 {padding:7px;}
.p8 {padding:8px;}
.p10 {padding:10px !important;}
.p15 {padding:15px !important;}
.p20 {padding:20px;}
.p30 {padding:20px;}

.w5p {width:5%;}
.w10p {width:10%;}
.w13p {width:13%;}
.w14p {width:14%;}
.w15p {width:15%;}
.w17p {width:17%;}
.w18p {width:18%;}
.w20p {width:20%;}
.w24p {width:24%;}
.w25p {width:25%;}
.w28p {width:28%;}
.w30p {width:30%;}
.w33p {width:33%;}
.w40p {width:40%;}
.w44p {width:44%;}
.w45p {width:45%;}
.w49p {width:49%;}
.w50p {width:50%;}
.w55p {width:55%;}
.w56p {width:56%;}
.w60p {width:60% !important;}
.w70p {width:70%;}
.w80p {width:80%;}
.w82p {width:82%;}
.w83p {width:83%;}
.w85p {width:85%;}
.w87p {width:87%;}
.w86p {width:86%;}
.w90p {width:90%;}
.w92p {width:92%;}
.w93p {width:93%;}
.w95p {width:95%;}
.w100p {width:100%;}

.h100 {height: 100% !important;}
.h320p {height: 320px !important;}

.w400px {width:400px !important}
.w150px {width:150px !important}
.w100px {width:100px !important}
.w80px {width:80px !important}

.lh100 {line-height:100% !important;}
.lh130 {line-height:130% !important;}
.lh140 {line-height:140% !important;}
.lh150 {line-height:150% !important;}
.lh160 {line-height:160% !important;}
.lh170 {line-height:170% !important;}
.lh180 {line-height:180% !important;}
.lh190 {line-height:190% !important;}
.lh200 {line-height:200% !important;}
.lh350 {line-height:350% !important;}
.lh450 {line-height:450% !important;}

.fta {font-family:"tahoma"}
.fd {font-family:"Dotum"}
.fng {font-family:"Nanum Gothic";}
.fngb {font-family:"Nanum Gothic Bold";}
.fngex {font-family:"Nanum Gothic Bold"; letter-spacing:-1px;}

.cblue1 {color:#3d5995;}
.cblue2 {color:#0052af;}
.cwhite {color:#fff !important;}
.clight {color:#999 !important;}
.clight:hover {color:#555 !important;}
.cblack {color:#000 !important;}
.cred {color:red;}
.cblue {color:blue;}
.clightgrey {color:#aaa;}
.cbdarkblue {background-color:#202e47 !important;}
.cbdarkblue:hover {background-color:#141c2c !important;}
.cbcyan {background-color:#93a2ae;}
.cblightgrey1 {background-color:#f8f8f8;}
.cblightgrey {background-color:#f0f0f0;}

.none, .hidden, .blind{display:none;}

.bt {border-top:solid 1px #ccc !important;}
.bb {border-bottom:solid 1px #ccc !important;}
.bbline > td {border-bottom:solid 1px #555 !important;}
.bb1 {border-bottom:solid 1px #eee !important;}
.bl {border-left:solid 1px #ccc !important;}
.br {border-right:solid 1px #ccc !important;}
.ba {border:1px solid #ccc !important;}
.bn {border:none;}

.ma {margin:0 auto;}

.center {text-align:center !important;}
.right {text-align:right !important;}
.left {text-align:left !important;}

.fh20{height:20px !important;}
.fh25{height:25px !important;}
.fh30{height:30px !important;}
.fh35{height:35px !important;}
.fh40{height:40px !important;}
.fh50{height:50px !important;}
.fh60{height:60px !important;}
.fh70{height:70px !important;}
.fh80{height:80px !important;}
.fh680{height:680px !important;}

/* ad-grid Start */
.ag-cell130h {line-height:130px !important;}
.ag-cell150h {line-height:150px !important;}
/* ad-grid End */

.btn-small {padding:4px 9px 5px 9px !important;font-size:12px !important;}
.btn-small > span {font-size:12px !important;}
.btn-small-addon {
    padding: 3px 9px 2px 9px !important;
    font-size: 12px !important;
}
.btn-small1 {padding:1px 9px !important;font-size:12px !important;}

.alert-area {
  position:fixed;
  right:20px;
  top:20px;
  max-width:380px;
  min-width:380px;
  z-index:10000000000000000;
  opacity: 0.9 !important;
  -moz-opacity: 0.9 !important;
  -khtml-opacity: 0.9 !important;
  -ms-filter:"progid:DXImageTransform.Microsoft.Alpha(Opacity=90)" !important;
  filter: alpha(opacity=90) !important;
}

.alert {
  -webkit-box-shadow: 2px 3px 5px 1px rgba(0,0,0,0.3);
  -moz-box-shadow: 2px 3px 5px 1px rgba(0,0,0,0.3);
  box-shadow: 2px 3px 5px 1px rgba(0,0,0,0.3);
}

.indicator {
  position:fixed;
  z-index:9999999999;
  left:0px;
  top:0px;
  bottom:0px;
  width:100%;
  height:100%;
  background:url(/assets/images/loading_ani.gif) no-repeat 50% 50%;
  background-color:rgba(0, 0, 0, 0.3);
}

.lstn {list-style-type:none;padding-left:0px;}

select.form-control-small {
  height: 29px !important;
  font-size:12px !important;
}

.form-control-small {
  padding:4px 12px 4px 12px !important;
  height:auto !important;
  font-size:12px !important;
}

.table-small > thead > tr > th {
  background-color:#f2f2f2;
  padding:9px 0px 9px 0px !important;
}

.table-small > tbody > tr > th {
  text-align:right;
  vertical-align:middle;
  font-weight:normal;
  padding:0px 20px 3px 3px !important;
  background-color:#f2f2f2;
}
.table-small > tbody > tr > th,
.table-small > tbody > tr > td {
  padding:3px;
  font-size:14px;
  font-family: "Noto Sans KR", gulim;
}

.table-small > tbody > tr > th > .material-icons {
  vertical-align: bottom !important;
}

.formErrors,
textarea.formErrors,
select.formErrors,
input.formErrors {
  background-color:#ffeaea !important;
  border:solid 1px #ffaeae !important;
}

.error-message-none {
  display:hidden;
}

.error-message {
  position:relative;
  top:-21px;
  text-align:right;
  padding-right:10px;
  font-size:12px;
  color:red;
  height:0px;
  pointer-events: none;
}

.error-message-hidden {
  font-size: 12px;
  color: red;
  display: inline-block;
  line-height: 25px;
  background-color: #ffe9e9 !important;
  width: 100%;
  height: 100%;
  padding-right: 10px;
  margin-top: 5px;
  border: solid 1px #ff9090;
  box-sizing:border-box;
  text-align:right;
}

.error-message-addon {
  font-size: 12px;
  color: red;
  display: inline-block;
  line-height: 25px;
  background-color: #ffe9e9 !important;
  height: 100%;
  padding-right: 10px;
  margin-top: 5px;
  border: solid 1px #ff9090;
  box-sizing:border-box;
  text-align:right;
}

.modal-xl {
  max-width:1000px;
  width:1000px;
}

.btn-primary {
    color: #fff;
    background-color: #337ab7;
    border-color: #2e6da4;
}

.btn-success {
    color: #fff;
    background-color: #5cb85c;
    border-color: #4cae4c;
}

.btn-info {
    color: #fff;
    background-color: #5bc0de;
    border-color: #46b8da;
}

.btn-warning {
    color: #fff;
    background-color: #f0ad4e;
    border-color: #eea236;
}

.btn-danger {
    color: #fff;
    background-color: #d9534f;
    border-color: #d43f3a;
}

.btn-default:hover {
  background-color: #eeeeee;
}

.btn-default {
  font-size: 14px;
  background-color: #fff;
  border-color: #ccc;
  /*padding: 3px 12px;*/
}
.btn-icon {
  width: 28px;
  height: 28px;
  background-color: #fff;
  border: 1px solid #ccc !important;
}
.btn-icon1 {
  width: 20px;
  height: 20px;
  background-color: #fff;
  border: 1px solid #ccc !important;
}
.btn-icon1 i {
  vertical-align: top;
  padding-top: 3px;
}
.note-modal {
  z-index:999999 !important;
}

.select2-container--open {
  z-index:9999999 !important;
}

.ui-datepicker {
  z-index:999999 !important;
}

.ag-cell-edit {
  background:url(/assets/images/edit.png) no-repeat 0% 50% !important;
  background-size:16px !important;
  padding-left:20px !important;
}

.as-split-gutter {
  background-color:#fff !important;
}

.calendar-input {
  background: url(/assets/images/calendar.png) no-repeat calc(100% - 7px) 50%;
  background-color: #f3f3f5 !important;
  background-size: 1.8vh;
  cursor: pointer;
}

.note-tooltip-arrow {
  width:10px !important;
  height:10px !important;
}

.note-tooltip-content{
  margin-top: 10px;
  margin-left: -75px;
  width: 150px;
  display: inline-block;
}

.ag-theme-balham .ag-row {
  border-width:0px !important;
}

.note-btn {
  width: 30px;
  height: 30px;
  margin: 0px;
}

.popup > ngb-pagination .pagination .page-item .page-link {
  width: 50px;
  height: 100%;
  margin: 0px;
  border-radius: 0px;
  border: 1px solid !important;
}

.cell-wrap-text {
  white-space: normal !important;
}

.bg-color {
  background-color: #f5f5f5;
  height: 50px;
  text-align: center;
  vertical-align : middle;
}

.td {
  height: 40px;
}

.document {
  color: #aaa
}

.list-table td {
  text-align: left;
  padding: 12px 10px;
}
.list-table tr:hover {
  background-color: #fff;
}
.radio label {
  display: inline-block;
  position: relative;
  padding: 0px 5px 0px 5px;
  cursor: pointer;
  line-height: 20px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.radio label input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 18px;
  width: 18px;
  background-color: #fff;
  border: 1px solid #b8b8b8;
  border-radius: 50%;
}

.radio label:hover input ~ .checkmark {
  background-color: #ccc;
}

.radio label input:checked ~ .checkmark {
  background-color: #2196F3;
}

.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.radio label input:checked ~ .checkmark:after {
  display: block;
}

.radio label .checkmark:after {
  top: 5px;
  left: 5px;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background: white;
}

@media print {
	table th {background-color:#f4f5f7 !important; -webkit-print-color-adjust:exact;}
}
