/* 부설에너지기기시험원 - 사업안내 - 국가공인시험검사기관 */
.eatl-business1 {
  padding-bottom: 290px;
}
.eatl-business1 .mobile-only {
  display: none;
}
.eatl-business1 .business1 {
  position: relative;
  height: 500px;
  background: url(/assets/images/eatl_business01.png);
}
.eatl-business1 .business1 .txt {
  font-size: 22px;
  color: #fff;
  line-height: 32px;
  text-align: center;
  padding-top: 125px;
}
.eatl-business1 .business1 .txt span {
  font-weight: bold;
}
.eatl-business1 .business1 .txt-area {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  left: calc(50% - 512px);
  bottom: -90px;
  width: 1024px;
  height: auto;
  padding: 55px 0;
  background: #fff;
  filter: drop-shadow(0px 10px 10px rgba(0, 0, 0, 0.11));
}
.eatl-business1 .business1 .txt-area > div {
  font-size: 20px;
  color: #2b3038;
  font-weight: 300;
  line-height: 28px;
  text-align: left;
}
.eatl-business1 .business1 .txt-area > div span {
  color: #0062b7;
  font-weight: 500;
}
@media screen and (max-width: 1280px) {
  .eatl-business1 .mobile-only {
    display: block;
 }
  .eatl-business1 .pc-only {
    display: none;
 }
  .eatl-business1 .business1 .txt {
    padding-top: 90px;
 }
  .eatl-business1 .business1 .txt-area {
    left: 5%;
    bottom: -210px;
    width: 90%;
 }
}
@media screen and (max-width: 720px) {
  .eatl-business1 {
    padding: 0 0 47rem;
 }  
  .eatl-business1 .business1 {
    height: 50rem;
    background: url(/assets/images/eatl_business01m.png) 50% / contain;
 }
  .eatl-business1 .business1 .txt {
    font-size: 3rem;
    line-height: 4rem;
    padding-top: 9rem;
 }
  .eatl-business1 .business1 .txt span {
    font-weight: bold;
 }
  .eatl-business1 .business1 .txt-area {
    left: 4rem;
    bottom: -38rem;
    width: 64rem;
    padding: 5.5rem 0;
 }
  .eatl-business1 .business1 .txt-area > div {
    font-size: 3rem;
    color: #2b3038;
    line-height: 4rem;
 }
}

/* 부설에너지기기시험원 - 사업안내 - KS표시인증업체협의회 */
.eatl-business2 {
  padding-bottom: 60px;
}
.eatl-business2 .mobile-only {
  display: none;
}
.eatl-business2 .guide-txt {
  font-size: 18px;
  color: #2b3038;
  line-height: 26px;
  text-align: center;
  margin-bottom: 42px;
}
.eatl-business2 .section {
  margin-bottom: 35px;
}
.eatl-business2 .section .title {
  font-size: 22px;
  font-weight: bold;
  color: #2b3038;
  line-height: 68px;
  border-bottom: 2px solid #2b3038;
}
.eatl-business2 .section .title span {
  font-weight: 400;
}
.eatl-business2 .section ul {
  padding: 12px 0 !important;
  border-bottom: 2px solid #e9e9e9;
}
.eatl-business2 .section ul li:after {
  content: "";
  display: block;
  clear: both;
}
.eatl-business2 .section ul li span:nth-child(1) {
  float: left;
}
.eatl-business2 .section ul li span:nth-child(1):before {
  float: left;
  content: '';
  width: 3px;
  height: 3px;
  background-color: #999;
  border-radius: 50%;
  margin: 15px 10px 0 0;
}
.eatl-business2 .section ul li span:nth-child(2) {
  float: left;
  width: calc(100% - 13px);
  font-size: 16px;
  color: #2b3038;
  line-height: 32px;
}
.eatl-business2 .section ul.four li {
  float: left;
  width: calc(100% / 4);
}
.eatl-business2 .section .file-down {
  font-size: 14px;
  color: #2b3038;
  line-height: 36px;
  padding: 12px 0;
  border-bottom: 2px solid #e9e9e9;
}
.eatl-business2 .section .file-down a {
  display: inline-block;
  width: 118px;
  height: 36px;
  border: 1px solid #d7d7d7;
  margin-right: 10px;
  font-weight: 500;
  color: #2b3038;
  line-height: 34px;
  text-align: center;
}
.eatl-business2 .section .file-down a:hover {
  text-decoration: none;
}
@media screen and (max-width: 1024px) {
  .eatl-business2 .section ul.four li {
    width: calc(100% / 2);
 }
}
@media screen and (max-width: 720px) {
  .eatl-business2 {
    padding-bottom: 5rem;
 }
  .eatl-business2 .mobile-only {
    display: block;
 }
  .eatl-business2 .guide-txt {
    font-size: 3rem;
    line-height: 4rem;
    margin-bottom: 4.5rem;
 }
  .eatl-business2 .section {
    margin-bottom: 5rem;
 }
  .eatl-business2 .section .title {
    font-size: 3.6rem;
    font-weight: 500;
    line-height: 9.6rem;
    letter-spacing: -2px;
    border-bottom: 0.4rem solid #2b3038;
 }
  .eatl-business2 .section ul {
    padding: 2.6rem 0 !important;
 }
  .eatl-business2 .section ul li span:nth-child(1):before {
    width: 0.5rem;
    height: 0.5rem;
    margin: 2.7rem 1.5rem 0 0;
 }
  .eatl-business2 .section ul li span:nth-child(2) {
    width: calc(100% - 2rem);
    font-size: 2.8rem;
    line-height: 5.6rem;
 }
  .eatl-business2 .section ul.four li {
    width: 100%;
 }
  .eatl-business2 .section .file-down {
    font-size: 2.8rem;
    line-height: 1;
    padding: 4rem 0;
 }
  .eatl-business2 .section .file-down a {
    display: block;
    width: 23rem;
    height: 7rem;
    margin: 0 0 2rem 0;
    line-height: 7rem;
 }
}

/* 부설에너지기기시험원 - 사업안내 - 안전확인시험검사기관 */
.eatl-business3 {
  padding-bottom: 60px;
}
.eatl-business3 .mobile-only {
  display: none;
}
.eatl-business3 .guide-txt {
  font-size: 18px;
  color: #2b3038;
  line-height: 26px;
  text-align: center;
  margin-bottom: 42px;
}
.eatl-business3 .blue {
  color: #0062b7 !important;
}
.eatl-business3 .section {
  margin-bottom: 35px;
}
.eatl-business3 .section .title {
  font-size: 22px;
  font-weight: bold;
  color: #2b3038;
  line-height: 68px;
  border-bottom: 2px solid #2b3038;
}
.eatl-business3 .section .title span {
  font-weight: 400;
}
.eatl-business3 .section .txt-area {
  padding: 12px 0 !important;
  border-bottom: 2px solid #e9e9e9;
}
.eatl-business3 .section .txt-area li {
  padding-top: 4px;
  margin-bottom: 10px;
}
.eatl-business3 .section .txt-area li .h2 {
  margin-bottom: 5px;
}
.eatl-business3 .section .txt-area li .h2:after {
  content: "";
  display: block;
  clear: both;
}
.eatl-business3 .section .txt-area li .h2 span:nth-child(1) {
  float: left;
}
.eatl-business3 .section .txt-area li .h2 span:nth-child(1):before {
  float: left;
  content: '';
  width: 3px;
  height: 3px;
  background-color: #999;
  border-radius: 50%;
  margin: 10px 10px 0 0;
}
.eatl-business3 .section .txt-area li .h2 span:nth-child(2) {
  float: left;
  width: calc(100% - 13px);
  font-size: 16px;
  font-weight: 500;
  color: #2b3038;
  line-height: 24px;
}
.eatl-business3 .section .txt-area li .txt {
  font-size: 16px;
  color: #2b3038;
  line-height: 20px;
  padding-left: 13px;
}
.eatl-business3 .section .txt-area li .txt .txt-term {
  display: block;
  margin: 12px 0;
}
.eatl-business3 .section .txt-area li .txt1-table {
  width: 630px;
  margin: 8px 0 0 13px;
}
.eatl-business3 .section .txt-area li .txt1-table tr {
  border: 1px solid #e9e9e9;
}
.eatl-business3 .section .txt-area li .txt1-table th {
  width: 250px;
  height: 40px;
  background: #f8f9f9;
  font-size: 14px;
  font-weight: 500;
  color: #2b3038;
  text-align: center;
}
.eatl-business3 .section .txt-area li .txt1-table td {
  height: 40px;
  padding-left: 20px;
  font-size: 14px;
  color: #2b3038;
}
.eatl-business3 .section .txt-area li.txt2 {
  padding: 30px 13px 0;
  margin-top: 25px;
  border-top: 1px solid #e9e9e9;
}
.eatl-business3 .section .txt-area li.txt2 .h2 {
  font-size: 16px;
  font-weight: 500;
  color: #0062b7;
  margin-bottom: 15px;
}
.eatl-business3 .section .txt-area li.txt2 .box {
  padding: 30px;
  background: #f8f9f9;
  border: 1px solid #e9e9e9;
}
.eatl-business3 .section .txt-area li.txt2 .box .h3 {
  font-size: 16px;
  font-weight: 500;
  color: #2b3038;
  margin-bottom: 20px;
}
.eatl-business3 .section .txt-area li.txt2 .box .left-area {
  float: left;
  width: 314px;
  padding-right: 30px;
  border-right: 1px solid #e9e9e9;
}
.eatl-business3 .section .txt-area li.txt2 .box .left-area .img {
  width: 284px;
  height: 165px;
  background: #fff url(/assets/images/eatl_design.png) no-repeat;
}
.eatl-business3 .section .txt-area li.txt2 .box .right-area {
  float: left;
  width: calc(100% - 314px);
  padding-left: 30px;
}
.eatl-business3 .section .txt-area li.txt2 .box .right-area li span:nth-child(1) {
  float: left;
}
.eatl-business3 .section .txt-area li.txt2 .box .right-area li span:nth-child(1):before {
  float: left;
  content: '';
  width: 3px;
  height: 3px;
  background-color: #999;
  border-radius: 50%;
  margin: 10px 10px 0 7px;
}
.eatl-business3 .section .txt-area li.txt2 .box .right-area li span:nth-child(2) {
  float: left;
  width: calc(100% - 20px);
  font-size: 14px;
  color: #2b3038;
  line-height: 24px;
}
.eatl-business3 .section .step {
  padding-top: 30px !important;
}
.eatl-business3 .section .step li {
  position: relative;
  float: left;
  width: calc((100% - 75px)/ 6);
  height: 160px;
  margin-right: 15px;
  background: #fff;
  border: 1px solid #e9e9e9;
}
.eatl-business3 .section .step li .arrow {
  position: absolute;
  top: calc(50% - 10px);
  right: -11px;
  width: 11px;
  height: 20px;
  background: url(/assets/images/triangle.png) no-repeat;
}
.eatl-business3 .section .step li:nth-last-child(1) {
  margin-right: 0;
}
.eatl-business3 .section .step li .no {
  font-family: "Roboto";
  font-size: 14px;
  font-weight: bold;
  color: #0062b7;
  padding: 33px 0 0 25px;
}
.eatl-business3 .section .step li .txt {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 105px;
}
.eatl-business3 .section .step li .txt > div {
  font-size: 16px;
  font-weight: 500;
  color: #2b3038;
  line-height: 22px;
  text-align: center;
}
.eatl-business3 .section .step li .txt > div span {
  font-size: 14px;
  font-weight: 400;
}
@media screen and (max-width: 1000px) {
  .eatl-business3 .section .step li {
    width: calc((100% - 45px)/ 3);
    margin-bottom: 10px;
 }
}
@media screen and (max-width: 720px) {
  .eatl-business3 {
    padding-bottom: 5rem;
 }
  .eatl-business3 .mobile-only {
    display: block;
 }
  .eatl-business3 .guide-txt {
    font-size: 3rem;
    line-height: 4rem;
    margin-bottom: 4.5rem;
 }
  .eatl-business3 .section {
    margin-bottom: 5rem;
 }
  .eatl-business3 .section .title {
    font-size: 3.6rem;
    font-weight: 500;
    line-height: 9.6rem;
    letter-spacing: -2px;
    border-bottom: 0.4rem solid #2b3038;
 }
  .eatl-business3 .section .txt-area {
    padding: 1.4rem 0 2.8rem !important;
    border-bottom: 2px solid #e9e9e9;
 }
  .eatl-business3 .section .txt-area li {
    padding-top: 0.8rem;
    margin-bottom: 1rem;
 }
  .eatl-business3 .section .txt-area li .h2 {
    margin-bottom: 0.9rem;
 }
  .eatl-business3 .section .txt-area li .h2 span:nth-child(1):before {
    width: 0.5rem;
    height: 0.5rem;
    margin: 2.7rem 1.5rem 0 0;
 }
  .eatl-business3 .section .txt-area li .h2 span:nth-child(2) {
    width: calc(100% - 2rem);
    font-size: 2.8rem;
    line-height: 3.6rem;
    padding: 1rem 0;
 }
  .eatl-business3 .section .txt-area li .txt {
    font-size: 2.8rem;
    line-height: 3.8rem;
    padding-left: 2rem;
 }
  .eatl-business3 .section .txt-area li .txt .txt-term {
    display: block;
    margin: 1.4rem 0;
 }
  .eatl-business3 .section .txt-area li .txt1-table {
    width: 100%;
    margin: 1.6rem 0 0 0;
 }
  .eatl-business3 .section .txt-area li .txt1-table th {
    width: 18rem;
    height: 9rem;
    font-size: 2.6rem;
 }
  .eatl-business3 .section .txt-area li .txt1-table td {
    height: 9rem;
    padding-left: 3rem;
    font-size: 2.6rem;
 }
  .eatl-business3 .section .txt-area li.txt2 {
    padding: 4.5rem 0 0;
    margin-top: 3.5rem;
    border-top: 1px solid #e9e9e9;
 }
  .eatl-business3 .section .txt-area li.txt2 .h2 {
    font-size: 2.8rem;
    font-weight: 500;
    color: #0062b7;
    line-height: 3.8rem;
    text-align: center;
    margin-bottom: 3.5rem;
 }
  .eatl-business3 .section .txt-area li.txt2 .box {
    padding: 0;
    background: #f8f9f9;
    border: 1px solid #e9e9e9;
 }
  .eatl-business3 .section .txt-area li.txt2 .box .h3 {
    font-size: 2.8rem;
    font-weight: 500;
    color: #2b3038;
    margin-bottom: 1rem;
 }
  .eatl-business3 .section .txt-area li.txt2 .box .left-area {
    float: none;
    width: 100%;
    padding: 3.5rem 3rem;
    border-right: none;
    border-bottom: 1px solid #e9e9e9;
 }
  .eatl-business3 .section .txt-area li.txt2 .box .left-area .img {
    width: 46rem;
    height: 26.7rem;
    background: #fff url(/assets/images/eatl_design.png) no-repeat 50% / contain;
    margin: 3.2rem auto 1.5rem;
 }
  .eatl-business3 .section .txt-area li.txt2 .box .right-area {
    float: none;
    width: 100%;
    padding: 3.5rem 3rem 2.2rem;
 }
  .eatl-business3 .section .txt-area li.txt2 .box .right-area li span:nth-child(1) {
    float: left;
 }
  .eatl-business3 .section .txt-area li.txt2 .box .right-area li span:nth-child(1):before {
    width: 0.5rem;
    height: 0.5rem;
    margin: 1.8rem 1.5rem 0 0;
 }
  .eatl-business3 .section .txt-area li.txt2 .box .right-area li span:nth-child(2) {
    float: left;
    width: calc(100% - 2rem);
    font-size: 2.8rem;
    color: #2b3038;
    line-height: 3.8rem;
    margin-bottom: 1.3rem;
 }
  .eatl-business3 .section .step {
    padding-top: 4rem !important;
 }
  .eatl-business3 .section .step li {
    position: relative;
    float: left;
    width: 100%;
    height: 30rem;
    margin: 0 0 10rem 0;
    background: #fff;
    border: 1px solid #e9e9e9;
 }
  .eatl-business3 .section .step li .arrow {
    position: absolute;
    top: auto;
    right: auto;
    left: calc(50% - 6rem);
    bottom: calc(-5.9rem + 1px);
    width: 11.7rem;
    height: 5.9rem;
    background: url(/assets/images/trianglem.png) no-repeat 50% / contain;
 }
  .eatl-business3 .section .step li:nth-last-child(1) {
    margin-bottom: 0;
 }
  .eatl-business3 .section .step li .no {
    font-size: 3rem;
    color: #0062b7;
    padding: 6rem 0 0 6rem;
 }
  .eatl-business3 .section .step li .txt {
    height: 15.2rem;
 }
  .eatl-business3 .section .step li .txt > div {
    font-size: 3.6rem;
    line-height: 4.4rem;
 }
  .eatl-business3 .section .step li .txt > div span {
    font-size: 3rem;
 }
}

/* 부설에너지기기시험원 - 사업안내 - 표준개발협력기관 */
.eatl-business4 {
  padding-bottom: 60px;
}
.eatl-business4 .mobile-only {
  display: none;
}
.eatl-business4 .guide-txt {
  font-size: 18px;
  color: #2b3038;
  line-height: 26px;
  text-align: center;
  margin-bottom: 42px;
}
.eatl-business4 .box {
  padding: 28px !important;
  background: #f8f9f9;
  border: 1px solid #e9e9e9;
}
.eatl-business4 .box li span:nth-child(1) {
  float: left;
}
.eatl-business4 .box li span:nth-child(1):before {
  float: left;
  content: '';
  width: 3px;
  height: 3px;
  background-color: #0062b7;
  border-radius: 50%;
  margin: 12px 10px 0 0;
}
.eatl-business4 .box li span:nth-child(2) {
  float: left;
  width: calc(100% - 20px);
  font-size: 15px;
  color: #2b3038;
  line-height: 28px;
}

@media screen and (max-width:1000px) {
  .eatl-business4 .pc-only {display: none;}
}
@media screen and (max-width:720px) {
  .eatl-business4 {
    padding-bottom: 0;
 }
  .eatl-business4 .mobile-only {
    display: block;
 }
  .eatl-business4 .guide-txt {
    font-size: 3rem;
    letter-spacing: -2px;
    line-height: 4rem;
    margin-bottom: 7.5rem;
 }
  .eatl-business4 .box {
    padding: 2.3rem 4rem !important;
    background: #f8f9f9;
    border: none;
    margin: 0 -4rem !important;
 }
  .eatl-business4 .box li span:nth-child(1):before {
    width: 0.5rem;
    height: 0.5rem;
    margin: 2.5rem 1.5rem 0 0;
 }
  .eatl-business4 .box li span:nth-child(2) {
    width: calc(100% - 2rem);
    font-size: 2.8rem;
    line-height: 3.6rem;
    padding: 1rem 0;
 }

 .popup-layer-mobile {
    width:100% !important;
    height:auto !important;
    left:0px !important;
    top:0px !important; 
  }


  .popup-layer-mobile > .popup-preview-contents > img {
    width:100% !important;
  }

  .popup-layer-mobile > .popup-preview-contents > a > img {
    width:100% !important;
  }
}  

/* 부설에너지기기시험원 - 사업안내 - 표준개발협력기관 - COSD */
.eatl-business41 {
  padding-bottom: 60px;
}
.eatl-business41 .section {
  margin-bottom: 35px;
}
.eatl-business41 .section .blue {
  color: #0062b7;
}
.eatl-business41 .section .title {
  font-size: 22px;
  font-weight: bold;
  color: #2b3038;
  line-height: 68px;
  border-bottom: 2px solid #2b3038;
}
.eatl-business41 .section .title span {
  font-weight: 400;
}
.eatl-business41 .section .txt-area {
  padding: 12px 0 !important;
  border-bottom: 2px solid #e9e9e9;
}
.eatl-business41 .section .txt-area li {
  padding-top: 4px;
  margin-bottom: 10px;
}
.eatl-business41 .section .txt-area li .txt {
  margin-bottom: 10px;
}
.eatl-business41 .section .txt-area li .txt:after {
  content: "";
  display: block;
  clear: both;
}
.eatl-business41 .section .txt-area li .txt span:nth-child(1) {
  float: left;
}
.eatl-business41 .section .txt-area li .txt span:nth-child(1):before {
  float: left;
  content: '';
  width: 3px;
  height: 3px;
  background-color: #999;
  border-radius: 50%;
  margin: 10px 10px 0 0;
}
.eatl-business41 .section .txt-area li .txt span:nth-child(2) {
  float: left;
  width: calc(100% - 13px);
  font-size: 16px;
  color: #2b3038;
  line-height: 22px;
}
.eatl-business41 .section .txt-area li .txt1 {
  font-size: 14px;
  color: #2b3038;
  line-height: 20px;
  padding-left: 13px;
}
.eatl-business41 .section .img {
  height: 824px;
  background: url(/assets/images/eatl_business41.png) no-repeat 50%;
  padding: 40px 0;
  border-bottom: 2px solid #e9e9e9;
}
@media screen and (max-width: 1024px) {
  .eatl-business41 .section .img {
    height: 80vw;
    background: url(/assets/images/eatl_business41.png) no-repeat 50% / 95% auto;
 }
}
@media screen and (max-width: 720px) {
  .eatl-business41 {
  padding-bottom: 0px;
}  
  .eatl-business41 .section {
    margin-bottom: 10rem;
 }
  .eatl-business41 .section .title {
    font-size: 3.6rem;
    font-weight: 500;
    line-height: 9.6rem;
    margin-top: 5rem;
 }
  .eatl-business41 .section .txt-area {
    padding: 3rem 0 !important;
 }
  .eatl-business41 .section .txt-area li {
    padding-top: 0.4rem;
    margin-bottom: 1rem;
 }
  .eatl-business41 .section .txt-area li .txt {
    margin-bottom: 1rem;
 }
  .eatl-business41 .section .txt-area li .txt span:nth-child(1):before {
    width: 0.5rem;
    height: 0.5rem;
    margin: 2rem 1.5rem 0 0;
 }
  .eatl-business41 .section .txt-area li .txt span:nth-child(2) {
    width: calc(100% - 2rem);
    font-size: 2.8rem;
    line-height: 3.8rem;
    letter-spacing: -2px;
 }
  .eatl-business41 .section .txt-area li .txt1 {
    font-size: 2.8rem;
    line-height: 3.8rem;
    padding-left: 2rem;
    margin-top: 0.5rem;
 }
  .eatl-business41 .section .img {
    padding: 4rem 0;
 }
}
/* 부설에너지기기시험원 - 사업안내 - 표준개발협력기관 - 국제표준화활동 */
.eatl-business42 .section {
  padding: 25px 0 40px;
}
.eatl-business42 .section .list-table {
  margin-bottom: 60px;
}
.eatl-business42 .section .list-table .col1 {
  width: 200px;
}
.eatl-business42 .section .list-table thead th {
  height: 70px;
}
.eatl-business42 .section .list-table tbody th {
  font-size: 14px;
}
.eatl-business42 .section .list-table td {
  height: auto;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0;
  padding: 17px 20px !important;
  text-align: left;
}
.eatl-business42 .section .list-table td button {
  width: 90px;
  height: 35px;
  background: #fff;
  border: 1px solid #d7d7d7;
  font-size: 14px;
  font-weight: 500;
  color: #2b3038;
  margin: 3px 0;
}
@media screen and (max-width: 720px) {
  .eatl-business42 .section {
    padding: 4.5rem 0 2rem;
 }
  .eatl-business42 .section .list-table {
    margin-bottom: 8rem;
 }
  .eatl-business42 .section .list-table .col1 {
    width: 16.5rem;
 }
  .eatl-business42 .section .list-table thead th {
    font-size: 3rem;
    height: 12rem;
 }
  .eatl-business42 .section .list-table tbody th {
    font-size: 3rem;
    padding: 0 !important;
 }
  .eatl-business42 .section .list-table td {
    height: auto;
    font-size: 3rem;
    line-height: 4rem;
    letter-spacing: -1px;
    padding: 2.4rem 0 2.4rem 2.7rem !important;
    text-align: left;
 }
  .eatl-business42 .section .list-table td button {
    width: 17rem;
    height: 7rem;
    background: #fff;
    border: 1px solid #d7d7d7;
    font-size: 2.8rem;
    font-weight: 500;
    color: #2b3038;
    margin: 3rem 0;
 }
  .eatl-business42 .bottom {
    height: 10rem;
 }
}

/* 부설에너지기기시험원 - 사업안내 - 표준개발협력기관 - 정책대응 */
.eatl-business43 .section {
  padding-top: 25px;
}
.eatl-business43 .list-table tfoot td {
  font-size: 16px;
  font-weight: 500;
  border-left: none;
  border-right: none;
}
.eatl-business43 .btn-area {
  text-align: center;
  margin: 40px 0;
}
.eatl-business43 .btn-area a {
  display: inline-block;
  font-size: 22px;
  font-weight: 500;
  color: #fff;
  width: 315px;
  height: 60px;
  line-height: 60px;
  background: #0062b7;
  border: none;
}
@media screen and (max-width: 720px) {
  .eatl-business43 .section {
    width: 100%;
    overflow-x: auto;
    padding-top: 4.5rem;
 }
  .eatl-business43 .list-table {
    width: 170rem;
 }
  .eatl-business43 .list-table th, .eatl-business43 .list-table td {
    font-size: 3rem;
    height: 12rem;
 }
  .eatl-business43 .list-table tfoot td {
    font-size: 3.2rem;
    font-weight: 500;
    border-left: none;
    border-right: none;
 }
  .eatl-business43 .btn-area {
    text-align: center;
    margin: 4rem 0 10rem;
 }
  .eatl-business43 .btn-area button {
    font-size: 3rem;
    font-weight: 500;
    color: #fff;
    width: 50rem;
    height: 10rem;
    background: #0062b7;
    border: none;
 }
  .eatl-business43 .bottom {
    height: 10rem;
 }
}

/* 부설에너지기기시험원 - 사업안내 - 품목별 품질관리기관 */
.eatl-business5 {
  padding-bottom: 60px;
}
.eatl-business5 .guide-txt {
  font-size: 18px;
  color: #2b3038;
  line-height: 26px;
  text-align: center;
  margin-bottom: 42px;
}
.eatl-business5 .section {
  margin-bottom: 35px;
}
.eatl-business5 .section .title {
  font-size: 22px;
  font-weight: bold;
  color: #2b3038;
  line-height: 68px;
  border-bottom: 2px solid #2b3038;
}
.eatl-business5 .section .title span {
  font-weight: 400;
}
.eatl-business5 .section .txt-area {
  padding: 12px 0 5px !important;
  border-bottom: 1px solid #e9e9e9;
}
.eatl-business5 .section .txt-area li {
  padding-top: 4px;
  margin-bottom: 10px;
}
.eatl-business5 .section .txt-area li .txt {
  margin-bottom: 5px;
}
.eatl-business5 .section .txt-area li .txt:after {
  content: "";
  display: block;
  clear: both;
}
.eatl-business5 .section .txt-area li .txt span:nth-child(1) {
  float: left;
}
.eatl-business5 .section .txt-area li .txt span:nth-child(1):before {
  float: left;
  content: '';
  width: 3px;
  height: 3px;
  background-color: #999;
  border-radius: 50%;
  margin: 10px 10px 0 0;
}
.eatl-business5 .section .txt-area li .txt span:nth-child(2) {
  float: left;
  width: calc(100% - 13px);
  font-size: 16px;
  color: #2b3038;
  line-height: 26px;
}
.eatl-business5 .section .step {
  padding: 30px 0 20px !important;
  border-bottom: 1px solid #e9e9e9;
}
.eatl-business5 .section .step li {
  position: relative;
  float: left;
  width: calc((100% - 90px)/ 7);
  height: 140px;
  margin-right: 15px;
  background: #fff;
  border: 1px solid #e9e9e9;
}
.eatl-business5 .section .step li .arrow {
  position: absolute;
  top: calc(50% - 10px);
  right: -11px;
  width: 11px;
  height: 20px;
  background: url(/assets/images/triangle.png) no-repeat;
}
.eatl-business5 .section .step li:nth-last-child(1) {
  margin-right: 0;
}
.eatl-business5 .section .step li .no {
  font-family: "Roboto";
  font-size: 14px;
  font-weight: bold;
  color: #0062b7;
  padding: 20px 0 0 20px;
}
.eatl-business5 .section .step li .txt {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 92px;
}
.eatl-business5 .section .step li .txt > div {
  font-size: 16px;
  font-weight: 500;
  line-height: 22px;
  color: #2b3038;
  line-height: 22px;
  text-align: center;
}
.eatl-business5 .section .step li .txt > div span {
  font-size: 14px;
  font-weight: 400;
}
@media screen and (max-width: 1200px) {
  .eatl-business5 .pc-only {
    display: none;
 }
  .eatl-business5 .section .step li {
    width: calc((100% - 60px)/ 4);
    margin-bottom: 10px;
 }
}
@media screen and (max-width: 720px) {
  .eatl-business5 {
    padding-bottom: 5rem;
 }
  .eatl-business5 .pc-only1 {
    display: none;
 }
  .eatl-business5 .guide-txt {
    font-size: 3rem;
    line-height: 4rem;
    margin-bottom: 4.5rem;
 }
  .eatl-business5 .section {
    margin-bottom: 5rem;
 }
  .eatl-business5 .section .title {
    font-size: 3.6rem;
    font-weight: 500;
    line-height: 9.6rem;
    letter-spacing: -2px;
    border-bottom: 0.4rem solid #2b3038;
 }
  .eatl-business5 .section .txt-area {
    padding: 1.4rem 0 !important;
    border-bottom: 1px solid #e9e9e9;
 }
  .eatl-business5 .section .txt-area li {
    padding-top: 0.8rem;
    margin-bottom: 1rem;
 }
  .eatl-business5 .section .txt-area li .txt {
    margin-bottom: 0.9rem;
 }
  .eatl-business5 .section .txt-area li .txt span:nth-child(1):before {
    width: 0.5rem;
    height: 0.5rem;
    margin: 2.7rem 1.5rem 0 0;
 }
  .eatl-business5 .section .txt-area li .txt span:nth-child(2) {
    width: calc(100% - 2rem);
    font-size: 2.8rem;
    line-height: 3.6rem;
    padding: 1rem 0;
 }
  .eatl-business5 .section .step {
    padding: 4rem 0 !important;
    border-bottom: 1px solid #e9e9e9;
 }
  .eatl-business5 .section .step li {
    position: relative;
    float: left;
    width: 100%;
    height: 30rem;
    margin: 0 0 10rem 0;
    background: #fff;
    border: 1px solid #e9e9e9;
 }
  .eatl-business5 .section .step li .arrow {
    position: absolute;
    top: auto;
    right: auto;
    left: calc(50% - 6rem);
    bottom: calc(-5.9rem + 1px);
    width: 11.7rem;
    height: 5.9rem;
    background: url(/assets/images/trianglem.png) no-repeat 50% / contain;
 }
  .eatl-business5 .section .step li:nth-last-child(1) {
    margin-bottom: 0;
 }
  .eatl-business5 .section .step li .no {
    font-size: 3rem;
    color: #0062b7;
    padding: 6rem 0 0 6rem;
 }
  .eatl-business5 .section .step li .txt {
    height: 15.2rem;
 }
  .eatl-business5 .section .step li .txt > div {
    font-size: 3.6rem;
    line-height: 4.4rem;
 }
  .eatl-business5 .section .step li .txt > div span {
    font-size: 3rem;
 }
}

/* 부설에너지기기시험원 - 사업안내 - 가스보일러 실환경 실증실험실 구축 */
.eatl-business6 {
  padding-bottom: 60px;
}
.eatl-business6 .guide-txt {
  font-size: 18px;
  color: #2b3038;
  line-height: 26px;
  text-align: center;
  margin-bottom: 42px;
}
.eatl-business6 .section {
  margin-bottom: 35px;
}
.eatl-business6 .section .title {
  font-size: 22px;
  font-weight: bold;
  color: #2b3038;
  line-height: 68px;
  border-bottom: 2px solid #2b3038;
}
.eatl-business6 .section .title span {
  font-weight: 400;
}
.eatl-business6 .section .txt-area {
  padding: 12px 0 5px !important;
  border-bottom: 1px solid #e9e9e9;
}
.eatl-business6 .section .txt-area li {
  padding-top: 4px;
  margin-bottom: 10px;
}
.eatl-business6 .section .txt-area li .txt {
  margin-bottom: 5px;
}
.eatl-business6 .section .txt-area li .txt:after {
  content: "";
  display: block;
  clear: both;
}
.eatl-business6 .section .txt-area li .txt span:nth-child(1) {
  float: left;
}
.eatl-business6 .section .txt-area li .txt span:nth-child(1):before {
  float: left;
  content: '';
  width: 3px;
  height: 3px;
  background-color: #999;
  border-radius: 50%;
  margin: 10px 10px 0 0;
}
.eatl-business6 .section .txt-area li .txt span:nth-child(2) {
  float: left;
  width: calc(100% - 13px);
  font-size: 16px;
  color: #2b3038;
  line-height: 22px;
}
.eatl-business6 .section .txt-area li .txt1 {
  font-size: 14px;
  color: #2b3038;
  line-height: 22px;
  padding-left: 13px;
}
.eatl-business6 .section .img {
  border-bottom: 1px solid #e9e9e9;
  padding: 20px 0;
}
.eatl-business6 .section .img .left-area {
  float: left;
  width: calc(100% - 532px);
  border-right: 1px solid #e9e9e9;
}
.eatl-business6 .section .img .left-area > div {
  float: left;
  width: 230px;
  border-radius: 5px;
  overflow: hidden;
  margin: 0 20px 10px 0;
}
.eatl-business6 .section .img .left-area > div p {
  font-size: 12px;
  font-weight: 500;
  color: #2b3038;
  line-height: 30px;
  text-align: center;
  margin-bottom: 0 !important;
}
.eatl-business6 .section .img .left-area > div:nth-child(3n) {
  margin-right: 0;
}
.eatl-business6 .section .img .left-area > div:nth-child(n+4) {
  margin-bottom: 0;
}
.eatl-business6 .section .img .right-area {
  float: right;
  width: 532px;
  padding-left: 20px;
}
.eatl-business6 .section .img .right-area > div p {
  font-size: 12px;
  font-weight: 500;
  color: #2b3038;
  line-height: 30px;
  text-align: center;
}
@media screen and (max-width: 1360px) {
  .eatl-business6 .section .img .left-area {
    float: none;
    width: 750px;
    margin: 0 auto;
    border-right: none;
 }
  .eatl-business6 .section .img .left-area::after {
    content: "";
    display: block;
    clear: both;
 }
  .eatl-business6 .section .img .left-area > div {
    float: left;
    width: 230px;
    border-radius: 5px;
    overflow: hidden;
    margin: 0 10px 10px;
 }
  .eatl-business6 .section .img .left-area > div:nth-child(3n) {
    margin-right: 10px;
 }
  .eatl-business6 .section .img .left-area > div:nth-child(n+4) {
    margin-bottom: 10px;
 }
  .eatl-business6 .section .img .right-area {
    float: none;
    width: 512px;
    padding-left: 0;
    margin: 10px auto 0;
 }
  .eatl-business6 .section .img .right-area > div p {
    font-size: 12px;
    font-weight: 500;
    color: #2b3038;
    line-height: 30px;
    text-align: center;
 }
}
@media screen and (max-width: 850px) {
  .eatl-business6 .pc-only {
    display: none;
 }
  .eatl-business6 .section .img .left-area {
    width: 500px;
 }
}
@media screen and (max-width: 720px) {
  .eatl-business6 {
    padding-bottom: 5rem;
 }
  .eatl-business6 .page-title {
    letter-spacing: -4px;
 }
  .eatl-business6 .guide-txt {
    font-size: 3rem;
    line-height: 4rem;
    margin-bottom: 4.5rem;
    letter-spacing: -2px;
 }
  .eatl-business6 .section {
    margin-bottom: 5rem;
 }
  .eatl-business6 .section .title {
    font-size: 3.6rem;
    font-weight: 500;
    line-height: 9.6rem;
    letter-spacing: -2px;
    border-bottom: 0.4rem solid #2b3038;
 }
  .eatl-business6 .section .txt-area {
    padding: 1.4rem 0 !important;
    border-bottom: 1px solid #e9e9e9;
 }
  .eatl-business6 .section .txt-area li {
    padding-top: 0.8rem;
    margin-bottom: 1rem;
 }
  .eatl-business6 .section .txt-area li .txt {
    margin-bottom: 0.9rem;
 }
  .eatl-business6 .section .txt-area li .txt span:nth-child(1):before {
    width: 0.5rem;
    height: 0.5rem;
    margin: 2.7rem 1.5rem 0 0;
 }
  .eatl-business6 .section .txt-area li .txt span:nth-child(2) {
    width: calc(100% - 2rem);
    font-size: 2.8rem;
    line-height: 3.6rem;
    padding: 1rem 0;
 }
  .eatl-business6 .section .txt-area li .txt1 {
    font-size: 2.8rem;
    color: #2b3038;
    line-height: 3.6rem;
    padding-left: 2rem;
 }
  .eatl-business6 .section .img {
    border-bottom: 1px solid #e9e9e9;
    padding: 4rem 0 0;
 }
  .eatl-business6 .section .img .left-area {
    width: 100%;
 }
  .eatl-business6 .section .img .left-area > div {
    float: none;
    width: 100%;
    border-radius: 0;
    overflow: hidden;
    margin: 0 0 2rem;
 }
  .eatl-business6 .section .img .left-area > div img {
    width: 100%;
 }
  .eatl-business6 .section .img .left-area > div p {
    font-size: 3rem;
    font-weight: 500;
    color: #2b3038;
    line-height: 10rem;
    text-align: center;
 }
  .eatl-business6 .section .img .left-area > div:nth-child(3n) {
    margin-right: 0;
 }
  .eatl-business6 .section .img .left-area > div:nth-child(n+4) {
    margin-bottom: 2rem;
 }
  .eatl-business6 .section .img .right-area {
    width: 100%;
 }
  .eatl-business6 .section .img .right-area > div img {
    width: 100%;
 }
  .eatl-business6 .section .img .right-area > div p {
    font-size: 3rem;
    font-weight: 500;
    color: #2b3038;
    line-height: 10rem;
    text-align: center;
 }
}

/* 부설에너지기기시험원 - 보유설비 */
.equipment-page {
  padding: 55px 0 100px;
}
.equipment-page .mobile-only {
  display: none;
}
.equipment-page .section {
  margin-bottom: 35px;
}
.equipment-page .section .title {
  font-size: 22px;
  font-weight: bold;
  color: #2b3038;
  line-height: 68px;
  border-bottom: 2px solid #2b3038;
}
.equipment-page .section .txt-area {
  padding: 17px 0 !important;
  border-bottom: 1px solid #e9e9e9;
}
.equipment-page .section .txt-area li:after {
  content: "";
  display: block;
  clear: both;
}
.equipment-page .section .txt-area li span:nth-child(1) {
  float: left;
}
.equipment-page .section .txt-area li span:nth-child(1):before {
  float: left;
  content: '';
  width: 3px;
  height: 3px;
  background-color: #999;
  border-radius: 50%;
  margin: 10px 10px 0 0;
}
.equipment-page .section .txt-area li span:nth-child(2) {
  float: left;
  width: calc(100% - 13px);
  font-size: 16px;
  color: #2b3038;
  line-height: 24px;
}
.equipment-page .section .list {
  padding-top: 30px !important;
  border-bottom: 1px solid #e9e9e9;
}
.equipment-page .section .list li {
  float: left;
  width: 50%;
  margin-bottom: 30px;
}
.equipment-page .section .list li:after {
  content: "";
  display: block;
  clear: both;
}
.equipment-page .section .list li img {
  float: left;
  width: 174px;
  height: 116px;
}
.equipment-page .section .list li .right-area {
  float: left;
  width: calc(100% - 174px);
  padding: 8px 0 0 20px;
}
.equipment-page .section .list li .right-area .h2 {
  font-size: 16px;
  font-weight: 500;
  color: #0062b7;
  margin-bottom: 12px;
}
.equipment-page .section .list li .right-area .txt > p {
  font-size: 14px;
  color: #2b3038;
  line-height: 16px;
  margin-bottom: 4px;
}
.equipment-page .section .list li .right-area .txt > p:after {
  content: "";
  display: block;
  clear: both;
}
.equipment-page .section .list li .right-area .txt > p span:nth-child(1) {
  float: left;
}
.equipment-page .section .list li .right-area .txt > p span:nth-child(1):before {
  float: left;
  content: '';
  width: 3px;
  height: 3px;
  background-color: #999;
  border-radius: 50%;
  margin: 10px 10px 0 0;
}
.equipment-page .section .list li .right-area .txt > p span:nth-child(2) {
  float: left;
  width: calc(100% - 13px);
  font-size: 16px;
  color: #2b3038;
  line-height: 22px;
}
@media screen and (max-width: 1300px) {
  .equipment-page .section .list li {
    float: none;
    width: calc(100% - 200px);
    margin: 0 auto 30px;
 }
}
@media screen and (max-width: 1000px) {
  .equipment-page .section .list li {
    float: none;
    width: 100%;
    margin: 0 0 30px;
 }
}
@media screen and (max-width: 720px) {
  .equipment-page {
    padding: 3.5rem 0 10rem;
 }
  .equipment-page .pc-only {
    display: none;
 }
  .equipment-page .mobile-only {
    display: block;
 }
  .equipment-page .section {
    margin-bottom: 0;
 }
  .equipment-page .section .title {
    font-size: 3.6rem;
    font-weight: 500;
    line-height: 9rem;
    border-bottom: 2px solid #2b3038;
 }
  .equipment-page .section .txt-area {
    padding: 2.2rem 0 2.8rem !important;
    border-bottom: 2px solid #e9e9e9;
 }
  .equipment-page .section .txt-area li span:nth-child(1) {
    float: left;
 }
  .equipment-page .section .txt-area li span:nth-child(1):before {
    width: 0.5rem;
    height: 0.5rem;
    margin: 2.7rem 1.5rem 0 0;
 }
  .equipment-page .section .txt-area li span:nth-child(2) {
    width: calc(100% - 2rem);
    font-size: 2.8rem;
    line-height: 3.6rem;
    padding: 1rem 0;
 }
  .equipment-page .section .list {
    padding-top: 3.5rem !important;
    border-bottom: 1px solid #e9e9e9;
 }
  .equipment-page .section .list li {
    float: none;
    width: 100%;
    padding-top: 2rem;
    margin: 0 0 4rem;
 }
  .equipment-page .section .list li:after {
    content: "";
    display: block;
    clear: both;
 }
  .equipment-page .section .list li img {
    float: none;
    width: 62rem;
    height: 42rem;
    margin: 3rem auto 0;
 }
  .equipment-page .section .list li .right-area {
    float: none;
    width: 100%;
    padding: 0;
 }
  .equipment-page .section .list li .right-area .h2 {
    font-size: 3.2rem;
    font-weight: 500;
    color: #0062b7;
    margin-bottom: 3.2rem;
 }
  .equipment-page .section .list li .right-area .txt > p {
    font-size: 2.8rem;
    color: #2b3038;
    line-height: 1;
    margin-bottom: 2.3rem;
 }
  .equipment-page .section .list li .right-area .txt > p span:nth-child(1) {
    float: left;
 }
  .equipment-page .section .list li .right-area .txt > p span:nth-child(1):before {
    width: 0.5rem;
    height: 0.5rem;
    margin: 1.9rem 1.5rem 0 0;
 }
  .equipment-page .section .list li .right-area .txt > p span:nth-child(2) {
    width: calc(100% - 2rem);
    font-size: 2.8rem;
    line-height: 3.5rem;
 }
}

/* 부설에너지기기시험원 - 홍보자료 */
.promote-page {
  padding: 55px 0 80px;
}
.promote-page .section .title {
  font-size: 22px;
  font-weight: bold;
  color: #2b3038;
  line-height: 68px;
  border-bottom: 2px solid #2b3038;
}
.promote-page .section .list {
  width: fit-content;
  margin: 78px auto 0 !important;
}
.promote-page .section .list li {
  float: left;
  width: 234px;
  height: 300px;
  position: relative;
}
.promote-page .section .list li .div1 {
  position: absolute;
  top: 0;
  left: 0;
  width: 230px;
  height: 300px;
  padding-top: 150px;
  border: 1px solid #d7d7d7;
  background: #f8f9f9 url(/assets/images/keaa_logo1.png) no-repeat 50% 50px;
  z-index: 3;
  text-align: center;
}
.promote-page .section .list li .div1 .down-title {
  font-size: 16px;
  font-weight: bold;
  color: #2b3038;
  line-height: 18px;
  text-align: center;
  margin-bottom: 30px;
}
.promote-page .section .list li .div1 .down-btn {
  display: inline-block;
  width: 118px;
  height: 36px;
  background: #fff;
  border: 1px solid #d7d7d7;
  font-size: 14px;
  font-weight: 500;
  color: #0062b7;
  line-height: 34px;
  text-align: center;
}
.promote-page .section .list li .div1 .down-btn:hover {
  text-decoration: none;
}
.promote-page .section .list li .div2 {
  position: absolute;
  top: 2px;
  left: 0;
  width: 232px;
  height: 296px;
  border: 1px solid #d7d7d7;
  background: #f8f9f9;
  z-index: 2;
}
.promote-page .section .list li .div3 {
  position: absolute;
  top: 4px;
  left: 0;
  width: 234px;
  height: 294px;
  border: 1px solid #d7d7d7;
  background: #f8f9f9;
  z-index: 1;
}
.promote-page .section .list li:nth-child(2) {
  margin: 0 130px;
}
@media screen and (max-width: 1100px) {
  .promote-page .section .list li:nth-child(2) {
    margin: 0 20px;
 }
}
@media screen and (max-width: 850px) {
  .promote-page .section .list li {
    float: none;
 }
  .promote-page .section .list li:nth-child(2) {
    margin: 20px 0;
 }
}
@media screen and (max-width: 720px) {
  .promote-page {
    padding: 2.5rem 0 0;
 }
  .promote-page .section .title {
    font-size: 3.6rem;
    font-weight: 500;
    line-height: 9.8rem;
 }
  .promote-page .section .list {
    width: 100%;
    padding: 0 3rem !important;
    margin: 8rem auto 10rem !important;
 }
  .promote-page .section .list li {
    float: none;
    width: 100%;
    height: 75rem;
 }
  .promote-page .section .list li .div1 {
    width: calc(100% - 0.8rem);
    height: 74rem;
    padding-top: 37rem;
    background: #f8f9f9 url(/assets/images/keaa_logo1m.png) no-repeat 50% 12rem / 19.9rem auto;
 }
  .promote-page .section .list li .div1 .down-title {
    font-size: 3.6rem;
    line-height: 4.4rem;
    margin-bottom: 7rem;
 }
  .promote-page .section .list li .div1 .down-btn {
    width: 26.3rem;
    height: 8.7rem;
    font-size: 3.4rem;
    line-height: 8.7rem;
 }
  .promote-page .section .list li .div2 {
    position: absolute;
    top: 0.5rem;
    left: 0;
    width: calc(100% - 0.4rem);
    height: 73rem;
 }
  .promote-page .section .list li .div3 {
    position: absolute;
    top: 1rem;
    left: 0;
    width: 100%;
    height: 72rem;
 }
  .promote-page .section .list li:nth-child(2) {
    margin: 8rem 0;
 }
}

/* 온라인신청 - 공인시험검사신청 */
.online-page .mobile-only {
  display: none;
}
.online-page .page-tab li {
  width: calc(100% /4);
}
.online-page .page-tab li a {
  font-size: 22px;
  color: #888;
}
.online-page .page-tab li.active a {
  font-size: 22px;
  font-weight: 500;
  color: #fff;
}
.online-page .online1-page {
  padding-bottom: 160px;
}
.online-page .online1-page .guide-txt {
  font-size: 18px;
  color: #2b3038;
  line-height: 26px;
  text-align: center;
  margin-bottom: 50px;
}
.online-page .online1-page .guide-txt .blue {
  color: #0062b7;
}
.online-page .online1-page .application li {
  float: left;
  width: 407px;
  height: 110px;
  padding: 0 20px;
  margin: 0 0 30px;
  border-radius: 4px;
  background-color: #f8f9f9;
  border: 2px solid #e9e9e9;
}
.online-page .online1-page .application li > div:nth-child(1) {
  /* float: left; */
  font-size: 18px;
  font-weight: bold;
  color: #2b3038;
  letter-spacing: -1px;
  line-height: 50px;
}
.online-page .online1-page .application li > div:nth-child(2) {
  /* float: right; */
}

.online-page .online1-page .application li > div:nth-child(2) a {
  font-size: 16px;
  color: #2b3038;
  letter-spacing: -1px;
  line-height: 50px;
}
.online-page .online1-page .application li > div:nth-child(2) a + a:before {
  display: inline-block;
  content: '';
  width: 1px;
  height: 15px;
  background-color: #d7d7d7;
  margin: 0px 20px;
  vertical-align: middle;
}
.online-page .online1-page .application li:nth-child(3n+2) {
  margin: 0 29.5px 30px;
}
/* .online-page .online1-page .application li:nth-last-child(1) {
  margin: 0 436.5px 35px;
} */
.online-page .online1-page .application li:hover {
  border: 2px solid #0061b7;
}
.online-page .online1-page .application li:hover > div:nth-child(1) {
  color: #0062b7;
}
.online-page .online1-page .section {
  margin-bottom: 35px;
}
.online-page .online1-page .section .title {
  font-size: 22px;
  font-weight: bold;
  color: #2b3038;
  line-height: 68px;
  border-bottom: 2px solid #2b3038;
}
.online-page .online1-page .section .title span {
  font-weight: 400;
}
.online-page .online1-page .section .title .blue {
  color: #0062b7;
}
.online-page .online1-page .section .title.fl {
  border-bottom: none;
}
.online-page .online1-page .section .title-sub {
  font-size: 12px;
  color: #2b3038;
  letter-spacing: -1px;
  line-height: 56px;
  padding-top: 12px;
}
.online-page .online1-page .section .txt-area {
  padding: 12px 0 5px !important;
  border-bottom: 1px solid #e9e9e9;
}
.online-page .online1-page .section .txt-area li {
  padding-top: 4px;
  margin-bottom: 10px;
}
.online-page .online1-page .section .txt-area li .txt {
  margin-bottom: 5px;
}
.online-page .online1-page .section .txt-area li .txt:after {
  content: "";
  display: block;
  clear: both;
}
.online-page .online1-page .section .txt-area li .txt span:nth-child(1) {
  float: left;
}
.online-page .online1-page .section .txt-area li .txt span:nth-child(1):before {
  float: left;
  content: '';
  width: 3px;
  height: 3px;
  background-color: #999;
  border-radius: 50%;
  margin: 10px 10px 0 0;
}
.online-page .online1-page .section .txt-area li .txt span:nth-child(2) {
  float: left;
  width: calc(100% - 13px);
  font-size: 16px;
  color: #2b3038;
  line-height: 26px;
}
.online-page .online1-page .section .txt-area li .txt.red {
  color: #e40000;
  line-height: 26px;
}
.online-page .online1-page .section .txt-area li .txt1 {
  margin: -7px 0 5px;
}
.online-page .online1-page .section .txt-area li .txt1:after {
  content: "";
  display: block;
  clear: both;
}
.online-page .online1-page .section .txt-area li .txt1 span:nth-child(1) {
  float: left;
  width: 23px;
  padding-left: 13px;
}
.online-page .online1-page .section .txt-area li .txt1 span:nth-child(2) {
  float: left;
  width: calc(100% - 23px);
  font-size: 14px;
  color: #2b3038;
  line-height: 22px;
}
.online-page .online1-page .section .img {
  height: 721px;
  background: url(/assets/images/accredited01.png) no-repeat 50%;
  padding: 40px 0;
  border-bottom: 1px solid #e9e9e9;
}
.online-page .online1-page .section .list-table {
  border-top: none;
}
.online-page .online1-page .section .list-table .col1 {
  width: 130px;
}
.online-page .online1-page .section .list-table .col2 {
  width: 260px;
}
.online-page .online1-page .section .list-table th {
  line-height: 20px;
}
.online-page .online1-page .section .list-table td {
  line-height: 26px;
  text-align: left;
  padding: 24px 0 24px 20px;
}
.online-page .online1-page .section .list-table.list-table1 {
  border-top: 2px solid #2b3038;
}
.online-page .online1-page .section .list-table.list-table1 th {
  font-size: 14px;
}
.online-page .online1-page .section .list-table.list-table1 td {
  text-align: center;
  padding: 14px 20px;
  min-height: 75px;
}
.online-page .online1-page .section .list-table.list-table1 td.tl {
  text-align: left;
}
.online-page .online1-page .section .list-table.list-table1 td.tr {
  text-align: right;
}
@media screen and (max-width: 1380px) {
  .online-page .mobile-only {
    display: block;
 }
  .online-page .online1-page .application {
    width: 874px;
    margin: 0 auto !important;
 }
  .online-page .online1-page .application li:nth-child(3n+2) {
    margin: 0 0 30px;
 }
  .online-page .online1-page .application li:nth-child(2n) {
    margin: 0 0 30px 30px;
 }
  .online-page .online1-page .application li:nth-last-child(1) {
    margin: 0 0 35px;
 }
}
@media screen and (max-width: 1000px) {
  .online-page .page-tab li {
    width: calc(100% /2);
 }
  .online-page .online1-page .application {
    width: 407px;
    margin: 0 auto !important;
 }
  .online-page .online1-page .application li:nth-child(3n+2) {
    margin: 0 0 30px;
 }
  .online-page .online1-page .application li:nth-child(2n) {
    margin: 0 0 30px;
 }
  .online-page .online1-page .application li:nth-last-child(1) {
    margin: 0 0 35px;
 }
  .online-page .online1-page .section .table-area {
    overflow-x: auto;
 }
  .online-page .online1-page .section .list-table {
    width: 1000px;
 }
}
@media screen and (max-width: 720px) {
  .online-page .page-tab li {
    width: calc(100% /2);
 }
  .online-page .page-tab li a {
    font-size: 3rem;
 }
  .online-page .page-tab li.active a {
    font-size: 3rem;
 }
  .online-page .online1-page {
    padding-bottom: 5rem;
 }
  .online-page .online1-page .guide-txt {
    font-size: 3rem;
    line-height: 4rem;
    margin-bottom: 7.5rem;
 }
  .online-page .online1-page .application {
    width: 100%;
 }
  .online-page .online1-page .application li {
    float: none;
    width: 100%;
    height: 10rem;
    padding: 0 3rem;
    margin: 0 0 3rem;
 }
  .online-page .online1-page .application li > div:nth-child(1) {
    font-size: 2.8rem;
    line-height: 10rem;
 }
  .online-page .online1-page .application li > div:nth-child(2) {
    float: right;
 }
  .online-page .online1-page .application li > div:nth-child(2) a {
    font-size: 2.6rem;
    line-height: 10rem;
 }
  .online-page .online1-page .application li > div:nth-child(2) a + a:before {
    height: 2.4rem;
    margin: 0px 3rem;
 }
  .online-page .online1-page .application li:nth-child(3n+2) {
    margin: 0 0 3rem;
 }
  .online-page .online1-page .application li:nth-child(2n) {
    margin: 0 0 3rem;
 }
  .online-page .online1-page .application li:nth-last-child(1) {
    margin: 0 0 4.5rem;
 }
  .online-page .online1-page .section {
    margin-bottom: 5rem;
 }
  .online-page .online1-page .section .title {
    font-size: 3.6rem;
    font-weight: 500;
    line-height: 9.6rem;
    letter-spacing: -2px;
    border-bottom: 0.4rem solid #2b3038;
 }
  .online-page .online1-page .section .title-sub {
    font-size: 2rem;
    line-height: 8rem;
 }
  .online-page .online1-page .section .txt-area {
    padding: 1.4rem 0 2rem !important;
    border-bottom: 2px solid #e9e9e9;
 }
  .online-page .online1-page .section .txt-area li {
    padding-top: 0.8rem;
    margin-bottom: 1rem;
 }
  .online-page .online1-page .section .txt-area li .txt {
    margin-bottom: 0;
 }
  .online-page .online1-page .section .txt-area li .txt span:nth-child(1):before {
    width: 0.5rem;
    height: 0.5rem;
    margin: 2.7rem 1.5rem 0 0;
 }
  .online-page .online1-page .section .txt-area li .txt span:nth-child(2) {
    width: calc(100% - 2rem);
    font-size: 2.8rem;
    line-height: 3.6rem;
    padding: 1rem 0;
 }
  .online-page .online1-page .section .txt-area li .txt.red {
    font-size: 2.8rem;
    line-height: 4.6rem;
 }
  .online-page .online1-page .section .txt-area li .txt1 {
    margin: 0;
 }
  .online-page .online1-page .section .txt-area li .txt1 span:nth-child(1) {
    font-size: 2.8rem;
    line-height: 3.6rem;
    width: 4rem;
    padding-left: 2rem;
 }
  .online-page .online1-page .section .txt-area li .txt1 span:nth-child(2) {
    float: left;
    width: calc(100% - 4rem);
    font-size: 2.8rem;
    line-height: 3.6rem;
 }
  .online-page .online1-page .section .img {
    height: 72.2rem;
    background: url(/assets/images/accredited01.png) no-repeat 50% / 58.1rem auto;
    padding: 4rem 0;
 }
  .online-page .online1-page .section .table-area {
    overflow-x: auto;
 }
  .online-page .online1-page .section .list-table {
    width: 230rem;
 }
  .online-page .online1-page .section .list-table .col1 {
    width: 22rem;
 }
  .online-page .online1-page .section .list-table .col2 {
    width: 35rem;
 }
  .online-page .online1-page .section .list-table th {
    font-size: 3rem;
    line-height: 3.4rem;
    padding: 4.5rem 0;
 }
  .online-page .online1-page .section .list-table td {
    font-size: 3rem;
    line-height: 3.6rem;
    padding: 4.5rem 0 4.5rem 3rem;
 }
  .online-page .online1-page .section .list-table.list-table1 {
    width: 200rem;
 }
  .online-page .online1-page .section .list-table.list-table1 th {
    font-size: 3rem;
    line-height: 3.4rem;
    padding: 4.5rem 0;
 }
  .online-page .online1-page .section .list-table.list-table1 td {
    font-size: 3rem;
    line-height: 3.6rem;
    text-align: center;
    padding: 3.5rem 3rem;
 }
  .online-page .online1-page .section .list-table.list-table1 td.tl {
    text-align: left;
 }
  .online-page .online1-page .section .list-table.list-table1 td.tr {
    text-align: right;
 }
}
/* 온라인신청 - 안전확인시험검사신청 */
.online-page01 .mobile-only {
  display: none;
}
.online-page01 .page-tab li {
  width: calc(100% /4);
}
.online-page01 .page-tab li a {
  font-size: 22px;
  color: #888;
}
.online-page01 .page-tab li.active a {
  font-size: 22px;
  font-weight: 500;
  color: #fff;
}
.online-page01 .online1-page {
  padding-bottom: 160px;
}
.online-page01 .online1-page .guide-txt {
  font-size: 18px;
  color: #2b3038;
  line-height: 26px;
  text-align: center;
  margin-bottom: 50px;
}
.online-page01 .online1-page .guide-txt .blue {
  color: #0062b7;
}
.online-page01 .online1-page .application {
  width: 407px;
  margin: 0 auto !important;
}
.online-page01 .online1-page .application li {
  width: 407px;
  height: 120px;
  padding: 0 20px;
  margin: 0 0 35px;
  border-radius: 4px;
  background-color: #f8f9f9;
  border: 2px solid #e9e9e9;
}
.online-page01 .online1-page .application li > div:nth-child(1) {
  /* float: left; */
  font-size: 18px;
  font-weight: bold;
  color: #2b3038;
  letter-spacing: -1px;
  line-height: 50px;
}
.online-page01 .online1-page .application li > div:nth-child(2) {
  /* float: right; */
}
.online-page01 .online1-page .application li > div:nth-child(2) a {
  font-size: 16px;
  color: #2b3038;
  letter-spacing: -1px;
  line-height: 50px;
}
.online-page01 .online1-page .application li > div:nth-child(2) a + a:before {
  display: inline-block;
  content: '';
  width: 1px;
  height: 15px;
  background-color: #d7d7d7;
  margin: 0px 20px;
  vertical-align: middle;
}
.online-page01 .online1-page .application li:hover {
  border: 2px solid #0061b7;
}
.online-page01 .online1-page .application li:hover > div:nth-child(1) {
  color: #0062b7;
}
.online-page01 .online1-page .section {
  margin-bottom: 35px;
}
.online-page01 .online1-page .section .title {
  font-size: 22px;
  font-weight: bold;
  color: #2b3038;
  line-height: 68px;
  border-bottom: 2px solid #2b3038;
}
.online-page01 .online1-page .section .title span {
  font-weight: 400;
}
.online-page01 .online1-page .section .title .blue {
  color: #0062b7;
}
.online-page01 .online1-page .section .title.fl, .online-page01 .online1-page .section .title.noline {
  border-bottom: none;
}
.online-page01 .online1-page .section .title-sub {
  font-size: 12px;
  color: #2b3038;
  letter-spacing: -1px;
  line-height: 56px;
  padding-top: 12px;
}
.online-page01 .online1-page .section .txt-area {
  padding: 12px 0 5px !important;
  border-bottom: 1px solid #e9e9e9;
}
.online-page01 .online1-page .section .txt-area li {
  padding-top: 4px;
  margin-bottom: 10px;
}
.online-page01 .online1-page .section .txt-area li .txt {
  margin-bottom: 5px;
}
.online-page01 .online1-page .section .txt-area li .txt:after {
  content: "";
  display: block;
  clear: both;
}
.online-page01 .online1-page .section .txt-area li .txt span:nth-child(1) {
  float: left;
}
.online-page01 .online1-page .section .txt-area li .txt span:nth-child(1):before {
  float: left;
  content: '';
  width: 3px;
  height: 3px;
  background-color: #999;
  border-radius: 50%;
  margin: 10px 10px 0 0;
}
.online-page01 .online1-page .section .txt-area li .txt span:nth-child(2) {
  float: left;
  width: calc(100% - 13px);
  font-size: 16px;
  color: #2b3038;
  line-height: 26px;
}
.online-page01 .online1-page .section .txt-area li .txt.red {
  color: #e40000;
  line-height: 26px;
}
.online-page01 .online1-page .section .list-table {
  border-top: none;
}
.online-page01 .online1-page .section .list-table .col1 {
  width: 260px;
}
.online-page01 .online1-page .section .list-table th {
  line-height: 20px;
  padding: 0 30px;
}
.online-page01 .online1-page .section .list-table td {
  line-height: 26px;
  text-align: left;
  padding: 24px 0 24px 20px;
}
.online-page01 .online1-page .section .list-table.list-table1 {
  border-top: 2px solid #2b3038;
}
.online-page01 .online1-page .section .list-table.list-table1 .col-th {
  width: 257px;
}
.online-page01 .online1-page .section .list-table.list-table1 th {
  font-size: 14px;
  height: 75px;
}
.online-page01 .online1-page .section .list-table.list-table1 th .tl {
  text-align: left;
}
.online-page01 .online1-page .section .list-table.list-table1 th .tr {
  text-align: right;
  padding-bottom: 25px;
}
.online-page01 .online1-page .section .list-table.list-table1 td {
  text-align: center;
  height: 75px;
}
.online-page01 .online1-page .section .list-table.list-table1 thead th {
  position: relative;
  height: 100px;
}
.online-page01 .online1-page .section .list-table.list-table1 thead .line {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: url(/assets/images/line.png) no-repeat 0 0 / cover;
}
@media screen and (max-width: 1380px) {
  .online-page01 .mobile-only {
    display: block;
 }
}
@media screen and (max-width: 1000px) {
  .online-page01 .page-tab li {
    width: calc(100% /2);
 }
  .online-page01 .online1-page .section .table-area {
    overflow-x: auto;
 }
  .online-page01 .online1-page .section .list-table {
    width: 1000px;
 }
}
@media screen and (max-width: 720px) {
  .online-page01 .page-tab li {
    width: calc(100% /2);
 }
  .online-page01 .page-tab li a {
    font-size: 3rem;
 }
  .online-page01 .page-tab li.active a {
    font-size: 3rem;
 }
  .online-page01 .online1-page {
    padding-bottom: 5rem;
 }
  .online-page01 .online1-page .guide-txt {
    font-size: 3rem;
    line-height: 4rem;
    margin-bottom: 7.5rem;
 }
  .online-page01 .online1-page .application {
    width: 100%;
 }
  .online-page01 .online1-page .application li {
    width: 100%;
    height: 10rem;
    padding: 0 3rem;
    margin: 0 0 4.5rem;
 }
  .online-page01 .online1-page .application li > div:nth-child(1) {
    font-size: 2.8rem;
    line-height: 10rem;
 }
  .online-page01 .online1-page .application li > div:nth-child(2) {
    float: right;
 }
  .online-page01 .online1-page .application li > div:nth-child(2) a {
    font-size: 2.6rem;
    line-height: 10rem;
 }
  .online-page01 .online1-page .application li > div:nth-child(2) a + a:before {
    height: 2.4rem;
    margin: 0px 3rem;
 }
  .online-page01 .online1-page .section {
    margin-bottom: 5rem;
 }
  .online-page01 .online1-page .section .title {
    font-size: 3.6rem;
    font-weight: 500;
    line-height: 9.6rem;
    letter-spacing: -2px;
    border-bottom: 0.4rem solid #2b3038;
 }
  .online-page01 .online1-page .section .title-sub {
    font-size: 2rem;
    line-height: 2rem;
    padding: 0 0 1rem;
 }
  .online-page01 .online1-page .section .txt-area {
    padding: 1.4rem 0 2rem !important;
    border-bottom: 2px solid #e9e9e9;
 }
  .online-page01 .online1-page .section .txt-area li {
    padding-top: 0.8rem;
    margin-bottom: 1rem;
 }
  .online-page01 .online1-page .section .txt-area li .txt {
    margin-bottom: 0;
 }
  .online-page01 .online1-page .section .txt-area li .txt span:nth-child(1):before {
    width: 0.5rem;
    height: 0.5rem;
    margin: 2.7rem 1.5rem 0 0;
 }
  .online-page01 .online1-page .section .txt-area li .txt span:nth-child(2) {
    width: calc(100% - 2rem);
    font-size: 2.8rem;
    line-height: 3.6rem;
    padding: 1rem 0;
 }
  .online-page01 .online1-page .section .txt-area li .txt.red {
    font-size: 2.8rem;
    line-height: 4.6rem;
 }
  .online-page01 .online1-page .section .table-area {
    overflow-x: auto;
 }
  .online-page01 .online1-page .section .list-table {
    width: 230rem;
 }
  .online-page01 .online1-page .section .list-table .col1 {
    width: 35.5rem;
 }
  .online-page01 .online1-page .section .list-table th {
    font-size: 3rem;
    line-height: 3.4rem;
    padding: 4.5rem 0;
 }
  .online-page01 .online1-page .section .list-table td {
    font-size: 3rem;
    line-height: 3.6rem;
    padding: 4.5rem 0 4.5rem 3rem;
 }
  .online-page01 .online1-page .section .list-table.list-table1 {
    width: 200rem;
 }
  .online-page01 .online1-page .section .list-table.list-table1 .col-th {
    width: 43rem;
 }
  .online-page01 .online1-page .section .list-table.list-table1 th {
    font-size: 3rem;
    height: 12rem;
    padding: 0 3rem;
 }
  .online-page01 .online1-page .section .list-table.list-table1 th .tl {
    text-align: left;
 }
  .online-page01 .online1-page .section .list-table.list-table1 th .tr {
    text-align: right;
    padding-bottom: 2rem;
 }
  .online-page01 .online1-page .section .list-table.list-table1 td {
    font-size: 3rem;
    line-height: 3.6rem;
    text-align: center;
    height: 12rem;
 }
  .online-page01 .online1-page .section .list-table.list-table1 thead th {
    position: relative;
    height: 12rem;
 }
  .online-page01 .online1-page .section .list-table.list-table1 thead .line {
    background: url(/assets/images/linem.png) no-repeat 0 0 / cover;
 }
}

/* 온라인신청 - 표준개발협력 제안 */
.online-page02 .mobile-only {
  display: none;
}
.online-page02 .page-tab li {
  width: calc(100% /4);
}
.online-page02 .page-tab li a {
  font-size: 22px;
  color: #888;
}
.online-page02 .page-tab li.active a {
  font-size: 22px;
  font-weight: 500;
  color: #fff;
}
.online-page02 .online1-page {
  padding-bottom: 160px;
}
.online-page02 .online1-page .guide-txt {
  font-size: 18px;
  color: #2b3038;
  line-height: 26px;
  text-align: center;
  margin-bottom: 50px;
}
.online-page02 .online1-page .guide-txt .blue {
  color: #0062b7;
}
.online-page02 .online1-page .application li {
  float: left;
  width: 407px;
  height: 80px;
  padding: 0 20px;
  margin: 0 0 30px;
  border-radius: 4px;
  background-color: #f8f9f9;
  border: 2px solid #e9e9e9;
}
.online-page02 .online1-page .application li > div:nth-child(1) {
  float: left;
  font-size: 18px;
  font-weight: bold;
  color: #2b3038;
  letter-spacing: -1px;
  line-height: 75px;
}
.online-page02 .online1-page .application li > div:nth-child(2) {
  float: right;
}
.online-page02 .online1-page .application li > div:nth-child(2) a {
  font-size: 16px;
  color: #2b3038;
  letter-spacing: -1px;
  line-height: 75px;
}
.online-page02 .online1-page .application li > div:nth-child(2) a + a:before {
  display: inline-block;
  content: '';
  width: 1px;
  height: 15px;
  background-color: #d7d7d7;
  margin: 0px 20px;
  vertical-align: middle;
}
.online-page02 .online1-page .application li:nth-child(3n+2) {
  margin: 0 29.5px 30px;
}
.online-page02 .online1-page .application li:nth-last-child(1) {
  margin: 0 436.5px 35px;
}
.online-page02 .online1-page .application li:hover {
  border: 2px solid #0061b7;
}
.online-page02 .online1-page .application li:hover > div:nth-child(1) {
  color: #0062b7;
}
.online-page02 .online1-page .section {
  margin-bottom: 35px;
}
.online-page02 .online1-page .section .title {
  font-size: 22px;
  font-weight: bold;
  color: #2b3038;
  line-height: 68px;
  border-bottom: 2px solid #2b3038;
}
.online-page02 .online1-page .section .title span {
  font-weight: 400;
}
.online-page02 .online1-page .section .title .blue {
  color: #0062b7;
}
.online-page02 .online1-page .section .title.fl {
  border-bottom: none;
}
.online-page02 .online1-page .section .title-sub {
  font-size: 12px;
  color: #2b3038;
  letter-spacing: -1px;
  line-height: 56px;
  padding-top: 12px;
}
.online-page02 .online1-page .section .txt-area {
  padding: 12px 0 5px !important;
  border-bottom: 1px solid #e9e9e9;
}
.online-page02 .online1-page .section .txt-area li {
  padding-top: 4px;
  margin-bottom: 10px;
}
.online-page02 .online1-page .section .txt-area li .txt {
  margin-bottom: 5px;
}
.online-page02 .online1-page .section .txt-area li .txt:after {
  content: "";
  display: block;
  clear: both;
}
.online-page02 .online1-page .section .txt-area li .txt span:nth-child(1) {
  float: left;
}
.online-page02 .online1-page .section .txt-area li .txt span:nth-child(1):before {
  float: left;
  content: '';
  width: 3px;
  height: 3px;
  background-color: #999;
  border-radius: 50%;
  margin: 10px 10px 0 0;
}
.online-page02 .online1-page .section .txt-area li .txt span:nth-child(2) {
  float: left;
  width: calc(100% - 13px);
  font-size: 16px;
  color: #2b3038;
  line-height: 26px;
}
.online-page02 .online1-page .section .txt-area li .txt.red {
  color: #e40000;
  line-height: 26px;
}
.online-page02 .online1-page .section .txt-area li .txt1 {
  margin: -7px 0 5px;
}
.online-page02 .online1-page .section .txt-area li .txt1:after {
  content: "";
  display: block;
  clear: both;
}
.online-page02 .online1-page .section .txt-area li .txt1 span:nth-child(1) {
  float: left;
  width: 23px;
  padding-left: 13px;
}
.online-page02 .online1-page .section .txt-area li .txt1 span:nth-child(2) {
  float: left;
  width: calc(100% - 23px);
  font-size: 14px;
  color: #2b3038;
  line-height: 22px;
}
@media screen and (max-width: 1380px) {
  .online-page02 .mobile-only {
    display: block;
 }
  .online-page02 .online1-page .application {
    width: 874px;
    margin: 0 auto !important;
 }
  .online-page02 .online1-page .application li:nth-child(2n) {
    margin: 0 0 30px 30px;
 }
}
@media screen and (max-width: 1000px) {
  .online-page02 .page-tab li {
    width: calc(100% /2);
 }
  .online-page02 .online1-page .application {
    width: 407px;
    margin: 0 auto !important;
 }
  .online-page02 .online1-page .application li:nth-child(3n+2) {
    margin: 0 0 30px;
 }
  .online-page02 .online1-page .application li:nth-child(2n) {
    margin: 0 0 30px;
 }
  .online-page02 .online1-page .application li:nth-last-child(1) {
    margin: 0 0 35px;
 }
}
@media screen and (max-width: 720px) {
  .online-page02 .page-tab li {
    width: calc(100% /2);
 }
  .online-page02 .page-tab li a {
    font-size: 3rem;
 }
  .online-page02 .page-tab li.active a {
    font-size: 3rem;
 }
  .online-page02 .online1-page {
    padding-bottom: 5rem;
 }
  .online-page02 .online1-page .guide-txt {
    font-size: 3rem;
    line-height: 4rem;
    margin-bottom: 7.5rem;
 }
  .online-page02 .online1-page .application {
    width: 100%;
 }
  .online-page02 .online1-page .application li {
    float: none;
    width: 100%;
    height: 10rem;
    padding: 0 3rem;
    margin: 0 0 3rem;
 }
  .online-page02 .online1-page .application li > div:nth-child(1) {
    font-size: 2.8rem;
    line-height: 10rem;
 }
  .online-page02 .online1-page .application li > div:nth-child(2) {
    float: right;
 }
  .online-page02 .online1-page .application li > div:nth-child(2) a {
    font-size: 2.6rem;
    line-height: 10rem;
 }
  .online-page02 .online1-page .application li > div:nth-child(2) a + a:before {
    height: 2.4rem;
    margin: 0px 3rem;
 }
  .online-page02 .online1-page .application li:nth-child(2n) {
    margin: 0 0 3rem;
 }
  .online-page02 .online1-page .application li:nth-last-child(1) {
    margin: 0 0 4.5rem;
 }
  .online-page02 .online1-page .section {
    margin-bottom: 5rem;
 }
  .online-page02 .online1-page .section .title {
    font-size: 3.6rem;
    font-weight: 500;
    line-height: 9.6rem;
    letter-spacing: -2px;
    border-bottom: 0.4rem solid #2b3038;
 }
  .online-page02 .online1-page .section .title-sub {
    font-size: 2rem;
    line-height: 8rem;
 }
  .online-page02 .online1-page .section .txt-area {
    padding: 1.4rem 0 2rem !important;
    border-bottom: 2px solid #e9e9e9;
 }
  .online-page02 .online1-page .section .txt-area li {
    padding-top: 0.8rem;
    margin-bottom: 1rem;
 }
  .online-page02 .online1-page .section .txt-area li .txt {
    margin-bottom: 0;
 }
  .online-page02 .online1-page .section .txt-area li .txt span:nth-child(1):before {
    width: 0.5rem;
    height: 0.5rem;
    margin: 2.7rem 1.5rem 0 0;
 }
  .online-page02 .online1-page .section .txt-area li .txt span:nth-child(2) {
    width: calc(100% - 2rem);
    font-size: 2.8rem;
    line-height: 3.6rem;
    padding: 1rem 0;
 }
  .online-page02 .online1-page .section .txt-area li .txt.red {
    font-size: 2.8rem;
    line-height: 4.6rem;
 }
  .online-page02 .online1-page .section .txt-area li .txt1 {
    margin: 0;
 }
  .online-page02 .online1-page .section .txt-area li .txt1 span:nth-child(1) {
    font-size: 2.8rem;
    line-height: 3.6rem;
    width: 4rem;
    padding-left: 2rem;
 }
  .online-page02 .online1-page .section .txt-area li .txt1 span:nth-child(2) {
    float: left;
    width: calc(100% - 4rem);
    font-size: 2.8rem;
    line-height: 3.6rem;
 }
}

/* 온라인신청 - 진흥회 가입신청 */
.online-page03 .mobile-only {
  display: none;
}
.online-page03 .page-tab li {
  width: calc(100% /4);
}
.online-page03 .page-tab li a {
  font-size: 22px;
  color: #888;
}
.online-page03 .page-tab li.active a {
  font-size: 22px;
  font-weight: 500;
  color: #fff;
}
.online-page03 .online1-page {
  padding-bottom: 160px;
}
.online-page03 .online1-page .guide-txt {
  font-size: 18px;
  color: #2b3038;
  line-height: 26px;
  text-align: center;
  margin-bottom: 50px;
}
.online-page03 .online1-page .guide-txt .blue {
  color: #0062b7;
}
.online-page03 .online1-page .application li {
  float: left;
  width: 407px;
  height: 80px;
  padding: 0 20px;
  margin: 0 0 30px;
  border-radius: 4px;
  background-color: #f8f9f9;
  border: 2px solid #e9e9e9;
}
.online-page03 .online1-page .application li > div:nth-child(1) {
  float: left;
  font-size: 18px;
  font-weight: bold;
  color: #2b3038;
  letter-spacing: -1px;
  line-height: 75px;
}
.online-page03 .online1-page .application li > div:nth-child(2) {
  float: right;
}
.online-page03 .online1-page .application li > div:nth-child(2) a {
  font-size: 16px;
  color: #2b3038;
  letter-spacing: -1px;
  line-height: 75px;
}
.online-page03 .online1-page .application li > div:nth-child(2) a + a:before {
  display: inline-block;
  content: '';
  width: 1px;
  height: 15px;
  background-color: #d7d7d7;
  margin: 0px 20px;
  vertical-align: middle;
}
.online-page03 .online1-page .application li:nth-last-child(1) {
  margin: 0 calc((100% - 407px) / 2) 35px;
}
.online-page03 .online1-page .application li:hover {
  border: 2px solid #0061b7;
}
.online-page03 .online1-page .application li:hover > div:nth-child(1) {
  color: #0062b7;
}
.online-page03 .online1-page .section {
  margin-bottom: 35px;
}
.online-page03 .online1-page .section .title {
  font-size: 22px;
  font-weight: bold;
  color: #2b3038;
  line-height: 68px;
  border-bottom: 2px solid #2b3038;
}
.online-page03 .online1-page .section .title span {
  font-weight: 400;
}
.online-page03 .online1-page .section .title .blue {
  color: #0062b7;
}
.online-page03 .online1-page .section .title.fl {
  border-bottom: none;
}
.online-page03 .online1-page .section .title-sub {
  font-size: 12px;
  color: #2b3038;
  letter-spacing: -1px;
  line-height: 56px;
  padding-top: 12px;
}
.online-page03 .online1-page .section .txt-area {
  padding: 12px 0 5px !important;
  border-bottom: 1px solid #e9e9e9;
}
.online-page03 .online1-page .section .txt-area li {
  padding-top: 4px;
  margin-bottom: 10px;
}
.online-page03 .online1-page .section .txt-area li .txt {
  margin-bottom: 5px;
}
.online-page03 .online1-page .section .txt-area li .txt:after {
  content: "";
  display: block;
  clear: both;
}
.online-page03 .online1-page .section .txt-area li .txt span:nth-child(1) {
  float: left;
}
.online-page03 .online1-page .section .txt-area li .txt span:nth-child(1):before {
  float: left;
  content: '';
  width: 3px;
  height: 3px;
  background-color: #999;
  border-radius: 50%;
  margin: 10px 10px 0 0;
}
.online-page03 .online1-page .section .txt-area li .txt span:nth-child(2) {
  float: left;
  width: calc(100% - 13px);
  font-size: 16px;
  color: #2b3038;
  line-height: 26px;
}
.online-page03 .online1-page .section .txt-area li .txt.red {
  color: #e40000;
  line-height: 26px;
}
.online-page03 .online1-page .section .txt-area li .txt1 {
  margin: -7px 0 5px;
}
.online-page03 .online1-page .section .txt-area li .txt1:after {
  content: "";
  display: block;
  clear: both;
}
.online-page03 .online1-page .section .txt-area li .txt1 span:nth-child(1) {
  float: left;
  width: 23px;
  padding-left: 13px;
}
.online-page03 .online1-page .section .txt-area li .txt1 span:nth-child(2) {
  float: left;
  width: calc(100% - 23px);
  font-size: 14px;
  color: #2b3038;
  line-height: 22px;
}
.online-page03 .online1-page .section .step-ul {
  padding: 40px 0 !important;
  border-bottom: 1px solid #e9e9e9;
}
.online-page03 .online1-page .section .step-ul li {
  text-align: center;
  padding: 0 calc((100% - 500px) / 2) 80px;
  background: url(/assets/images/icon_down.png) no-repeat center bottom 30px;
}
.online-page03 .online1-page .section .step-ul li > div {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 500px;
  height: 90px;
  border-radius: 45px;
  font-size: 18px;
  font-weight: 500;
  line-height: 26px;
}
.online-page03 .online1-page .section .step-ul li:nth-child(1) > div {
  color: #0176dc;
  background-color: #fff;
  border: 2px solid #6390e4;
}
.online-page03 .online1-page .section .step-ul li:nth-child(2) > div {
  color: #fff;
  background-color: #6390e4;
}
.online-page03 .online1-page .section .step-ul li:nth-child(3) > div {
  color: #fff;
  background-color: #0176dc;
}
.online-page03 .online1-page .section .step-ul li:nth-child(4) {
  padding-bottom: 0;
  background: none;
}
.online-page03 .online1-page .section .step-ul li:nth-child(4) > div {
  color: #fff;
  background-color: #174899;
}
@media screen and (max-width: 1000px) {
  .online-page03 .mobile-only {
    display: block;
 }
  .online-page03 .page-tab li {
    width: calc(100% /2);
 }
}
@media screen and (max-width: 720px) {
  .online-page03 .page-tab li {
    width: calc(100% /2);
 }
  .online-page03 .page-tab li a {
    font-size: 3rem;
 }
  .online-page03 .page-tab li.active a {
    font-size: 3rem;
 }
  .online-page03 .online1-page {
    padding-bottom: 5rem;
 }
  .online-page03 .online1-page .guide-txt {
    font-size: 3rem;
    line-height: 4rem;
    margin-bottom: 7.5rem;
 }
  .online-page03 .online1-page .application {
    width: 100%;
 }
  .online-page03 .online1-page .application li {
    float: none;
    width: 100%;
    height: 10rem;
    padding: 0 3rem;
    margin: 0 0 3rem;
 }
  .online-page03 .online1-page .application li > div:nth-child(1) {
    font-size: 2.8rem;
    line-height: 10rem;
 }
  .online-page03 .online1-page .application li > div:nth-child(2) {
    float: right;
 }
  .online-page03 .online1-page .application li > div:nth-child(2) a {
    font-size: 2.6rem;
    line-height: 10rem;
 }
  .online-page03 .online1-page .application li > div:nth-child(2) a + a:before {
    height: 2.4rem;
    margin: 0px 3rem;
 }
  .online-page03 .online1-page .application li:nth-child(2n) {
    margin: 0 0 3rem;
 }
  .online-page03 .online1-page .application li:nth-last-child(1) {
    margin: 0 0 4.5rem;
 }
  .online-page03 .online1-page .section {
    margin-bottom: 5rem;
 }
  .online-page03 .online1-page .section .title {
    font-size: 3.6rem;
    font-weight: 500;
    line-height: 9.6rem;
    letter-spacing: -2px;
    border-bottom: 0.4rem solid #2b3038;
 }
  .online-page03 .online1-page .section .title-sub {
    font-size: 2rem;
    line-height: 8rem;
 }
  .online-page03 .online1-page .section .txt-area {
    padding: 1.4rem 0 2rem !important;
    border-bottom: 2px solid #e9e9e9;
 }
  .online-page03 .online1-page .section .txt-area li {
    padding-top: 0.8rem;
    margin-bottom: 1rem;
 }
  .online-page03 .online1-page .section .txt-area li .txt {
    margin-bottom: 0;
 }
  .online-page03 .online1-page .section .txt-area li .txt span:nth-child(1):before {
    width: 0.5rem;
    height: 0.5rem;
    margin: 2.7rem 1.5rem 0 0;
 }
  .online-page03 .online1-page .section .txt-area li .txt span:nth-child(2) {
    width: calc(100% - 2rem);
    font-size: 2.8rem;
    line-height: 3.6rem;
    padding: 1rem 0;
 }
  .online-page03 .online1-page .section .txt-area li .txt.red {
    font-size: 2.8rem;
    line-height: 4.6rem;
 }
  .online-page03 .online1-page .section .txt-area li .txt1 {
    margin: 0;
 }
  .online-page03 .online1-page .section .txt-area li .txt1 span:nth-child(1) {
    font-size: 2.8rem;
    line-height: 3.6rem;
    width: 4rem;
    padding-left: 2rem;
 }
  .online-page03 .online1-page .section .txt-area li .txt1 span:nth-child(2) {
    float: left;
    width: calc(100% - 4rem);
    font-size: 2.8rem;
    line-height: 3.6rem;
 }
  .online-page03 .online1-page .section .step-ul {
    padding: 4rem 0 !important;
    border-bottom: 1px solid #e9e9e9;
 }
  .online-page03 .online1-page .section .step-ul li {
    text-align: center;
    padding: 0 0 10.5rem;
    background: url(/assets/images/icon_down.png) no-repeat center bottom 4rem / 6.5rem auto;
 }
  .online-page03 .online1-page .section .step-ul li > div {
    width: 100%;
    height: 17rem;
    border-radius: 8.5rem;
    font-size: 2.8rem;
    line-height: 3.6rem;
 }
}

/* 온라인신청 - 단체표준인증신청 */
.online-page04 .online1-page {
  padding-bottom: 100px;
}
.online-page04 .online1-page .guide-txt {
  font-size: 18px;
  color: #2b3038;
  line-height: 26px;
  text-align: center;
  margin-bottom: 50px;
}
.online-page04 .online1-page .guide-txt .blue {
  color: #0062b7;
}
.online-page04 .online1-page .application {
  width: 280px;
  margin: 0 auto !important;
}
.online-page04 .online1-page .application li {
  width: 280px;
  height: 80px;
  padding: 0 20px;
  margin: 0 0 35px;
  border-radius: 4px;
  background-color: #f8f9f9;
  border: 2px solid #e9e9e9;
}
.online-page04 .online1-page .application li > div:nth-child(1) {
  float: left;
  font-size: 18px;
  font-weight: bold;
  color: #2b3038;
  letter-spacing: -1px;
  line-height: 75px;
}
.online-page04 .online1-page .application li > div:nth-child(2) {
  float: right;
}
.online-page04 .online1-page .application li > div:nth-child(2) a {
  font-size: 16px;
  color: #2b3038;
  letter-spacing: -1px;
  line-height: 75px;
}
.online-page04 .online1-page .application li > div:nth-child(2) a + a:before {
  display: inline-block;
  content: '';
  width: 1px;
  height: 15px;
  background-color: #d7d7d7;
  margin: 0px 20px;
  vertical-align: middle;
}
.online-page04 .online1-page .application li:hover {
  border: 2px solid #0061b7;
}
.online-page04 .online1-page .application li:hover > div:nth-child(1) {
  color: #0062b7;
}
.online-page04 .online1-page .section {
  margin-bottom: 35px;
}
.online-page04 .online1-page .section .title {
  font-size: 22px;
  font-weight: bold;
  color: #2b3038;
  line-height: 68px;
  border-bottom: 2px solid #2b3038;
}
.online-page04 .online1-page .section .title span {
  font-weight: 400;
}
.online-page04 .online1-page .section .title .blue {
  color: #0062b7;
}
.online-page04 .online1-page .section .title.fl, .online-page04 .online1-page .section .title.noline {
  border-bottom: none;
}
.online-page04 .online1-page .section .title-sub {
  font-size: 12px;
  color: #2b3038;
  letter-spacing: -1px;
  line-height: 56px;
  padding-top: 12px;
}
.online-page04 .online1-page .mark-ci {
  float: left;
  width: 350px;
  border-top: 2px solid #2b3038 !important;
}
.online-page04 .online1-page .mark-ci .mark1 {
  text-align: left;
  padding: 10px 0 10px 20px;
}  
.online-page04 .online1-page .mark-ci .mark1 .mark-nm {
  font-size: 16px;
  padding: 0 0 10px 0;
}  
.online-page04 .online1-page .mark-ci .mark1 img {
  width: auto;
  max-width: 95%;
  height: 40px;
}

.online-page04 .online1-page .mark-ci .down-btn {
  display: inline-block;
  font-size: 14px;
  font-weight: 500;
  color: #2b3038;
  line-height: 34px;
  text-align: center;
  width: 118px;
  height: 36px;
  border: 1px solid #d7d7d7;
  border-radius: 5px;
  margin: 10px 2px 0;
}  
.online-page04 .online1-page .table-area.mark-table {
  float: right;
  width: calc(100% - 360px);
}  
.online-page04 .online1-page .table-area.mark-table td {
  height: 58px;
}  
.online-page04 .online1-page .section .txt-area {
  padding: 12px 0 5px !important;
  border-bottom: 1px solid #e9e9e9;
}
.online-page04 .online1-page .section table .txt-area {
  border: none;
}
.online-page04 .online1-page .section .txt-area li {
  padding-top: 4px;
  margin-bottom: 10px;
}
.online-page04 .online1-page .section .txt-area li .txt,
.online-page04 .online1-page .list-table td .txt,
.online-page04 .online1-page .section .step-div .step-txt .txt {
  margin-bottom: 5px;
}
.online-page04 .online1-page .section .txt-area li .txt:after,
.online-page04 .online1-page .list-table td .txt:after,
.online-page04 .online1-page .section .step-div .step-txt .txt:after {
  content: "";
  display: block;
  clear: both;
}
.online-page04 .online1-page .section .txt-area li .txt span:nth-child(1),
.online-page04 .online1-page .list-table td .txt span:nth-child(1),
.online-page04 .online1-page .section .step-div .step-txt .txt span:nth-child(1) {
  float: left;
}
.online-page04 .online1-page .section .txt-area li .txt span:nth-child(1):before,
.online-page04 .online1-page .list-table td .txt span:nth-child(1):before,
.online-page04 .online1-page .section .step-div .step-txt .txt span:nth-child(1):before {
  float: left;
  content: '';
  width: 3px;
  height: 3px;
  background-color: #999;
  border-radius: 50%;
  margin: 10px 10px 0 0;
}
.online-page04 .online1-page .section .txt-area li .txt span:nth-child(2),
.online-page04 .online1-page .section .list-table .txt span:nth-child(2),
.online-page04 .online1-page .section .step-div .step-txt .txt span:nth-child(2)  {
  float: left;
  width: calc(100% - 13px);
  font-size: 16px;
  color: #2b3038;
  line-height: 26px;
}
.online-page04 .online1-page .section .list-table .txt span:nth-child(2) {
  font-size: 14px;
}
.online-page04 .online1-page .section .txt-area li .txt.red {
  color: #e40000;
  line-height: 26px;
}
.online-page04 .online1-page .section .list-table {
  border-top: none;
}
.online-page04 .online1-page .section .list-table th {
  line-height: 20px;
  padding: 0 30px;
}
.online-page04 .online1-page .section .list-table td {
  line-height: 26px;
  text-align: left;
  padding: 24px 0 24px 20px;
}
.online-page04 .online1-page .section .list-table.center td {
  text-align: center;
}
.online-page04 .online1-page .section .list-table td .btn {
  font-size: 14px;
  width: 75px;
  height: 30px;
  line-height: 1;
  background: #fff;
  border: 1px solid #d7d7d7;
}
.online-page04 .online1-page .section .img {
  padding: 80px 0;
  border-bottom: 1px solid #e9e9e9;
  text-align: center;
}
.online-page04 .online1-page .section .img img {
  width: 80%;
}    
.online-page04 .online1-page .section .down-btn a {
  font-size: 14px;
  width: 200px;
  height: 40px;
  background: #fff;
  border: 1px solid #d7d7d7;
  margin: 10px 10px 0 0 !important;
}
.online-page04 .online1-page .section .step-div {
  margin-top: 40px !important;
}
.online-page04 .online1-page .section .step-div .step {
  font-size: 20px;
  color: #2b3038;
  line-height: 60px;
  height: 60px;
  padding-left: 30px;
  border: 1px solid #d7d7d7;
}
.online-page04 .online1-page .section .step-div .step > span {
  font-size: 20px;
  font-weight: 500;
  color: #0062b7;
  line-height: 60px;
  padding-right: 30px;
}  
.online-page04 .online1-page .section .step-div .step-table {
  width: 100%;
  border-top: none !important;
}
.online-page04 .online1-page .section .step-div .step-table .col {
  width: 200px;
}
.online-page04 .online1-page .section .step-div .step-txt {
  font-size: 16px;
  padding: 20px 30px;
}   
.online-page04 .online1-page .section .step-div .step-txt .txt1 {
  font-size: 16px;
  line-height: 26px;
  padding-left: 30px;
  margin: 0 !important;
} 
.online-page04 .online1-page .section .step-div .step-txt .step-box {
  display:flex;
  align-items: center;  
  font-size: 17px;
  color: #2b3038;
  line-height: 30px;
  padding: 20px 30px;
  border-radius: 35px;
  background-color: #f8f9f9;
  border: 1px solid #e9e9e9;
  margin: 30px 70px;
}
.online-page04 .online1-page .section .step-div .step-txt .step-box > span {
  font-size: 17px;
  font-weight: 500;
  color: #0062b7;
  line-height: 20px;
  border-right: 1px solid #d7d7d7;
  padding-right: 30px;
  margin-right: 30px;
}

@media screen and (max-width: 720px) {

  .online-page04 .online1-page {
    padding-bottom: 5rem;
 }
  .online-page04 .online1-page .guide-txt {
    font-size: 3rem;
    line-height: 4rem;
    margin-bottom: 7.5rem;
 }
  .online-page04 .online1-page .application {
    width: 100%;
 }
  .online-page04 .online1-page .application li {
    width: 100%;
    height: 10rem;
    padding: 0 3rem;
    margin: 0 0 4.5rem;
 }
  .online-page04 .online1-page .application li > div:nth-child(1) {
    font-size: 2.8rem;
    line-height: 10rem;
 }
  .online-page04 .online1-page .application li > div:nth-child(2) {
    float: right;
    width: 20rem;
 }
  .online-page04 .online1-page .application li > div:nth-child(2) a {
    font-size: 2.6rem;
    line-height: 10rem;
 }
  .online-page04 .online1-page .application li > div:nth-child(2) a + a:before {
    height: 2.4rem;
    margin: 0px 3rem;
 }
  .online-page04 .online1-page .section {
    margin-bottom: 5rem;
 }
  .online-page04 .online1-page .section .title {
    font-size: 3.6rem;
    font-weight: 500;
    line-height: 9.6rem;
    letter-spacing: -2px;
    border-bottom: 0.4rem solid #2b3038;
 }
  .online-page04 .online1-page .section .title-sub {
    font-size: 2rem;
    line-height: 2rem;
    padding: 0 0 1rem;
 }
 .online-page04 .online1-page .mark-ci {
  float: none;
  width: 100%;
  border-top: 2px solid #2b3038 !important;
}
.online-page04 .online1-page .mark-ci img {
  display: block;
  width: 35rem;
  height: 35rem;  
  margin: 0 auto;
}
.online-page04 .online1-page .mark-ci .down-btn {
  display: inline-block;
  font-size: 3rem;
  font-weight: 500;
  color: #2b3038;
  line-height: 7rem;
  text-align: center;
  width: calc(50% - 1.1rem);
  height: 7rem;
  border: 1px solid #d7d7d7;
  border-radius: 5px;
  margin: 0 0 3rem;
}  
.online-page04 .online1-page .mark-ci .down-btn:nth-child(1) {
  margin-right: 2rem;
}
.online-page04 .online1-page .table-area.mark-table {
  float: none;
  width: 100%;
}  
.online-page04 .online1-page .table-area.mark-table td {
  height: 9rem;
} 


  .online-page04 .online1-page .section .txt-area {
    padding: 1.4rem 0 2rem !important;
    border-bottom: 2px solid #e9e9e9;
 }
  .online-page04 .online1-page .section .txt-area li {
    padding-top: 0.8rem;
    margin-bottom: 1rem;
 }
  .online-page04 .online1-page .section .txt-area li .txt,
  .online-page04 .online1-page .list-table td .txt,
  .online-page04 .online1-page .section .step-div .step-txt .txt {
    margin-bottom: 0;
 }
  .online-page04 .online1-page .section .txt-area li .txt span:nth-child(1):before,
  .online-page04 .online1-page .list-table td .txt span:nth-child(1):before,
  .online-page04 .online1-page .section .step-div .step-txt .txt span:nth-child(1):before {
    width: 0.5rem;
    height: 0.5rem;
    margin: 2.7rem 1.5rem 0 0;
 }
  .online-page04 .online1-page .section .txt-area li .txt span:nth-child(2),
  .online-page04 .online1-page .section .list-table .txt span:nth-child(2),
  .online-page04 .online1-page .section .step-div .step-txt .txt span:nth-child(2) {
    width: calc(100% - 2rem);
    font-size: 2.8rem;
    line-height: 3.6rem;
    padding: 1rem 0;
 }
  .online-page04 .online1-page .section .txt-area li .txt.red {
    font-size: 2.8rem;
    line-height: 4.6rem;
 }
  .online-page04 .online1-page .section .table-area {
    overflow-x: auto;
 }
  .online-page04 .online1-page .section .list-table {
    width: 230rem;
 }
 .online-page04 .online1-page .section .list-table.center {
    width: 100rem;
 } 
  .online-page04 .online1-page .section .list-table .col1 {
    width: 35.5rem;
 }
  .online-page04 .online1-page .section .list-table th {
    font-size: 3rem;
    line-height: 3.4rem;
    padding: 4.5rem 0;
 }
  .online-page04 .online1-page .section .list-table td {
    font-size: 3rem;
    line-height: 3.6rem;
    padding: 4.5rem 0 4.5rem 3rem;
 }
  .online-page04 .online1-page .section .list-table td .btn {
    font-size: 3rem;
    width: 20rem;
    height: 6rem;
  }
  .online-page04 .online1-page .section .step-div .step {
    font-size: 3rem;
    color: #2b3038;
    line-height: 8rem;
    height: 8rem;
    padding-left: 3rem;
    border: 1px solid #d7d7d7;
  }
  .online-page04 .online1-page .section .step-div .step > span {
    font-size: 3rem;
    font-weight: 500;
    color: #0062b7;
    line-height: 8rem;
    padding-right: 3rem;
  }    
  .online-page04 .online1-page .section .step-div .step-txt {
    font-size: 2.5rem;
    padding: 2rem 0;
  }  
  .online-page04 .online1-page .section .step-div .step-txt .step-box {
    display:flex;
    align-items: center;  
    font-size: 2rem;
    color: #2b3038;
    line-height: 3rem;
    padding: 2rem 3rem;
    border-radius: 1rem;
    background-color: #f8f9f9;
    border: 1px solid #e9e9e9;
    margin: 3rem 0;
  }
  .online-page04 .online1-page .section .step-div .step-txt .step-box > span {
    font-size: 2.5rem;
    font-weight: 500;
    color: #0062b7;
    line-height: 4rem;
    border-right: 1px solid #d7d7d7;
    padding-right: 30px;
    margin-right: 30px;
  }    
  .online-page04 .online1-page .section .step-div .step-txt .txt1 {
    font-size: 2.5rem;
    line-height: 3.5rem;
    padding-left: 3rem;
    margin: 0 !important;
  }   
  .online-page04 .online1-page .section .down-btn a {
    font-size: 3rem;
    width: 100%;
    height: 7rem;
    background: #fff;
    border: 1px solid #d7d7d7;
    margin: 3rem 0 0 !important;
  }   
  .online-page04 .online1-page .section .step-div .step-table .col {
    width: 20rem;
  }
}



/* 온라인신청 - 제품인증(KAS)신청 */
.online-page05 {
  padding-bottom: 60px;
}
@media screen and (max-width: 720px) {
  .online-page05 {
    padding-bottom: 4rem;
  }  
}

/* 고객서비스 - 해외시장 조사자료 */ 
.overseas-page {
  padding: 35px 0 55px;
}
.overseas-page .section {
  margin-bottom: 45px;
}
.overseas-page .section .title {
  font-size: 22px;
  font-weight: bold;
  color: #2b3038;
  line-height: 68px;
}
.overseas-page .section .title span {
  font-weight: 400;
}
.overseas-page .section .goods {
  border-top: 2px solid #2b3038;
  border-bottom: 1px solid #e9e9e9;
}
.overseas-page .section .goods li {
  float: left;
  width: 20%;
  height: 60px;
  font-size: 16px;
  line-height: 60px;
  text-align: center;
  border-right: 1px solid #e9e9e9;
}
.overseas-page .section .goods li:nth-last-child(1) {
  border-right: none;
}
.overseas-page .section .list-table td {
  height: 60px;
  font-size: 16px;
  line-height: 1.3;
}
.overseas-page .section .list-table.list-table1 tr td:nth-child(1) {
  font-weight: bold;
}
.overseas-page .section .list-table.list-table1 tr td:nth-child(2) {
  text-align: left;
  padding-left: 20px !important;
}
@media screen and (max-width: 720px) {
  .overseas-page {
    padding: 3.5rem 0 10rem;
 }
  .overseas-page .section {
    margin-bottom: 0;
 }
  .overseas-page .section .title {
    font-size: 3.6rem;
    font-weight: 500;
    line-height: 9rem;
 }
  .overseas-page .section .goods {
    padding: 2.5rem 0 !important;
    margin-bottom: 5.5rem !important;
 }
  .overseas-page .section .goods li {
    float: left;
    width: 50%;
    height: 6rem;
    font-size: 2.8rem;
    line-height: 5.6rem;
    text-align: left;
    border-right: none;
 }
  .overseas-page .section .goods li:before {
    float: left;
    content: '';
    width: 0.5rem;
    height: 0.5rem;
    background-color: #999;
    border-radius: 50%;
    margin: 2.7rem 1.5rem 0 0;
 }
  .overseas-page .section .list-table th {
    font-size: 3rem;
    height: 12rem;
 }
  .overseas-page .section .list-table td {
    height: 12rem;
    font-size: 3rem;
    padding: 0 2rem !important;
    text-align: left;
 }
}

/* 고객서비스 - 통계자료 - 무역통계 */
.trade-statistics {
  padding-bottom: 60px;
}
.trade-statistics .tab {
  width: fit-content;
  margin: 60px auto 34px !important;
}
.trade-statistics .tab li {
  float: left;
  width: 297px;
  height: 50px;
  font-size: 22px;
  color: #888;
  line-height: 48px;
  text-align: center;
  border-radius: 25px;
  background-color: #f8f9f9;
  border: 1px solid #e9e9e9;
  margin-right: 30px;
  cursor: pointer;
}
.trade-statistics .tab li.active {
  color: #fff;
  font-weight: bold;
  background: #0176dc;
  border-right: 1px solid #0176dc;
}
.trade-statistics .tab li:nth-last-child(1) {
  margin-right: 0;
}
.trade-statistics .section {
  margin-bottom: 45px;
}
.trade-statistics .section .title {
  float: left;
  font-size: 22px;
  font-weight: bold;
  color: #2b3038;
  line-height: 56px;
}
.trade-statistics .section .title span {
  font-weight: 400;
}
.trade-statistics .section .unit {
  float: right;
  font-size: 12px;
  color: #2b3038;
  line-height: 56px;
  padding-top: 3px;
}
.trade-statistics .section .list-table th {
  height: 70px;
}
.trade-statistics .section .list-table th span {
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 0;
}
.trade-statistics .section .list-table td {
  letter-spacing: 0;
  text-align: right;
  padding: 0 30px;
}
.trade-statistics .section .list-table td span {
  font-size: 12px;
  letter-spacing: 0;
}
.trade-statistics .section .list-table tr td:nth-child(1) {
  text-align: center;
}
@media screen and (max-width: 720px) {
  .trade-statistics {
    padding-bottom: 6rem;
 }
  .trade-statistics .tab {
    width: 100%;
    margin: 8rem auto 6rem !important;
 }
  .trade-statistics .tab li {
    float: left;
    width: 50%;
    height: 12rem;
    font-size: 3rem;
    line-height: 12rem;
    border-radius: 0;
    margin-right: 0;
 }
  .trade-statistics .section {
    width: 100%;
    margin-bottom: 4rem;
    overflow-y: auto;
 }
  .trade-statistics .section .section-title {
    width: 120rem;
 }
  .trade-statistics .section .title {
    float: left;
    font-size: 3.6rem;
    font-weight: 500;
    line-height: 9rem;
 }
  .trade-statistics .section .unit {
    float: right;
    font-size: 2rem;
    color: #2b3038;
    line-height: 7rem;
    padding-top: 2rem;
 }
  .trade-statistics .section .list-table {
    width: 120rem;
 }
  .trade-statistics .section .list-table th {
    font-size: 3rem;
    line-height: 3.6rem;
    height: 12rem;
 }
  .trade-statistics .section .list-table th span {
    font-size: 2.8rem;
    font-weight: 400;
 }
  .trade-statistics .section .list-table td {
    font-size: 3rem;
    letter-spacing: -1px;
    text-align: right;
    height: 12rem;
    padding: 0 2rem;
 }
  .trade-statistics .section .list-table td span {
    font-size: 3rem;
    letter-spacing: 0;
 }
  .trade-statistics .section .list-table tr td:nth-child(1) {
    text-align: center;
 }
}

/* 고객서비스 - 통계자료 - 생산통계 */
.output-statistics {
  padding-bottom: 60px;
}
.output-statistics .section {
  margin-bottom: 45px;
}
.output-statistics .section .title {
  float: left;
  font-size: 22px;
  font-weight: bold;
  color: #2b3038;
  line-height: 56px;
}
.output-statistics .section .title span {
  font-weight: 400;
}
.output-statistics .section .unit {
  float: right;
  font-size: 12px;
  color: #2b3038;
  line-height: 56px;
  padding-top: 3px;
}
.output-statistics .section .list-table {
  table-layout: fixed;
}
.output-statistics .section .list-table .col1 {
  width: 65px;
}
.output-statistics .section .list-table th {
  height: 40px;
  font-size: 14px;
}
.output-statistics .section .list-table tr.f12 th {
  font-size: 12px;
}
.output-statistics .section .list-table td {
  font-size: 12px;
  letter-spacing: 0;
  text-align: right;
  height: 40px;
  padding: 0 5px;
}
.output-statistics .section .list-table tr td:nth-child(1) {
  text-align: center;
}
.output-statistics .section .txt {
  font-size: 12px;
  color: #2b3038;
  padding: 20px 0 10px;
}
@media screen and (max-width: 1100px) {
  .output-statistics .section {
    width: 100%;
    overflow-y: auto;
 }
  .output-statistics .section .section-title {
    width: 1020px;
 }
  .output-statistics .section .list-table {
    table-layout: fixed;
    width: 1020px;
 }
}
@media screen and (max-width: 720px) {
  .output-statistics {
    padding-bottom: 6rem;
 }
  .output-statistics .section {
    width: 100%;
    margin-bottom: 4rem;
    overflow-y: auto;
 }
  .output-statistics .section .section-title {
    width: 300rem;
 }
  .output-statistics .section .title {
    float: left;
    font-size: 3.6rem;
    font-weight: 500;
    line-height: 9rem;
 }
  .output-statistics .section .unit {
    float: right;
    font-size: 2rem;
    color: #2b3038;
    line-height: 7rem;
    padding-top: 2rem;
 }
  .output-statistics .section .list-table {
    table-layout: fixed;
    width: 300rem;
 }
  .output-statistics .section .list-table .col1 {
    width: 15rem;
 }
  .output-statistics .section .list-table th {
    font-size: 3rem;
    line-height: 3.6rem;
    height: 12rem;
 }
  .output-statistics .section .list-table tr.f12 th {
    font-size: 3rem;
 }
  .output-statistics .section .list-table td {
    font-size: 3rem;
    letter-spacing: -1px;
    text-align: right;
    height: 12rem;
    padding: 0 2rem;
 }
  .output-statistics .section .list-table tr td:nth-child(1) {
    text-align: center;
 }
  .output-statistics .section .txt {
    font-size: 2.4rem;
    color: #2b3038;
    padding: 4rem 0 2rem;
 }
}

/* 고객서비스 - 성상분석data */
.analysis-page {
  padding: 38px 0 100px;
}
.analysis-page .section {
  margin-bottom: 45px;
}
.analysis-page .section .title {
  float: left;
  font-size: 22px;
  font-weight: bold;
  color: #2b3038;
  line-height: 88px;
}
.analysis-page .section .title span {
  font-size: 14px;
  font-weight: 400;
}
.analysis-page .section .ym {
  float: right;
  font-size: 12px;
  color: #2b3038;
  line-height: 56px;
  padding-top: 23px;
}
.analysis-page .section .ym > div {
  position: relative;
}
.analysis-page .section .ym .select-title {
  font-size: 16px;
  color: #2b3038;
  line-height: 45px;
  width: 120px;
  padding-left: 10px;
  border-bottom: 1px solid #e9e9e9;
  background: url(/assets/images/icon_select.png) no-repeat right 10px center;
  cursor: pointer;
}
.analysis-page .section .ym ul {
  position: absolute;
  width: 100%;
  border: 1px solid #e9e9e9;
}
.analysis-page .section .ym ul li {
  font-size: 14px;
  color: #2b3038;
  line-height: 30px;
  text-align: left;
  padding-left: 10px;
  background: #fff;
  cursor: pointer;
}
.analysis-page .section .ym ul li:hover {
  background: #f6f9fe;
}
.analysis-page .section .list-table {
  table-layout: fixed;
}
.analysis-page .section .list-table th {
  height: 40px;
  font-size: 14px;
}
.analysis-page .section .list-table tr.white th {
  background: transparent;
}
.analysis-page .section .list-table tr.white th:nth-child(-n+6) {
  font-family: 'Roboto';
  font-weight: 600;
}
.analysis-page .section .list-table td {
  font-size: 12px;
  letter-spacing: 0;
  text-align: right;
  height: 40px;
  padding: 0 5px;
  background: #f0f6fd;
}
.analysis-page .section .list-table tr td:nth-child(-n+7) {
  text-align: center;
}
.analysis-page .section .list-table tr td:nth-child(1), .analysis-page .section .list-table tr td:nth-child(8) {
  background: transparent;
}
.analysis-page .section .txt {
  font-size: 12px;
  color: #2b3038;
  padding: 20px 0;
}
@media screen and (max-width: 1400px) {
  .analysis-page .section {
    width: 100%;
    overflow-y: auto;
 }
  .analysis-page .section .table-area {
    width: 1280px;
 }
  .analysis-page .section .list-table {
    table-layout: fixed;
    width: 1280px;
 }
}
@media screen and (max-width: 720px) {
  .analysis-page {
    padding: 3rem 0 6rem;
 }
  .analysis-page .section {
    width: 100%;
    margin-bottom: 4rem;
    overflow-y: auto;
 }
  .analysis-page .section .table-area {
    width: 300rem;
 }
  .analysis-page .section .title {
    float: left;
    font-size: 3.6rem;
    font-weight: 500;
    line-height: 9rem;
 }
  .analysis-page .section .title span {
    font-size: 2.8rem;
 }
  .analysis-page .section .ym {
    font-size: 2.4rem;
    line-height: 7rem;
    padding-top: 2rem;
 }
  .analysis-page .section .ym .select-title {
    font-size: 2.4rem;
    line-height: 4.5rem;
    width: 15rem;
    padding-left: 1rem;
 }
  .analysis-page .section .ym ul li {
    font-size: 2.4rem;
    line-height: 4.5rem;
    padding-left: 1rem;
 }
  .analysis-page .section .list-table {
    table-layout: fixed;
    width: 300rem;
 }
  .analysis-page .section .list-table th {
    font-size: 3rem;
    line-height: 3.6rem;
    height: 12rem;
 }
  .analysis-page .section .list-table td {
    font-size: 3rem;
    height: 12rem;
    padding: 0 2rem;
 }
  .analysis-page .section .txt {
    font-size: 2.4rem;
    padding: 4rem 0 2rem;
 }
}

/* 회원사 - 진흥회가입안내 */
.joinguide-page {
  padding-top: 60px;
}
.joinguide-page .mobile-only {
  display: none;
}
.joinguide-page .info {
  position: relative;
  height: 500px;
  background: url(/assets/images/joinguide01.png);
  margin-bottom: 120px;
}
.joinguide-page .info .txt {
  font-size: 30px;
  color: #fff;
  line-height: 40px;
  text-align: center;
  padding-top: 115px;
}
.joinguide-page .info .txt span {
  font-weight: bold;
}
.joinguide-page .info .bar {
  width: 30px;
  height: 2px;
  background: #fff;
  margin: 55px auto 0;
}
.joinguide-page .info .txt-area {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  left: calc(50% - 512px);
  bottom: -60px;
  width: 1024px;
  height: 250px;
  background: #fff;
  filter: drop-shadow(0px 10px 10px rgba(0, 0, 0, 0.11));
}
.joinguide-page .info .txt-area > div {
  font-size: 20px;
  color: #2b3038;
  font-weight: 300;
  line-height: 28px;
  text-align: left;
}
.joinguide-page .info .txt-area > div span {
  color: #0062b7;
  font-weight: 500;
}
.joinguide-page .join-btn {
  display: block;
  width: 235px;
  height: 60px;
  font-size: 22px;
  color: #fff;
  background-color: #174899;
  border: none;
  margin: 0 auto !important;
}
.joinguide-page .guide {
  margin-top: 60px !important;
}
.joinguide-page .guide li:after {
  content: "";
  display: block;
  clear: both;
}
.joinguide-page .guide .guide-title {
  float: left;
  width: 260px;
  border-top: 2px solid #bdcbdf;
  padding-top: 30px;
  margin-right: 50px;
  font-size: 28px;
  font-weight: 500;
  color: #0062b7;
}
.joinguide-page .guide .guide-txt {
  float: left;
  width: calc(100% - 310px);
  border-top: 1px solid #e9e9e9;
  padding: 30px 0 45px !important;
}
.joinguide-page .guide .guide-txt li {
  font-size: 20px;
  color: #2b3038;
  padding-bottom: 15px;
}
.joinguide-page .guide .guide-txt li:before {
  display: inline-block;
  content: '';
  width: 4px;
  height: 4px;
  background-color: #0062b7;
  border-radius: 50%;
  margin-right: 10px;
  vertical-align: middle;
}
.joinguide-page .guide .guide-txt li .txt1 {
  padding: 15px 0 0 15px;
}
.joinguide-page .guide .guide-process {
  float: left;
  width: calc(100% - 310px);
  border-top: 1px solid #e9e9e9;
  padding: 60px 0 25px !important;
}
.joinguide-page .guide .guide-process li {
  width: 500px;
  height: 165px;
  margin: 0 auto;
}
.joinguide-page .guide .guide-process li > div {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 500px;
  height: 90px;
  border-radius: 45px;
  font-size: 18px;
  font-weight: 500;
  color: #fff;
  line-height: 26px;
  text-align: center;
}
.joinguide-page .guide .guide-process li:nth-child(1) > div {
  color: #0176dc;
  background: #fff;
  border: 2px solid #638fe3;
}
.joinguide-page .guide .guide-process li:nth-child(2) > div {
  background: #6390e4;
}
.joinguide-page .guide .guide-process li:nth-child(3) > div {
  background: #0176dc;
}
.joinguide-page .guide .guide-process li:nth-child(4) > div {
  background: #174899;
}
.joinguide-page .guide .guide-process li:not(:nth-child(4)) {
  background: url(/assets/images/icon_down.png) no-repeat center bottom 29px;
}
@media screen and (max-width: 1280px) {
  .joinguide-page .pc-only {
    display: none;
 }
  .joinguide-page .info .txt-area {
    left: 5%;
    width: 90%;
    padding: 0 40px;
 }
  .joinguide-page .guide .guide-title {
    float: none;
    width: 100%;
    border-top: 2px solid #bdcbdf;
    padding: 30px 0 20px;
    margin-right: 0;
 }
  .joinguide-page .guide .guide-txt {
    float: none;
    width: 100%;
 }
  .joinguide-page .guide .guide-process {
    float: none;
    width: 100%;
 }
}
@media screen and (max-width: 720px) {
  .joinguide-page {
    padding: 6rem 0 8rem;
 }
  .joinguide-page .mobile-only {
    display: block;
 }
  .joinguide-page .info {
    height: 50rem;
    background: url(/assets/images/joinguide01m.png) 50% / contain;
    margin: 0 -4rem;
    margin-bottom: 39.5rem;
 }
  .joinguide-page .info .txt {
    font-size: 3rem;
    line-height: 4rem;
    padding-top: 11.5rem;
 }
  .joinguide-page .info .bar {
    width: 5.6rem;
    height: 0.4rem;
    background: #fff;
    margin: 5.5rem auto 0;
 }
  .joinguide-page .info .txt-area {
    left: 4rem;
    bottom: -33.5rem;
    width: 64rem;
    height: auto;
    padding: 6rem 3rem;
 }
  .joinguide-page .info .txt-area > div {
    font-size: 3rem;
    color: #2b3038;
    line-height: 4rem;
 }
  .joinguide-page .join-btn {
    width: 40rem;
    height: 10rem;
    font-size: 3.4rem;
 }
  .joinguide-page .guide {
    margin-top: 4rem !important;
 }
  .joinguide-page .guide .guide-title {
    float: none;
    width: 100%;
    border-top: none;
    border-bottom: 2px solid #bdcbdf;
    padding: 8rem 0 6rem;
    margin-right: 0;
    font-size: 4.8rem;
    text-align: center;
 }
  .joinguide-page .guide .guide-txt {
    float: none;
    width: 100%;
    border-top: none;
    border-bottom: 1px solid #e9e9e9;
    padding: 3.5rem 0 1rem !important;
 }
  .joinguide-page .guide .guide-txt li {
    font-size: 2.8rem;
    color: #2b3038;
    line-height: 3.8rem;
    padding-bottom: 2rem;
 }
  .joinguide-page .guide .guide-txt li:before {
    width: 0.5rem;
    height: 0.5rem;
    margin-right: 1.4rem;
 }
  .joinguide-page .guide .guide-txt li .txt1 {
    padding: 1.5rem 0 0 1.5rem;
 }
  .joinguide-page .guide .guide-process {
    float: none;
    width: 100%;
    border-bottom: 1px solid #e9e9e9;
    padding: 6rem 0 0 !important;
 }
  .joinguide-page .guide .guide-process li {
    width: 100%;
    height: 27rem;
    margin: 0 auto;
 }
  .joinguide-page .guide .guide-process li > div {
    width: 100%;
    height: 17rem;
    border-radius: 8.5rem;
    font-size: 3rem;
    line-height: 3.6rem;
 }
  .joinguide-page .guide .guide-process li:nth-child(1) > div {
    font-size: 2.8rem;
    border: 2px solid #638fe3;
 }
  .joinguide-page .guide .guide-process li:nth-child(2) > div {
    background: #6390e4;
 }
  .joinguide-page .guide .guide-process li:nth-child(3) > div {
    background: #0176dc;
 }
  .joinguide-page .guide .guide-process li:nth-child(4) > div {
    background: #174899;
 }
  .joinguide-page .guide .guide-process li:not(:nth-child(4)) {
    background: url(/assets/images/icon_down.png) no-repeat center bottom 3.7rem / 6.6rem auto;
 }
  .joinguide-page .guide .guide-process li:nth-child(4) {
    height: 21rem;
 }
}

/* 진흥회소개 - 인사말 */ 
.greetings-page {
  padding-top: 100px;
}
.greetings-page .mobile-only {
  display: none;
}
.greetings-page .txt-logo {
  width: 270px;
  height: 47px;
  background: url(/assets/images/greetings_txt.png);
  margin: 0 auto 30px;
}
.greetings-page .title {
  font-size: 36px;
  font-weight: 500;
  color: #0062b7;
  letter-spacing: -2px;
  line-height: 43px;
  text-align: center;
  margin-bottom: 50px;
}
.greetings-page .vbar {
  display: block;
  width: 3px;
  height: 28px;
  background-color: #bfc9e2;
  margin: 0 auto;
}
.greetings-page .greetings .pic {
  float: left;
  width: 471px;
  height: 471px;
  margin-right: 88px;
}
.greetings-page .greetings .pic img {
  width: 100%;
  height: 100%;
}
.greetings-page .greetings .txt {
  float: left;
  width: calc(100% - 559px);
  padding: 50px 0 100px;
}
.greetings-page .greetings .txt p {
  font-size: 18px;
  color: #2b3038;
  line-height: 26px;
  margin-bottom: 22px;
}
.greetings-page .greetings .txt p span {
  font-weight: 500;
}
.greetings-page .greetings .txt p.thanks {
  padding: 12px 0 70px;
}
.greetings-page .greetings .txt .ceo {
  font-size: 16px;
  font-weight: 500;
  color: #2b3038;
  text-align: right;
  margin-bottom: 15px;
}
.greetings-page .greetings .txt .sign {
  text-align: right;
}
.greetings-page .greetings .txt .sign img {
  width: 78px;
  height: 32px;
}
@media screen and (max-width: 1200px) {
  .greetings-page .mobile-only {
    display: block;
 }
  .greetings-page .greetings .pic {
    float: none;
    margin: 30px auto;
 }
  .greetings-page .greetings .txt {
    float: none;
    width: 100%;
 }
}
@media screen and (max-width: 720px) {
  .greetings-page {
    padding-top: 10rem;
 }
  .greetings-page .txt-logo {
    width: 27rem;
    height: 4.7rem;
    background: url(/assets/images/greetings_txt.png);
    background-size: contain;
    margin: 0 auto 6rem;
 }
  .greetings-page .title {
    font-size: 4.8rem;
    line-height: 5.6rem;
    margin-bottom: 7.6rem;
 }
  .greetings-page .vbar {
    width: 0.6rem;
    height: 5.6rem;
 }
  .greetings-page .greetings .pic {
    float: none;
    width: 47.1rem;
    height: 47.1rem;
    margin: 8rem auto 7.5rem;
 }
  .greetings-page .greetings .txt {
    float: none;
    width: 100%;
    padding: 0 0 10rem;
 }
  .greetings-page .greetings .txt p {
    font-size: 3rem;
    color: #2b3038;
    line-height: 4rem;
    margin-bottom: 4.5rem;
 }
  .greetings-page .greetings .txt p.thanks {
    padding: 0 0 9rem;
    margin: 0;
 }
  .greetings-page .greetings .txt .ceo {
    font-size: 2.4rem;
    margin-bottom: 3rem;
 }
  .greetings-page .greetings .txt .sign {
    text-align: right;
 }
  .greetings-page .greetings .txt .sign img {
    width: 13.5rem;
    height: 5.3rem;
 }
}

/* 진흥회소개 - 진흥회개요 - 설립목적 및 주요사업 */
.objective-page {
  padding-bottom: 130px;
}
.objective-page .mobile-only {
  display: none;
}
.objective-page .objective {
  position: relative;
  height: 500px;
  background: url(/assets/images/objective_bg.png);
}
.objective-page .objective .txt-area {
  position: absolute;
  left: calc(50% - 512px);
  bottom: -50px;
  width: 1024px;
  height: 210px;
  background: #fff;
  filter: drop-shadow(0px 10px 10px rgba(0, 0, 0, 0.11));
  padding: 48px 48px 48px 20px;
}
.objective-page .objective .txt-area .img {
  float: left;
  width: 230px;
  height: 100%;
  background: url(/assets/images/keaa_logo.png) no-repeat 50%;
  border-right: 1px solid #e9e9e9;
}
.objective-page .objective .txt-area .txt {
  float: left;
  width: calc(100% - 230px);
  font-size: 20px;
  color: #2b3038;
  font-weight: 500;
  line-height: 30px;
  padding: 12px 0 0 50px;
}
.objective-page .objective .txt-area .txt span {
  color: #0062b7;
}
.business-page {
  padding-bottom: 80px;
}
.business-page .page-title1 {
  float: left;
  width: 260px;
  border-top: 2px solid #bdcbdf;
  padding-top: 50px;
  margin-right: 50px;
  font-size: 36px;
  font-weight: 500;
  color: #0062b7;
}
.business-page .page-title-bar {
  display: none;
}
.business-page .business-list {
  float: left;
  width: calc(100% - 310px);
  border-top: 2px solid #e9e9e9;
  padding-top: 50px !important;
}
.business-page .business-list li {
  height: 100px;
  background: #fff;
  border: 1px solid #d7d7d7;
  margin-bottom: 20px;
}
.business-page .business-list li span:nth-child(2) {
  display: flex;
  align-items: center;
  font-size: 22px;
  color: #2b3038;
  line-height: 30px;
  height: 100%;
  padding-right: 15px;
}
.business-page .business-list li span:nth-child(1) {
  float: left;
  display: block;
  width: 160px;
  height: 100%;
}
.business-page .business-list li:nth-child(1) > span:nth-child(1) {
  background: url(/assets/images/icon_business01.png) no-repeat 50%;
}
.business-page .business-list li:nth-child(2) > span:nth-child(1) {
  background: url(/assets/images/icon_business02.png) no-repeat 50%;
}
.business-page .business-list li:nth-child(3) > span:nth-child(1) {
  background: url(/assets/images/icon_business03.png) no-repeat 50%;
}
.business-page .business-list li:nth-child(4) > span:nth-child(1) {
  background: url(/assets/images/icon_business04.png) no-repeat 50%;
}
.business-page .business-list li:nth-child(5) > span:nth-child(1) {
  background: url(/assets/images/icon_business05.png) no-repeat 50%;
}
.business-page .business-list li:nth-child(6) > span:nth-child(1) {
  background: url(/assets/images/icon_business06.png) no-repeat 50%;
}
.business-page .business-list li:nth-child(7) > span:nth-child(1) {
  background: url(/assets/images/icon_business07.png) no-repeat 50%;
}
.business-page .business-list li:nth-child(8) > span:nth-child(1) {
  background: url(/assets/images/icon_business08.png) no-repeat 50%;
}
.business-page .business-list li:nth-child(9) > span:nth-child(1) {
  background: url(/assets/images/icon_business09.png) no-repeat 50%;
}
@media screen and (max-width: 1300px) {
  .objective-page .objective .txt-area {
    left: 40px;
    width: calc(100% - 80px);
    height: auto;
    padding: 20px 48px 48px;
 }
  .objective-page .objective .txt-area .img {
    float: none;
    width: 100%;
    height: 160px;
    border-right: none;
    border-bottom: 1px solid #e9e9e9;
 }
  .objective-page .objective .txt-area .txt {
    float: none;
    width: 100%;
    text-align: center;
    padding: 30px 0 0;
 }
  .business-page .page-title1 {
    float: none;
 }
  .business-page .business-list {
    float: none;
    width: 100%;
    border-top: none;
 }
}
@media screen and (max-width: 720px) {
  .objective-page {
    padding-bottom: 13rem;
 }
  .objective-page .mobile-only {
    display: block;
 }
  .objective-page .objective {
    height: 50rem;
    background: url(/assets/images/objective_bgm.png) 0 0 / contain;
    margin: 0 -4rem;
 }
  .objective-page .objective .txt-area {
    left: calc(50% - 32rem);
    bottom: -40rem;
    width: 64rem;
    height: 57rem;
    padding: 0 4rem;
 }
  .objective-page .objective .txt-area .img {
    float: none;
    width: 100%;
    height: 24rem;
    background: url(/assets/images/keaa_logo.png) no-repeat 50% 6rem / 13rem auto;
    border-right: none;
    border-bottom: 1px solid #e9e9e9;
 }
  .objective-page .objective .txt-area .txt {
    float: none;
    width: 100%;
    font-size: 3rem;
    line-height: 4rem;
    text-align: center;
    padding: 3.5rem 0 0;
 }
  .business-page {
    padding: 27rem 0 10rem;
 }
  .business-page .page-title1 {
    float: none;
    width: 100%;
    border-top: none;
    padding-top: 0;
    margin: 10rem 0 6rem;
    font-size: 4.8rem;
    letter-spacing: -2px;
    line-height: 5.6rem;
    text-align: center;
 }
  .business-page .page-title-bar {
    display: block;
 }
  .business-page .business-list {
    float: none;
    width: 100%;
    border-top: 1px solid #d7d7d7;
    padding-top: 0 !important;
 }
  .business-page .business-list li {
    height: 15rem;
    border: none;
    border-bottom: 1px solid #d7d7d7;
    margin-bottom: 0;
 }
  .business-page .business-list li span:nth-child(2) {
    font-size: 2.8rem;
    line-height: 4rem;
    padding-right: 0;
 }
  .business-page .business-list li span:nth-child(1) {
    width: 15rem;
 }
  .business-page .business-list li:nth-child(1) > span:nth-child(1) {
    background: url(/assets/images/icon_business01.png) no-repeat 50% / 7rem auto;
 }
  .business-page .business-list li:nth-child(2) > span:nth-child(1) {
    background: url(/assets/images/icon_business02.png) no-repeat 50% / 6rem auto;
 }
  .business-page .business-list li:nth-child(3) > span:nth-child(1) {
    background: url(/assets/images/icon_business03.png) no-repeat 50% / 5.8rem auto;
 }
  .business-page .business-list li:nth-child(4) > span:nth-child(1) {
    background: url(/assets/images/icon_business04.png) no-repeat 50% / 6.2rem auto;
 }
  .business-page .business-list li:nth-child(5) > span:nth-child(1) {
    background: url(/assets/images/icon_business05.png) no-repeat 50% / 5.4rem auto;
 }
  .business-page .business-list li:nth-child(6) > span:nth-child(1) {
    background: url(/assets/images/icon_business06.png) no-repeat 50% / 6.4rem auto;
 }
  .business-page .business-list li:nth-child(7) > span:nth-child(1) {
    background: url(/assets/images/icon_business07.png) no-repeat 50% / 6.2rem auto;
 }
  .business-page .business-list li:nth-child(8) > span:nth-child(1) {
    background: url(/assets/images/icon_business08.png) no-repeat 50% / 6.6rem auto;
 }
  .business-page .business-list li:nth-child(9) > span:nth-child(1) {
    background: url(/assets/images/icon_business09.png) no-repeat 50% / 6.2rem auto;
 }
}

/* 진흥회소개 - 진흥회개요 - 연혁 */
.history {
  padding-bottom: 100px;
}
.history .guide-txt {
  font-size: 18px;
  color: #666;
  line-height: 26px;
  text-align: center;
}
.history .term li {
  float: left;
  width: calc((100% - 90px) / 4);
  height: 50px;
  margin: 76px 30px 50px 0;
  border-radius: 25px;
  background-color: #f8f9f9;
  border: 1px solid #e9e9e9;
}
.history .term li a {
  display: block;
  height: 100%;
  font-size: 22px;
  font-weight: 400;
  color: #888;
  line-height: 48px;
  text-align: center;
}
.history .term li:nth-last-child(1) {
  margin-right: 0px;
}
.history .term li:hover {
  background-color: #0176dc;
  border: 1px solid #0176dc;
}
.history .term li:hover a {
  color: #fff;
  font-weight: bold;
  text-decoration: none;
}
.history .history-list > li {
  padding-bottom: 33px;
}
.history .history-list > li:after {
  content: "";
  display: block;
  clear: both;
}
.history .history-list > li .year {
  float: left;
  max-width: 297px;
  width: 25%;
  border-top: 1px solid #bdcbdf;
  padding-top: 17px;
  font-family: "Roboto";
  font-size: 32px;
  font-weight: 500;
  color: #0062b7;
  letter-spacing: -2px;
  line-height: 28px;
}
.history .history-list > li .list {
  float: left;
  min-width: calc(100% - 297px);
  width: 75%;
  padding-top: 16px !important;
  border-top: 1px solid #e9e9e9;
}
.history .history-list > li .list li {
  padding: 0 0 13px 30px;
}
.history .history-list > li .list li .mon {
  float: left;
  width: 33px;
  font-family: "Roboto";
  font-size: 22px;
  font-weight: 400;
  color: #0a1f55;
  padding-top: 4px;
}
.history .history-list > li .list li .txt > p {
  padding-left: 33px;
  margin-bottom: 0 !important;
}
.history .history-list > li .list li .txt > p:after {
  content: "";
  display: block;
  clear: both;
}
.history .history-list > li .list li .txt > p span:nth-child(1) {
  float: left;
}
.history .history-list > li .list li .txt > p span:nth-child(1):before {
  float: left;
  content: '';
  width: 4px;
  height: 4px;
  background-color: #999;
  border-radius: 50%;
  margin: 11px 10px 0 0;
}
.history .history-list > li .list li .txt > p span:nth-child(2) {
  float: left;
  width: calc(100% - 14px);
  font-size: 18px;
  color: #666;
  line-height: 26px;
}
@media screen and (max-width: 720px) {
  .history {
    padding-bottom: 6.7rem;
 }
  .history .guide-txt {
    font-size: 3rem;
    line-height: 4rem;
 }
  .history .term {
    display: none;
 }
  .history .history-list {
    margin-top: 7.5rem !important;
 }
  .history .history-list > li {
    padding-bottom: 3.3rem;
 }
  .history .history-list > li .year {
    width: 15rem;
    border-top: 1px solid #bdcbdf;
    padding-top: 3.2rem;
    font-family: "Roboto";
    font-size: 3.2rem;
    line-height: 4rem;
 }
  .history .history-list > li .list {
    float: left;
    width: calc(100% - 15rem);
    padding-top: 3.1rem !important;
    border-top: 1px solid #e9e9e9;
 }
  .history .history-list > li .list li {
    padding: 0 0 0.3rem 0;
 }
  .history .history-list > li .list li .mon {
    width: 4.5rem;
    font-size: 3.2rem;
    font-weight: 500;
    color: #2b3038;
    line-height: 4rem;
    padding-top: 0;
 }
  .history .history-list > li .list li .txt > p {
    padding-left: 4.5rem;
 }
  .history .history-list > li .list li .txt > p span:nth-child(1):before {
    width: 0.6rem;
    height: 0.6rem;
    margin: 1.8rem 1rem 0 0;
 }
  .history .history-list > li .list li .txt > p span:nth-child(2) {
    width: calc(100% - 2rem);
    font-size: 2.8rem;
    line-height: 4rem;
 }
}

/* 진흥회소개 - 진흥회개요 - 조직도 */
.organization {
  padding-bottom: 100px;
}
.organization .mobile-only {
  display: none;
}
.organization .img {
  text-align: center;
}
.organization .img img{
  width: 100%;
  max-width: 1005px;
}
@media screen and (max-width: 720px) {
  .organization {
    padding-bottom: 7rem;
 }
  .organization .img {
    display: none;
 }
  .organization .mobile-only {
    display: block;
    padding-top: 4rem;
 }
  .organization .mobile-only .dept {
    width: 63rem;
    height: 11rem;
    border-radius: 5.5rem;
    font-size: 3.6rem;
    letter-spacing: -2px;
    line-height: 11rem;
    font-weight: 500;
    text-align: center;
    margin: 0 0 6rem;
 }
  .organization .mobile-only .dept.dept1 {
    padding: 0.5rem;
    border: 0.2rem solid #d7d7d7;
 }
  .organization .mobile-only .dept.dept1 > div {
    width: 100%;
    height: 100%;
    border-radius: 5rem;
    background: #174899;
    color: #fff;
    line-height: 9.5rem;
 }
  .organization .mobile-only .dept.dept2 {
    color: #fff;
    background: #0176dc;
 }
  .organization .mobile-only .dept.dept3 {
    color: #6390e4;
    background: #fff;
    border: 1px solid #638fe3;
 }
  .organization .mobile-only .dept.dept4 {
    color: #fff;
    background: #6390e4;
 }
  .organization .mobile-only .dept.dept4-1 {
    color: #fff;
    line-height: 3.6rem;
    background: #6390e4;
 }
  .organization .mobile-only .dept.dept4-1 p {
    font-size: 3rem;
    padding-top: 1.5rem;
 }
  .organization .mobile-only .team {
    border: 1px solid #d7d7d7;
    border-radius: 3rem;
    margin: -3rem 0 6rem;
    overflow: hidden;
 }
  .organization .mobile-only .team > div:nth-child(1) {
    font-size: 3.2rem;
    color: #666;
    letter-spacing: -2px;
    line-height: 6.5rem;
    text-align: center;
    background: #f8f9f9;
    padding: 2.4rem 0;
 }
}

/* 진흥회소개 - 정관 */
.articles-page {
  padding-top: 60px;
}
.articles-page .edit-date {
  font-size: 12px;
  font-weight: 300;
  color: #2b3038;
  line-height: 14px;
  text-align: right;
  padding-right: 15px;
}
.articles-page .articles {
  border-top: 2px solid #bdcbdf;
  margin-top: 30px;
}
.articles-page .articles .chapter {
  border-bottom: 1px solid #e9e9e9;
  padding-bottom: 30px;
}
.articles-page .articles .chapter .h1 {
  font-size: 20px;
  font-weight: 500;
  color: #0062b7;
  text-align: center;
  padding: 30px 0;
  border-bottom: 1px solid #e9e9e9;
}
.articles-page .articles .chapter .chapter-txt {
  padding-left: 10px;
}
.articles-page .articles .chapter .chapter-txt .h2 {
  font-size: 16px;
  font-weight: 500;
  color: #2b3038;
  padding: 30px 0 10px;
}
.articles-page .articles .chapter .chapter-txt .txt {
  font-size: 14px;
  line-height: 18px;
  color: #666;
}
.articles-page .articles .chapter .chapter-txt .txt .txt1 {
  padding-top: 20px;
}
.articles-page .articles .chapter .chapter-txt .txt1 {
  font-size: 14px;
  color: #666;
  line-height: 22px;
  padding-left: 10px;
}
@media screen and (max-width: 720px) {
  .articles-page {
    padding: 3.5rem 0 10rem;
 }
  .articles-page .edit-date {
    font-size: 2rem;
    line-height: 3rem;
    padding-right: 0;
 }
  .articles-page .articles {
    margin-top: 5.5rem;
 }
  .articles-page .articles .chapter {
    padding-bottom: 4rem;
 }
  .articles-page .articles .chapter.pb70 {
    padding-bottom: 0 !important;
    border-bottom: none;
 }
  .articles-page .articles .chapter .h1 {
    font-size: 3.6rem;
    padding: 4rem 0;
 }
  .articles-page .articles .chapter .chapter-txt {
    padding-left: 0;
 }
  .articles-page .articles .chapter .chapter-txt .h2 {
    font-size: 3rem;
    padding: 4rem 0 1.8rem;
 }
  .articles-page .articles .chapter .chapter-txt .txt {
    font-size: 2.8rem;
    line-height: 4rem;
 }
  .articles-page .articles .chapter .chapter-txt .txt .txt1 {
    padding-top: 1.2rem;
 }
  .articles-page .articles .chapter .chapter-txt .txt.pb70 {
    padding-bottom: 0 !important;
 }
  .articles-page .articles .chapter .chapter-txt .txt1 {
    font-size: 2.8rem;
    line-height: 4rem;
    padding-left: 0;
 }
}

/* 진흥회소개 - 찾아오시는 길 - 버스 */
.map-bus {
  padding: 60px 0 40px;
}
.map-bus .bus-list > li {
  float: left;
  width: calc((100% - 80px) / 3);
  padding-bottom: 20px;
  margin-bottom: 60px;
  border-top: 2px solid #bdcbdf;
  border-bottom: 1px solid #e9e9e9;
}
.map-bus .bus-list > li .title {
  font-size: 22px;
  font-weight: 500;
  color: #0062b7;
  line-height: 60px;
  padding-left: 20px;
  border-bottom: 1px solid #e9e9e9;
}
.map-bus .bus-list > li .bus-stop {
  font-size: 18px;
  color: #2b3038;
  padding: 20px 0 20px 20px;
}
.map-bus .bus-list > li .busno {
  padding-left: 10px !important;
}
.map-bus .bus-list > li .busno li {
  float: left;
  width: 100px;
  height: 40px;
  border-radius: 20px;
  background-color: #f8f9f9;
  border: 1px solid #d7d7d7;
  margin: 0 10px;
  font-size: 18px;
  color: #2b3038;
  line-height: 38px;
  text-align: center;
}
.map-bus .bus-list > li:nth-child(3n+2) {
  margin: 0 40px;
}
@media screen and (max-width: 1350px) {
  .map-bus .bus-list > li {
    float: left;
    width: calc((100% - 40px) / 2);
 }
  .map-bus .bus-list > li:nth-child(3n+2) {
    margin: 0 0 60px;
 }
  .map-bus .bus-list > li:nth-child(2n) {
    margin-left: 40px;
 }
}
@media screen and (max-width: 900px) {
  .map-bus .bus-list > li {
    float: none;
    width: 100%;
 }
  .map-bus .bus-list > li:nth-child(2n) {
    margin-left: 0;
 }
}
@media screen and (max-width: 720px) {
  .map-bus {
    padding: 10rem 4rem;
 }
  .map-bus .bus-list > li {
    float: none;
    width: 100%;
    padding-bottom: 4rem;
    margin-bottom: 6rem;
    border-top: 2px solid #bdcbdf;
    border-bottom: 1px solid #e9e9e9;
 }
  .map-bus .bus-list > li .title {
    font-size: 3.4rem;
    line-height: 11rem;
    padding-left: 2rem;
 }
  .map-bus .bus-list > li .bus-stop {
    font-size: 3rem;
    padding: 4rem 0 3rem 2rem;
 }
  .map-bus .bus-list > li .busno {
    padding-left: 1rem !important;
 }
  .map-bus .bus-list > li .busno li {
    float: left;
    width: 18rem;
    height: 7rem;
    border-radius: 3.5rem;
    margin: 0 1rem;
    font-size: 3rem;
    line-height: 7rem;
 }
  .map-bus .bus-list > li:nth-child(3n+2) {
    margin: 0;
 }
  .map-bus .bus-list > li:nth-child(2n) {
    margin-left: 0;
 }
}

/* 진흥회소개 - 찾아오시는 길 - 지하철이용 */
.map-subway {
  padding: 60px 0 40px;
}
.map-subway .subway .line {
  float: left;
  width: 260px;
  border-top: 2px solid #bdcbdf;
  padding-top: 20px;
  margin-right: 50px;
  font-size: 22px;
  font-weight: 500;
  color: #0062b7;
  line-height: 30px;
}
.map-subway .subway .line span {
  display: inline-block;
  width: 80px;
  height: 30px;
  border-radius: 15px;
  background-color: #0099e5;
  border: 1px solid #d7d7d7;
  margin-right: 10px;
  font-size: 18px;
  font-weight: bold;
  color: #fff;
  line-height: 30px;
  text-align: center;
}
.map-subway .subway .map-area {
  float: left;
  width: calc(100% - 310px);
  border-top: 2px solid #e9e9e9;
  padding-top: 26px;
}
.map-subway .subway .map-area .txt > div {
  font-size: 18px;
  color: #2b3038;
  line-height: 26px;
}
.map-subway .subway .map-area .txt > div span {
  color: #0062b7;
  font-weight: 500;
  margin-bottom: 10px;
}
.map-subway .subway .map-area .map {
  height: 360px;
  border: 1px solid #e9e9e9;
  margin: 0px 0 60px;
}
.map-div {
  margin-bottom: 20px;
}
@media screen and (max-width: 1000px) {
  .map-subway .subway .line {
    float: none;
    width: 100%;
    padding: 20px 0;
    margin-right: 0;
 }
  .map-subway .subway .map-area {
    float: none;
    width: 100%;
 }
}
@media screen and (max-width: 720px) {
  .map-subway {
    padding: 10rem 4rem 4rem;
 }
  .map-subway .subway .line {
    float: none;
    width: 100%;
    padding: 2rem;
    margin-right: 0;
    font-size: 3.4rem;
    line-height: 7rem;
 }
  .map-subway .subway .line span {
    width: 15rem;
    height: 7rem;
    border-radius: 3.5rem;
    margin-right: 2rem;
    font-size: 3rem;
    font-weight: 500;
    line-height: 7rem;
 }
  .map-subway .subway .map-area {
    float: none;
    width: 100%;
    border-top: 2px solid #e9e9e9;
    border-bottom: 1px solid #e9e9e9;
    padding-top: 3.5rem;
    margin-bottom: 6rem;
 }
  .map-subway .subway .map-area .txt > div {
    font-size: 3rem;
    line-height: 4rem;
    padding-left: 2rem;
 }
  .map-subway .subway .map-area .txt > div:nth-last-child(1) {
    padding-top: 2rem;
 }
  .map-subway .subway .map-area .map {
    height: 40rem;
    margin: 3.5rem 0 4rem;
 }
}

/* 진흥회소개 - 찾아오시는 길 - 자가용이용 */
.map-car {
  padding: 60px 0 40px;
}
.map-car .car .mobile-only {
  display: none;
}
.map-car .car .line {
  float: left;
  width: 260px;
  border-top: 2px solid #bdcbdf;
  padding-top: 20px;
  margin-right: 50px;
  font-size: 22px;
  font-weight: 500;
  color: #0062b7;
  line-height: 30px;
}
.map-car .car .map-area {
  float: left;
  width: calc(100% - 310px);
  border-top: 2px solid #e9e9e9;
  padding-top: 24px;
}
.map-car .car .map-area .addr > div .th {
  float: left;
  font-size: 18px;
  color: #0062b7;
  font-weight: 500;
  line-height: 30px;
  padding-right: 20px;
}
.map-car .car .map-area .addr > div .th:before {
  display: inline-block;
  content: '';
  width: 4px;
  height: 4px;
  background-color: #0062b7;
  margin-right: 10px;
  vertical-align: middle;
}
.map-car .car .map-area .addr > div span:nth-last-child(1) {
  float: left;
  width: calc(100% - 100px);
  font-size: 18px;
  color: #2b3038;
  line-height: 30px;
}
.map-car .car .map-area .map {
  height: 360px;
  border: 1px solid #e9e9e9;
  margin: 0px 0 60px;
}
@media screen and (max-width: 1000px) {
  .map-car .car .line {
    float: none;
    width: 100%;
    padding: 20px 0;
    margin-right: 0;
 }
  .map-car .car .map-area {
    float: none;
    width: 100%;
 }
}
@media screen and (max-width: 720px) {
  .map-car {
    padding: 10rem 4rem 4rem;
 }
  .map-car .car .mobile-only {
    display: block;
 }
  .map-car .car .line {
    float: none;
    width: 100%;
    padding: 2rem;
    margin-right: 0;
    font-size: 3.4rem;
    line-height: 7rem;
 }
  .map-car .car .map-area {
    float: none;
    width: 100%;
    border-top: 2px solid #e9e9e9;
    border-bottom: 1px solid #e9e9e9;
    padding-top: 3.5rem;
    margin-bottom: 6rem;
 }
  .map-car .car .map-area .addr > div {
    padding-left: 2rem;
 }
  .map-car .car .map-area .addr > div .th {
    font-size: 3rem;
    line-height: 4rem;
    padding-right: 2rem;
 }
  .map-car .car .map-area .addr > div .th:before {
    display: none;
 }
  .map-car .car .map-area .addr > div span:nth-last-child(1) {
    width: calc(100% - 100px);
    font-size: 3rem;
    line-height: 4rem;
    padding-bottom: 2rem;
 }
  .map-car .car .map-area .map {
    height: 40rem;
    margin: 2rem 0 4rem;
 }
}

/* 개인정보처리방침 */
.privacy {
  font-size: 13px;
  color: #222;
  letter-spacing: -1px;
  line-height: 22px;
  padding: 50px 0;
}
@media screen and (max-width: 720px) {
  .privacy {
    font-size: 2.8rem;
    line-height: 4rem;
    padding: 7rem 4rem 4rem;
  }
}

/* 이메일무단수집거부 */
.email {
  padding: 10px 0 50px;
}
.email .txt1 {
  font-size: 26px;
  font-weight: 700;
  color: #3d569d;
  letter-spacing: -1px;
  line-height: 41px;
  text-align: center;
  margin: 25px 0px 40px;
}
.email .txt-area {
  padding: 50px;
  border: 1px solid #e1e2e1;
  font-family: 'Noto Sans KR', gulim;
  font-size: 14px;
  color: #222;
  letter-spacing: -1px;
  line-height: 24px;
}
.email .txt-area .txt2 {
  font-size: 20px;
  font-weight: bold;
  line-height: 22px;
}
.email .txt-area .txt3 {
  font-weight: bold;
  margin: 35px 0px 5px;
}

@media screen and (max-width: 1200px) {
  .email .pc-only {
    display: none;
 }
  .email .txt1 {
    text-align: left;
 }
}
@media screen and (max-width: 720px) {
  .email {
    padding: 4rem 4rem 10rem;
  }
  .email .txt1 {
    font-size: 2.8rem;
    line-height: 4rem;
 }
  .email .txt-area {
    padding: 20px !important;
 }
}

/* 부설에너지기기시험원 - 사업안내 -  단체표준인증 */
.eatl-business7 {
  padding-bottom: 60px;
}
.eatl-business7 .mobile-only {
  display: none;
}
.eatl-business7 .guide-txt {
  font-size: 18px;
  color: #2b3038;
  line-height: 26px;
  text-align: center;
  margin-bottom: 42px;
}
.eatl-business7 .section {
  margin-bottom: 35px;
}
.eatl-business7 .section .title {
  font-size: 22px;
  font-weight: bold;
  color: #2b3038;
  line-height: 68px;
  border-bottom: 2px solid #2b3038;
}
.eatl-business7 .section .title span {
  font-weight: 400;
}
.eatl-business7 .section .title .down-btn {
  float: right;
  font-size: 14px;
  font-weight: 500;
  color: #2b3038;
  line-height: 34px;
  text-align: center;
  width: 118px;
  height: 36px;
  border: 1px solid #d7d7d7;
  border-radius: 5px;
  margin: 20px 0 0 5px;
}
.eatl-business7 .section ul {
  padding: 12px 0 12px !important;
  border-bottom: 2px solid #e9e9e9;
}
.eatl-business7 .section ul.purpose {
  padding: 12px 0 !important;
  border-bottom: 2px solid #e9e9e9;
}
.eatl-business7 .section ul li:after {
  content: "";
  display: block;
  clear: both;
}
.eatl-business7 .section ul li span:nth-child(1) {
  float: left;
}
.eatl-business7 .section ul li span:nth-child(1):before {
  float: left;
  content: '';
  width: 3px;
  height: 3px;
  background-color: #999;
  border-radius: 50%;
  margin: 15px 10px 0 0;
}
.eatl-business7 .section ul li span:nth-child(2) {
  float: left;
  width: calc(100% - 13px);
  font-size: 16px;
  color: #2b3038;
  line-height: 32px;
}

.eatl-business7 .mark-ci {
  text-align: center;
  padding: 30px 0;
}
.eatl-business7 .mark-ci .mark1 {
  float: left;
  width: 50%;
  text-align: left;
  padding: 0 0 0 20px;
}  
.eatl-business7 .mark-ci .mark1 .mark-nm {
  font-size: 16px;
  padding: 0 0 20px;
}  
.eatl-business7 .mark-ci .mark1 img {
  width: auto;
  max-width: 95%;
  height: 60px;
}
.eatl-business7 .mark-table {
  margin-top: 10px !important;
}  

.eatl-business7 .section .organization {
  padding: 70px 0 50px;
}
.eatl-business7 .section .organization img {
  display: block;
  max-width: 949px;
  width: 90%;
  margin: 0 auto;
}
.eatl-business7 .section .policy {
  max-width: 720px;
  width: 100%;
  padding: 30px 50px 30px;
  border: 1px solid #d7d7d7;
  margin: 50px auto;
}
.eatl-business7 .section .policy .policy-title {
  font-size: 25px;
  font-weight: bold;
  color: #2b3038;
  line-height: 80px;
  text-align: center;
}
.eatl-business7 .section .policy .contents {
  font-size: 15px;
  color: #2b3038;
  line-height: 2;
}  
.eatl-business7 .section .policy .contents .p1 {
  float: left;
  width: 20px;
  line-height: 2;
  margin: 0 !important;
}
.eatl-business7 .section .policy .contents .p2 {
  float: left;
  width: calc(100% - 20px);
  line-height: 2;
  margin: 0 !important;
}
.eatl-business7 .section .policy .date {
  font-size: 15px;
  color: #2b3038;
  line-height: 4;
  text-align: right;
}  
.eatl-business7 .section .policy .sign {
  font-size: 17px;
  color: #2b3038;
  line-height: 65px;
  text-align: right;
  background: url(/assets/images/sign2.png) no-repeat 100% 0% / 100px;
  padding-right: 50px;
}  

.eatl-business7 .section .list-table {
  border-top: 1px solid #d8d8d8 !important;
  margin-top: 0;
}

@media screen and (max-width: 720px) {
  .eatl-business7 {
    padding-bottom: 5rem;
 }
  .eatl-business7 .mobile-only {
    display: block;
 }
  .eatl-business7 .guide-txt {
    font-size: 3rem;
    line-height: 4rem;
    margin-bottom: 4.5rem;
 }
  .eatl-business7 .section {
    margin-bottom: 5rem;
 }
  .eatl-business7 .section .title {
    font-size: 3.6rem;
    font-weight: 500;
    line-height: 9.6rem;
    letter-spacing: -2px;
    border-bottom: 0.4rem solid #2b3038;
 }
 .eatl-business7 .section .title .down-btn {
  font-size: 2rem;
  line-height: 4.5rem;
  width: 15rem;
  height: 4.5rem;
  margin: 2.5rem 0 0 1rem;
}
  .eatl-business7 .section ul {
    padding: 2.6rem 0 !important;
 }
 .eatl-business7 .section ul.purpose {
  padding: 2.6rem 0 !important;
  border-bottom: 2px solid #e9e9e9;
  background: none;
}
  .eatl-business7 .section ul li span:nth-child(1):before {
    width: 0.5rem;
    height: 0.5rem;
    margin: 2.7rem 1.5rem 0 0;
 }
  .eatl-business7 .section ul li span:nth-child(2) {
    width: calc(100% - 2rem);
    font-size: 2.8rem;
    line-height: 5.6rem;
 }
 .eatl-business7 .mark-ci {
  float: none;
  width: 100%;
}

.eatl-business7 .mark-ci {
  text-align: center;
  padding: 3rem 0 0;
}
.eatl-business7 .mark-ci .mark1 {
  float: none;
  width: 100%;
  text-align: left;
  padding: 0 0 5rem 5rem;
}  
.eatl-business7 .mark-ci .mark1 .mark-nm {
  font-size: 2.5rem;
  padding: 0 0 2rem;
  text-align: left;
}  
.eatl-business7 .mark-ci .mark1 img {
  width: auto;
  height: 6rem;
}


.eatl-business7 .mark-table {
  float: none;
  width: 100%;
  margin-top: 10px !important;
}  

 /* .eatl-business7 .section .mark-ci {
  font-size: 2.5rem;
  padding: 3rem 0;
}
.eatl-business7 .section .mark-ci img {
  width: 35rem;
  height: 35rem;
} */
.eatl-business7 .section .organization {
  padding: 5rem 0 3rem;
}
.eatl-business7 .section .organization img {
  display: block;
  width: 100%;
  margin: 0 auto;
}
.eatl-business7 .section .policy {
  padding: 3rem 5rem 3rem;
  margin: 5rem auto;
}
.eatl-business7 .section .policy .policy-title {
  font-size: 3rem;
  line-height: 10rem;
}
.eatl-business7 .section .policy .contents {
  font-size: 2rem;
  line-height: 2;
}  
.eatl-business7 .section .policy .contents .p1 {
  float: left;
  width: 2rem;
  line-height: 2;
  margin: 0 !important;
}
.eatl-business7 .section .policy .contents .p2 {
  float: left;
  width: calc(100% - 2rem);
  line-height: 2;
  margin: 0 !important;
}
.eatl-business7 .section .policy .date {
  font-size: 2rem;
  color: #2b3038;
  line-height: 4;
  text-align: right;
}  
.eatl-business7 .section .policy .sign {
  font-size: 2.5rem;
  color: #2b3038;
  line-height: 7rem;
  text-align: right;
  background: url(/assets/images/sign2.png) no-repeat 100% 0% / 13rem;
  padding-right: 3rem;
}  



 .eatl-business7 .section .list-table th {
   font-size: 3rem;
   height: 12rem;
 }
  .eatl-business7 .section .list-table td {
  font-size: 2.8rem;
  height: 12rem;
  line-height: 4rem;
}
  .eatl-business7 .section .table-wrap {
    width: 100%;
    overflow-x: auto;
  }
  .eatl-business7 .section .table-wrap .list-table {
    width: 160rem;
  }
}

/* 부설에너지기기시험원 - 사업안내 -  제품인증(KAS) */
.eatl-business8 {
  padding-bottom: 100px;
}

@media screen and (max-width: 720px) {
  .eatl-business8 {
    padding-bottom: 10rem;
 }
}

/* 준비중 page */
.prepare-div {
  font-size: 44px;
  color: #2b3038;
  letter-spacing: -2px;
  text-align: center;
  height: 510px;
  padding-top: 285px;
  background: url(/assets/images/icon_prepare.png) no-repeat 50% 180px;
  border: 1px solid #e9e9e9;
  border-top: 2px solid #2b3038;
}

@media screen and (max-width: 720px) {
  .prepare-div {
    font-size: 4rem;
    height: 50rem;
    padding-top: 30rem;
    background: url(/assets/images/icon_prepare.png) no-repeat 50% 14rem / 10rem;
  }  
}  