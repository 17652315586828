@charset "UTF-8";

/*******************************************************************************
 ABOUT KRAIA > Introduction & Brief History
*******************************************************************************/

.eng-history .history-area {
  margin: 25px auto 150px;
}

.eng-history .history-area .history-txt {
	font-family: 'Titillium Web', gulim;
	font-size: 30px;
	font-weight: 400;
	line-height: 35px;
	text-align: center;
	margin-bottom: 120px;
}    

.eng-history .history-area .history-txt span {
 font-weight: 700;
}

.eng-history .history {
  padding: 0px 0px 80px;
  margin-left: 80px;
  border-left: 1px solid #d4d4d4;
  font-family: 'Titillium Web', gulim;
}
 
.eng-history .history .history-li {
  position: relative;
  clear: both;
}

.eng-history .history .history-li .list {
  padding: 0px 0px 30px 50px; 
}
 
.eng-history .history .history-li .list:before {
  content: '';
  position: absolute;
  display: block;
  left: -7px;
  top: 0px;
  width: 15px;
  height: 15px;
  background-color: #c3cbe3;
  border-radius: 50%;
} 
      
.eng-history .history .history-li .yy {
  float: left;
  font-size: 30px;
  font-weight: 700;
  color: #222;
  letter-spacing: -1px;
  line-height: 20px;
}
    
.eng-history .history .history-li .year-list {
  float: left;
  padding: 0px 0px 30px 40px;
}
      
.eng-history .history .history-li .year-list li {
  clear: both;
  padding-bottom: 20px;
}
    
.eng-history .history .history-li .year-list li .date {
  float: left;
  width: 125px;
  font-size: 20px;
  font-weight: 400;
  color: #555;
  letter-spacing: 0px;
  line-height: 20px;
}
        
.eng-history .history .history-li .year-list li .txt {
  float: left;
  font-size: 20px;
  font-weight: 400;
  color: #555;
  letter-spacing: 0px;
  line-height: 20px;  
  letter-spacing: -0.5px; 
}          
          
.eng-history .history .history-li .year-list li .txt .line {
  display: block;
  margin-bottom: 10px;
}   
      
.eng-history .history .history-li .year-list li:after {
  content: '';
  display: block;
  clear: both;
}  

/*******************************************************************************
 ABOUT KRAIA > Organization
*******************************************************************************/      
.eng-organization.organization .img {
   width: 891px;
   margin: 0 auto;
}    

.eng-organization.organization .img img {
  width: 100%;
}  
  
.eng-organization.organization .list-table {
  margin: 110px 0px 210px;
}    

.eng-organization.organization .list-table td {
  text-align: left;
  padding: 0px 20px;
}

.eng-organization.organization .list-table .link {
  display: inline-block;
  width: 115px;
  height: 30px;
  background-color: #65bfb8;
  border-radius: 3px;
  margin-right: 30px;
  font-family: 'Titillium Web', gulim;
  font-size: 14px;
  font-weight: 600;
  color: #fff;
  line-height: 30px;
  text-align: center;      
}
    
.eng-organization.organization .list-table .link:hover {
  text-decoration: none;
}

/*******************************************************************************
 ABOUT KRAIA > Visit KRAIA
*******************************************************************************/      
.eng-map .map-area {
  text-align: center;
}  

.eng-map .map-area .map {
  overflow: hidden;
}
  
.eng-map .map-area .address {
  display: inline-block;
  font-family: 'Titillium Web', gulim;
  font-size: 24px;
  font-weight: 300;    
  color: #222;
  letter-spacing: 0px;
  line-height: 35px;
  text-align: left;
  padding-left: 130px;
  background: url(/assets/images/icon_map.png) no-repeat 0% 50%;
  margin: 45px 0px 100px;
}    

.eng-map .map-area .address span {
  display: inline-block;
  width: 1px;
  height: 15px;
  margin: 0 15px;
  background-color: #ddd;
}

/*******************************************************************************
 Activities > Domestic Activities
*******************************************************************************/      
.eng-domestic.activities {
  margin-bottom: 150px;
}

.eng-domestic.activities .activities1 {
  text-align: center;
  background-color: #edf3fc;
  padding: 70px;
  margin-bottom: 50px;
  
  font-family: 'Titillium Web', gulim;
  font-size: 28px;
  font-weight: 300;
  color: #222;
  letter-spacing: 0px;
  line-height: 35px;
}    

.eng-domestic.activities .activities1 span {
  font-size: 22px;
}

.eng-domestic.activities .section-title {
  font-family: 'Titillium Web', gulim;
  font-size: 20px;
  font-weight: 700;
  color: #222;
  letter-spacing: 0px;
  line-height: 24px;  
  margin: 0px 0px 15px;
}
  
.eng-domestic.activities .section-title:before {
  content: "\2022";
  color: #3d569d;
  font-weight: bold;
  display: inline-block; 
  width: 7px;
  margin: 0px 7px 0px 0px;      
}  

.eng-domestic.activities .htxt {
  font-family: 'Titillium Web', gulim;
  font-size: 16px;
  color: #3d569d;
  font-weight: 700;
  letter-spacing: 0px;
  margin: 10px 0px 15px 15px;
}

.eng-domestic.activities .txt {
  font-family: 'Titillium Web', gulim;
  font-size: 16px;
  color: #222;
  font-weight: 400;
  letter-spacing: 0px;
  line-height: 25px;  
  margin: 0px 0px 40px 15px;  
}

/*******************************************************************************
 Activities > International Activities
*******************************************************************************/      
.eng-international.activities {
  margin-bottom: 150px;
}

.eng-international.activities .section-title {
  font-family: 'Titillium Web', gulim;
  font-size: 20px;
  font-weight: 700;
  color: #222;
  letter-spacing: 0px;
  line-height: 24px;  
  margin: 0px 0px 15px;
}
  
.eng-international.activities .section-title:before {
  content: "\2022";
  color: #3d569d;
  font-weight: bold;
  display: inline-block; 
  width: 7px;
  margin: 0px 7px 0px 0px;      
}  

.eng-international.activities .htxt {
  font-family: 'Titillium Web', gulim;
  font-size: 16px;
  color: #3d569d;
  font-weight: 700;
  letter-spacing: 0px;
  margin: 10px 0px 15px 15px;
}

.eng-international.activities .txt {
  font-family: 'Titillium Web', gulim;
  font-size: 16px;
  color: #222;
  font-weight: 400;
  letter-spacing: 0px;
  line-height: 25px;  
  margin: 0px 0px 40px 15px;  
}

/*******************************************************************************
 PRODUCT SECTIONS > Items covered by KRAIA
*******************************************************************************/      

.eng-procuct .section-title {
  font-family: 'Titillium Web', gulim;
  font-size: 20px;
  font-weight: 700;
  color: #222;
  letter-spacing: 0px;
  line-height: 24px;  
  margin: 0px 0px 15px;
}

.eng-procuct .section-title:before {
  content: "\2022";
  color: #3d569d;
  font-weight: bold;
  display: inline-block; 
  width: 7px;
  margin: 0px 7px 0px 0px;      
}    

.eng-procuct .list-table {
  margin-bottom: 150px;
}
  
.eng-procuct .list-table td {
  font-family: 'Titillium Web', gulim;
  lettre-spacing: 0px;
  border: 1px solid #d4d4d4;
}
  
.eng-procuct .list-table tr:nth-child(1) > td {
  border-top: none;
}
  
.eng-procuct .list-table .color1 {
  background-color: #edf3fc;
}

/*******************************************************************************
 HARFKO > About HARFKO
*******************************************************************************/   
.eng-harfko .harfko-img {
  text-align: center;
}

.eng-harfko table {
  width: 1000px;
  margin: 100px auto;
}

.eng-harfko table tr {
  height: 54px !important;
}  
  
.eng-harfko table th, .eng-harfko table td {
  font-family: 'Titillium Web', gulim;
  font-size: 16px;
  letter-spacing: 0px;
}

.eng-harfko table th {
	width: 30%;
  font-weight: bold;  
}
  
.eng-harfko table td {
  width: 70%;
  text-align: left;
  padding: 10px 15px !important;
}

.eng-harfko .to-harfko {
  text-align: center;
  background-color: #edf3fc;
  padding: 90px 0px 95px;
}  

.eng-harfko .to-harfko .harfko1 {
  display: inline-block;
}
  
.eng-harfko .to-harfko .img {
  float: left;
  width: 118px;
  margin-right: 30px;
}
  
.eng-harfko .to-harfko .txt {
  float: left;
  height: 89px;
  
  font-family: 'Titillium Web', gulim;
  font-size: 28px;
  font-weight: 300;
  color: #222;
  line-height: 35px;
  text-align: left;
  padding-top: 20px;
}
  
.eng-harfko .to-harfko .btn1 {
  display: inline-block;
  width: 160px;
  height: 45px;
  line-height: 45px;
  font-family: 'Titillium Web', gulim;
  font-size: 18px;
  font-weight: 400;
  color: #fff;
  background-color: #3d569d;  
  border-radius: 3px;
  margin-top: 30px !important;
  text-align: center;
}
.eng-harfko .to-harfko .btn1 span {
  display: inline-blcok;
  padding-right: 20px;
  background: url(/assets/images/s_icon_link.png) no-repeat 100% 50%;
}
  
.eng-harfko .to-harfko .btn1:hover {
  text-decoration: none;
}
  