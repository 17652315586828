/* You can add global styles to this file, and also import other style files */

/* font load */
@import url('./assets/o2oz_icon/style.css');
@import url('https://fonts.googleapis.com/css?family=Material+Icons|Material+Icons+Outlined|Material+Icons+Two+Tone|Material+Icons+Round|Material+Icons+Sharp');

/* library css */
@import '../node_modules/jqueryui/jquery-ui.min.css';
@import '../node_modules/ngx-toastr/toastr.css';
@import '~@angular/material/prebuilt-themes/indigo-pink.css';
@import '~@angular/material/theming';
@import '~ag-grid-community/dist/styles/ag-grid.css';
@import '~ag-grid-community/dist/styles/ag-theme-balham.css';
@import '~bootstrap/scss/bootstrap';
@import '../node_modules/summernote/dist/summernote-lite.css';
@import '../node_modules/@pioneer-code/pioneer-tree/scss/pioneer-tree.scss';
@import '~@circlon/angular-tree-component/css/angular-tree-component.css';

/* default css */
@import url('./assets/css/default.css');
@import url('./assets/css/common.css');
@import url('./assets/css/font.css');
@import url('./assets/css/ag-theme-balham-old.css');
@import url('./assets/select2/dist/css/select2.min.css');

/* 관리자 */
.admin-body {
  background-color: #dce1e8;
  min-width: 1600px;
  height:100%;
  overflow: auto;
}

.admin-login-header {
  position: fixed;
  width: 100%;
  height: 60px;
  padding: 0px;
  background-color: #fff;
  z-index: 99999;

  .top-logo {
    margin: 13px 0px;
    float:left;
    padding-left:20px;
  }

  .icon-area {
    float:right;
    padding-right:20px;
  }

  i {
    line-height: 60px;
    font-size: 26px;
    color: #737d8a;
  }

  .right-icon {
    font-size: 25px;
    height: 60px;
    line-height: 60px;
    margin-right: 20px;
  }
}

.admin-contents {
  margin-top: 60px;
  margin-left: 230px;
  padding: 20px;
  height: calc(100% - 60px);
}

.admin-contents-area-small .admin-contents {
  margin-left: 70px !important;
}

.contents-title {
  font-family: 'Noto Sans KR', gulim;
  font-size: 22px;
  color: #383838;
  width: 265px;

  .title-bar {
    width: 40px;
    height: 3px;
    background-color: #343b3f;
    margin-bottom: 7px;
  }

  h3 {
    margin: 0px;
    font-size:20px;
  }
}

.widget-area {
  width: calc(100% - 265px);
}

.contents-area-top,
.contents-area {
  height: calc(100% - 60px);
  padding: 20px;
  margin-top: 20px;
  background-color: #fff;
  border-radius: 5px;
  overflow: auto;

  .contents-body {
    border-top:solid 1px #555;
    margin-top:5px;
  }

  .search-condition {
    input {
      height: 30px;
    }

    .btn-group button {
      font-size:12px;
    }

    select {
      height: 30px;
      padding: 0px;
    }

    .as-search {
      width: 230px;
      height: 30px;
    }

    .result-cnt {
      font-weight: 300;
      margin-left: 20px;
    }
  }
}

.contents-area-top {
  height: calc(100% - 80px) !important;
}

.contents-area2 {
  padding: 20px;
  margin-top: 20px;
  background-color: #fff;
  border-radius: 5px;
}

.bottom-area {
  height:20px;
}

.modal {
  z-index: 99999;

  .modal-normal {
    max-width: 600px;
  }

  .modal-large {
    max-width: 940px;
  }

  .modal-footer,
  .modal-header {
    padding: 15px 20px;
  }

  .modal-header .close {
    padding: 0px;
  }

  .modal-title {
    font-size: 18px;
  }

  .modal-body {
    padding: 20px;
  }

  .modal-body td {
    padding: 0 5px;
    text-align: left;
  }

  .modal-body input {
    font-size: 13px;
    height: 27px;
    padding: 0px 12px;
  }
  .modal-body .help-block {
    display: block;
    padding: 5px 12px;
    text-align: left;
  }

  .modal-footer .blue-btn {
    color: #fff !important;
    background-color: #337ab7 !important;
  }

  .modal-footer > * {
    margin: 0 !important;
  }

  .modal-holder{
    animation-name: appear;
    animation-duration: 0.3s;
  }
}

.list-table {
  width: 100%;
  border-top: 1px solid #383838;
  text-align: center;
  font-family: 'Noto Sans KR', gulim;
  font-size: 13px;
  font-weight: 400;
  tr {
    border-bottom: 1px solid #d8d8d8;
    height:auto !important;

    th {
      background-color: #f4f5f7;
      font-weight: 500;
      text-align: center;
      font-size:inherit;
      padding: 9px !important;
    }

    td {
      padding: 9px !important;
      &.tr {text-align: right !important;}
    }
  }

  tr:hover {
    background-color: #f2f2f2;
  }

  .notice {
    background-color: #fff1f3;
  }
  .tl {
    text-align: left;
  }

  input[type="text"], .list-table select {
    height: 25px !important;
    padding: 0px 8px;
    font-size: 13px;
  }

  .btn-group .abtn {
    font-size: 13px;
    height: 25px;
    padding: 0px 10px !important;
    border-right: 0px solid;
  }

  .btn-group .abtn:nth-last-child(1) {
    border-right: 1px solid #ccc;
  }
}

.btn-group {
  .active {
    color: #333;
    background-color: #e6e6e6;
    border-color: #adadad;
    box-shadow: inset 0 3px 5px rgba(0,0,0,.125);
  }

  button {
    border-right: 0px solid;
  }

  button:last-child {
    border-right: 1px solid #ccc;
  }
}

.btn-default {
  background-color: #fff;
  border-color: #ccc;
  /* padding: 3px 12px; */
}

.list-table1, .list-table2 {
  border-top: 1px solid #d8d8d8;
  margin-top: 10px;
}

.list-table2 th {
  background-color: #fff0f5;
  font-weight: 500;
  text-align: center;
}

.list-table2 th, .list-table2	td {
  border-right: 1px solid #ccc;
}

.list-table2 th:nth-last-child(1), .list-table2	td:nth-last-child(1) {
  border-right: 0px solid;
}

.pagination {
  justify-content: center;
  font-size: 15px;
  color: #345fdf;
}

.pagination	a:hover {
  font-weight: bold;
}

.popup-layer .popup-preview-contents img {
  max-width: 100%;
}

@keyframes appear {
  0%   {transform: scale(0.5)}
  100% {transform: scale(1)}
}

ul.pioneer-tree-root ul {
  margin-left: 0 !important;
}

.pioneer-tree-node .pioneer-tree-node-content {
  padding: 0 !important;
}

ul.pioneer-tree-root {
  color: #333 !important;
}

/* angular-tree-node START */
.expand-tree .tree-children.tree-children-no-padding { padding-left: 0 }
.expand-tree .tree-children { padding-left: 20px; overflow: hidden }
.expand-tree .node-drop-slot { display: block; height: 2px }
.expand-tree .node-drop-slot.is-dragging-over { background: #ddffee; height: 20px; border: 2px dotted #888; }
.expand-tree .toggle-children-wrapper-expanded .toggle-children { transform: rotate(90deg) }
.expand-tree .toggle-children-wrapper-collapsed .toggle-children { transform: rotate(0); }
.expand-tree .toggle-children-wrapper {
  padding: 5px 6px 8px 4px;
}
/* tslint:disable */
.expand-tree .toggle-children {
  background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAASCAYAAABSO15qAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAABAhpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNi1jMDY3IDc5LjE1Nzc0NywgMjAxNS8wMy8zMC0yMzo0MDo0MiAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wTU09Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9tbS8iIHhtbG5zOnN0UmVmPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvc1R5cGUvUmVzb3VyY2VSZWYjIiB4bWxuczp4bXA9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC8iIHhtbG5zOmRjPSJodHRwOi8vcHVybC5vcmcvZGMvZWxlbWVudHMvMS4xLyIgeG1wTU06T3JpZ2luYWxEb2N1bWVudElEPSJ1dWlkOjY1RTYzOTA2ODZDRjExREJBNkUyRDg4N0NFQUNCNDA3IiB4bXBNTTpEb2N1bWVudElEPSJ4bXAuZGlkOkYzRkRFQjcxODUzNTExRTU4RTQwRkQwODFEOUZEMEE3IiB4bXBNTTpJbnN0YW5jZUlEPSJ4bXAuaWlkOkYzRkRFQjcwODUzNTExRTU4RTQwRkQwODFEOUZEMEE3IiB4bXA6Q3JlYXRvclRvb2w9IkFkb2JlIFBob3Rvc2hvcCBDQyAyMDE1IChNYWNpbnRvc2gpIj4gPHhtcE1NOkRlcml2ZWRGcm9tIHN0UmVmOmluc3RhbmNlSUQ9InhtcC5paWQ6MTk5NzA1OGEtZDI3OC00NDZkLWE4ODgtNGM4MGQ4YWI1NzNmIiBzdFJlZjpkb2N1bWVudElEPSJhZG9iZTpkb2NpZDpwaG90b3Nob3A6YzRkZmQxMGMtY2NlNS0xMTc4LWE5OGQtY2NkZmM5ODk5YWYwIi8+IDxkYzp0aXRsZT4gPHJkZjpBbHQ+IDxyZGY6bGkgeG1sOmxhbmc9IngtZGVmYXVsdCI+Z2x5cGhpY29uczwvcmRmOmxpPiA8L3JkZjpBbHQ+IDwvZGM6dGl0bGU+IDwvcmRmOkRlc2NyaXB0aW9uPiA8L3JkZjpSREY+IDwveDp4bXBtZXRhPiA8P3hwYWNrZXQgZW5kPSJyIj8+5iogFwAAAGhJREFUeNpiYGBgKABigf///zOQg0EARH4A4gZyDIIZ8B/JoAJKDIDhB0CcQIkBRBtEyABkgxwoMQCGD6AbRKoBGAYxQgXIBRuZGKgAKPIC3QLxArnRSHZCIjspk52ZKMrOFBUoAAEGAKnq593MQAZtAAAAAElFTkSuQmCC');
  height: 8px;
  width: 9px;
  background-size: contain;
  display: inline-block;
  position: relative;
  top: 1px;
  background-repeat: no-repeat;
  background-position: center;
}
.expand-tree .toggle-children-placeholder {
  display: inline-block;
  height: 10px;
  width: 10px;
  position: relative;
  top: 1px;
  padding-right: 3px;
}
.expand-tree .node-content-wrapper {
  display: inline-block;
  padding: 5px;
  border-radius: 2px;
  transition: background-color .15s,box-shadow .15s;
}
.expand-tree .node-wrapper {display: flex; align-items: flex-start;}
.expand-tree .node-content-wrapper-active,
.expand-tree .node-content-wrapper.node-content-wrapper-active:hover,
.expand-tree .node-content-wrapper-active.node-content-wrapper-focused {
  background: #beebff;
}
.expand-tree .node-content-wrapper-focused { background: #e7f4f9 }
.expand-tree .node-content-wrapper:hover { background: #f7fbff }
.expand-tree .node-content-wrapper-active, .node-content-wrapper-focused, .node-content-wrapper:hover {
  box-shadow: inset 0 0 1px #999;
}
.expand-tree .node-content-wrapper.is-dragging-over { background: #ddffee; box-shadow: inset 0 0 1px #999; }
.expand-tree .node-content-wrapper.is-dragging-over-disabled { opacity: 0.5 }

.expand-tree tree-viewport {
  height: 100%;
  overflow: auto;
  display: block;
}
.expand-tree .tree-children { padding-left: 20px }
.expand-tree .empty-tree-drop-slot .node-drop-slot { height: 20px; min-width: 100px }
.expand-tree .angular-tree-component {
  width: 100%;
  position:relative;
  display: inline-block;
  cursor: pointer;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none;   /* Chrome/Safari/Opera */
  -khtml-user-select: none;    /* Konqueror */
  -moz-user-select: none;      /* Firefox */
  -ms-user-select: none;       /* IE/Edge */
  user-select: none;           /* non-prefixed version, currently not supported by any browser */
}

tree-root .angular-tree-component-rtl {
  direction: rtl;
}
tree-root .angular-tree-component-rtl .toggle-children-wrapper-collapsed .toggle-children {
  transform: rotate(180deg) !important;
}
tree-root .angular-tree-component-rtl .tree-children {
  padding-right: 20px;
  padding-left: 0;
}

.expand-tree div.angular-tree-component div.tree-children::before,
.expand-tree div.angular-tree-component::before {
  content: "";
  position: absolute;
  border-left: 1px dotted #23527c;
  height: 100%;
  top: -10px;
  left: 6px
}

.expand-tree div.angular-tree-component {
  padding: 10px;
  margin: 0;
}

.expand-tree div.angular-tree-component div.tree-children {
  position: relative;
  padding-left: 0;
  margin-left: 16px
}

.expand-tree div.angular-tree-component div.tree-children::before {
  left: 5px
}

.expand-tree .node-drop-slot {height:2px;}

.expand-tree div.angular-tree-component tree-node>div>.node-wrapper {
  margin-left: 24px
}

.expand-tree div.angular-tree-component tree-node>div>.node-wrapper>.node-content-wrapper {
  margin-left: 4px
}

.expand-tree div.angular-tree-component tree-node>div.tree-node-leaf .node-wrapper {
  margin-left: 0;
  position: relative;
}

.expand-tree div.angular-tree-component tree-node>div::before {
  content: "";
  position: absolute;
  border-bottom: 1px dotted #23527c;
  width: 7px;
  margin-top: 12px;
  left: 6px
}

.expand-tree div.angular-tree-component tree-node>div .toggle-children-wrapper {
  width: 13px;
  height: 13px;
  border: 1px solid #23527c;
  position: relative;
  margin-top: 5px;
  margin-left: 14px;
  display: inline-block;
  background-color: #fff;
  z-index: 1
}

.expand-tree div.angular-tree-component tree-node>div .toggle-children-wrapper::before {
  content: "";
  display: inline-block;
  width: 7px;
  border-top: 1px solid #23527c;
  position: absolute;
  top: 5px;
  left: 2px
}

.expand-tree div.angular-tree-component tree-node>div .toggle-children-wrapper.toggle-children-wrapper-collapsed::after {
  content: "";
  display: inline-block;
  height: 7px;
  border-left: 1px solid #23527c;
  position: absolute;
  top: 2px;
  left: 5px
}

.expand-tree div.angular-tree-component tree-node>div .toggle-children-wrapper .toggle-children {
  display: none
}

.expand-tree div.angular-tree-component tree-node>div .node-content-wrapper {
  margin: 0;
  // padding: 0 0 0 4px;
  box-shadow: none;
  // background: none;
}

.expand-tree div.angular-tree-component>tree-node>div::before {
  left: 14px
}
/* angular-tree-node END */

#certification_spscheckitem {
  .ag-cell-value, .ag-group-value {
    display: -webkit-box;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
  }
}