* {font-family: 'Noto Sans KR';}
.inner-wrap {max-width: 1280px; width: calc(100% - 80px); margin: 0 auto !important;}
.mobile-only {display: none;}

/* 배경 shape */
.contents-page {
  position: relative;
}
.contents-page .bg1 {
  position: absolute;
  top: 0;
  left: calc(50% - 735px);
  width: 320px;
  height: 220px;
  background: url(/assets/images/contents_bg01.png) no-repeat;
  z-index: -1;
}
.contents-page .bg2 {
  position: absolute;
  left: calc(50% + 555px);
  bottom: 160px;
  width: calc(50% - 555px);
  max-width: 135px;
  height: 135px;
  background: url(/assets/images/contents_bg02.png) no-repeat;
  z-index: -1;
}
.contents-page .bg3 {
  position: absolute;
  bottom: -152px;
  left: calc(50% + 670px);
  width: calc(50% - 670px);
  max-width: 378px;
  height: 352px;
  background: url(/assets/images/contents_bg03.png) no-repeat;
  z-index: -1;
}

/* page title */
.page-title {
  font-size: 36px;
  font-weight: 500;
  color: #0062b7;
  letter-spacing: -2px;
  text-align: center;
  margin: 80px 0 50px;
}

.page-title-bar {
  display: block;
  width: 30px;
  height: 2px;
  background-color: #bdcbdf;
  margin: 0 auto 50px;
}

/* list-table */
.list-table {
  width: 100%;
  /* border-top: 2px solid #2b3038 !important; */
}
.list-table tr:hover {
  background-color: transparent !important;
}
.list-table th {
  font-size: 16px;
  font-weight: 500;
  color: #2b3038;
  line-height: 18px;
  text-align: center;
  height: 50px;
  background-color: #f8f9f9;
  border: 1px solid #e9e9e9;
}
.list-table td {
  font-size: 14px;
  /* color: #2b3038; */
  line-height: 18px;
  text-align: center;
  height: 50px;
  border: 1px solid #e9e9e9;
}
.blue1{
  color: #539bff !important;
}
.list-table tr th:nth-child(1), .list-table tr td:nth-child(1) {
  border-left: none;
}
.list-table tr th:nth-last-child(1), .list-table tr td:nth-last-child(1) {
  border-right: none;
}

/* list-table-business */
.list-table-business {
  width: 100%;
}

.list-table-business th {
  font-size: 16px;
  font-weight: 500;
  color: #2b3038;
  line-height: 18px;
  text-align: center;
  height: 50px;
  background-color: #f8f9f9;
  border: 1px solid #ddd;
}
.list-table-business td {
  font-size: 14px;
  /* color: #2b3038; */
  line-height: 18px;
  text-align: center;
  height: 50px;
  border: 1px solid #ddd;
}

/* list-table-work */
.list-table-work {
  width: 100%;
}

.list-table-work th {
  font-size: 16px;
  font-weight: 500;
  color: #2b3038;
  line-height: 18px;
  text-align: center;
  height: 50px;
  background-color: #f8f9f9;
  border: 1px solid #ddd;
}
.list-table-work td {
  font-size: 14px;
  /* color: #2b3038; */
  line-height: 18px;
  text-align: center;
  height: 30px;
  border: 1px solid #ddd;
}



/* pagination */
.pagination .page-item .page-link {
  font-size: 13px;
  color: #2b3038;
  line-height: 30px;
  text-align: center;
  width: 32px;
  height: 32px;
  padding: 0;
  margin-right: 6px;
  border-radius: 4px;
  background-color: #fff;
  border: 1px solid #d7d7d7;
}
.pagination .page-item .page-link:focus {
  box-shadow: none;
}
.pagination .page-item.active .page-link {
  background-color: #2b3038;
  border: 1px solid #2b3038;
}
/* .pagination .page-item.disabled:not(:nth-child(-n+2)) .page-link {
  border: none;
}   */

/* modal */
.modal-dialog {
  margin: 0 auto !important;
}
.modal-content {
  border: none !important;
  border-radius: 10px !important;
  overflow: hidden;
}
.modal-header {
  height: 60px;
  padding: 0 30px !important;
  background-color: #0062b7;
}
.modal-header .modal-title {
  font-size: 22px;
  font-weight: 500;
  color: #fff;
  line-height: 60px;
  margin: 0;
}
.modal-header .close {
  float: right;
  width: 14px;
  height: 60px;
  padding: 0;
  background: url(/assets/images/icon_close.png) no-repeat 50%;
  border: none;
  outline: none;
}
.modal-body {
  max-height: 645px;
  overflow-y: auto;
  padding: 20px !important;
}
.modal-footer {
  display: block !important;
  padding: 0 30px 20px !important;
  border: none !important;
}
.modal-footer .btn1 {
  float: left;
  font-size: 16px;
  color: #2b3038;
  width: 85px;
  height: 45px;
  border: 1px solid #d7d7d7;
  background: #fff;
}
.modal-footer .btn1.blue {
  float: right;
  color: #fff;
  background: #174899;
}
.modal-footer .btn-small {
  font-size: 14px !important;
}

.modal-lock .modal-dialog {
  width: 400px;
  max-width: 400px;
}
.modal-company .modal-dialog {
  width: 670px;
  max-width: 670px;
}
.modal-900 .modal-dialog {
  width: 900px;
  max-width: 900px;
}
.modal-1100 .modal-dialog {
  width: 1100px;
  max-width: 1100px;
}

/* main visual slide */
.main-visual .main_custom_paging {
  position: absolute;
  top: 500px;
  left: -740px;
}
.main-visual .main_custom_paging li {
  display: none;
}
.main-visual .main_custom_paging .slick-active {
  display: block;
  width: 65px;
  font-size: 18px;
  letter-spacing: 0px;
  color: #30354b;
  line-height: 40px;
  text-align: center;
}
.main-visual .main_custom_paging .slick-active > span {
  font-weight: 500;
}

.main-visual .slick-prev, .main-visual .slick-next {
  position: absolute;
  top: 520px;
  left: -780px;
  width: 40px;
  height: 40px;
  background: #fff;
  border: 1px solid #e5e5e5;
  color: #2b3038;
}
.main-visual .slick-next {
  left: -490px;
}
.main-visual .slick-prev:before {
  display: inline-block;
  content: '';
  width: 9px;
  height: 14px;
  background: #fff url(/assets/images/icon_sprev.png) no-repeat 50%;
}
.main-visual .slick-next:before {
  display: inline-block;
  content: '';
  width: 9px;
  height: 14px;
  background: url(/assets/images/icon_snext.png) no-repeat 50%;
}
/* business slide */
.business .slick-prev, .business .slick-next {
  position: absolute;
  top: 50%;
  left: calc(50% - 215px);
  width: 28px;
  height: 52px;
  border: none;
  color: #2b3038;
  z-index: 999;
}
.business .slick-next {
  left: calc(50% + 185px);
}
.business .carousel.before-slide .slick-prev {
  left: calc(50% - 375px);
}
.business .carousel.before-slide .slick-next {
  left: calc(50% + 147px);
}

.business .slick-prev:before {
  display: inline-block;
  content: '';
  width: 28px;
  height: 52px;
  background: url(/assets/images/icon_prev.png) no-repeat 50%;
}
.business .slick-next:before {
  display: inline-block;
  content: '';
  width: 28px;
  height: 52px;
  background: url(/assets/images/icon_next.png) no-repeat 50%;
}

/* page 이동 tab */
.sub-tab {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  border: 1px solid #e9e9e9;
  border-right: none;
  border-bottom: none;
  margin-top: 60px !important;
}
.sub-tab > li {
  flex: 1;
  min-width: 159px;
}
.sub-tab > li > a {
  display:flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  /* width: calc(100% / 2); */
  height: 70px;
  font-size: 16px;
  color: #888;
  line-height: 20px;
  text-align: center;
  background: #f8f9f9;
  border-right: 1px solid #e9e9e9;
  border-bottom: 1px solid #e9e9e9;
  cursor: pointer;
}
.sub-tab > li > a.active {
  color: #fff;
  font-weight: 500;
  background: #0062b7;
  border-right: 1px solid #0062b7;
}

/* sub-tab */
.submenu-tab-ul {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin: 75px 0 35px !important;
}
.submenu-tab-ul .nav-item {
  flex: 1;
  max-width: calc((100% - 90px) /4);
  height: 50px;
  margin-right: 30px;
  cursor: pointer;
}
.submenu-tab-ul .nav-item > span {
  display: block;
  font-size: 22px;
  color: #888;
  line-height: 48px;
  height: 50px;
  text-align: center;
  padding: 0;
  border-radius: 25px;
  background-color: #f8f9f9;
  border: 1px solid #e9e9e9;
}
.submenu-tab-ul .nav-item > span.active {
  color: #fff;
  font-weight: bold;
  background: #0176dc;
}
.submenu-tab-ul .nav-item:nth-last-child(1) {
  margin-right: 0;
}

/* admin 버튼 */
.btn-small-icon {
  padding: 2px 5px 0!important;
  line-height: 23px!important;
}
.btn-grid1 {
  font-size: 12px !important;
  padding: 0 5px !important;
}
.btn-grid2 {
  width: 20px;
  height: 20px;
  padding: 0!important;
  font-size: 12px!important;
}
.btn-search {
  height: 28px;
  margin-right: 5px !important;
}

/* admin modal */
.modal-admin {z-index: 1050 !important;}
.modal-admin .modal-body {
  padding: 15px !important;
}
.modal-admin .modal-footer {
  padding: 15px !important;
  border-top: 1px solid #dee2e6 !important;
}
.modal-a600 .modal-lg {
  width: 600px;
  max-width: 600px;
}
.modal-a830 .modal-lg {
  width: 830px;
  max-width: 830px;
}

.modal-a850 .modal-lg {
  width: 850px;
  max-width: 850px;
}

.modal-a900 .modal-lg {
  width: 900px;
  max-width: 900px;
}

.modal-a1200 .modal-lg {
  width: 1200px;
  max-width: 1200px;
}

.modal-a1500 .modal-lg {
  width: 1500px;
  max-width: 1500px;
}

.calendar-input {
  background: url(/assets/images/calendar.png) no-repeat calc(100% - 7px) 50%;
  background-color: #f3f3f5!important;
  background-size: 1.8vh;
  cursor: pointer;
}

@media screen and (max-width:1600px) {
  /* main visual slide */
  .main-visual .main_custom_paging {
    left: -710px;
  }
  .main-visual .slick-prev, .main-visual .slick-next {
    left: -750px;
  }
  .main-visual .slick-next {
    left: -460px;
  }
}

@media screen and (max-width:1200px) {
  /* main visual slide */
  .main-visual .main_custom_paging {
    left: -340px;
  }
  .main-visual .slick-prev, .main-visual .slick-next {
    left: -380px;
  }
  .main-visual .slick-next {
    left: -90px;
  }
}


@media screen and (max-width:720px) {
  html {font-size: 1.39vw;} /* 10px */
  .inner-wrap {width: 100%; padding: 0 4rem; margin: 0 auto;}

  /* main visual slide */
  .main-visual .main_custom_paging {
    top: 37.5rem;
    left: 11rem;
    width: 9rem;
  }
  .main-visual .main_custom_paging .slick-active {
    font-size: 3.6rem;
    letter-spacing: -1px;
    line-height: 7rem;
    width: 9rem;
  }
  .main-visual .slick-prev, .main-visual .slick-next {
    position: absolute;
    top: 41.5rem;
    left: 4rem;
    width: 7rem;
    height: 7rem;
    background: #fff;
    border: 1px solid #e5e5e5;
    color: #2b3038;
  }
  .main-visual .slick-next {
    left: 20rem;
  }
  .main-visual .slick-prev:before {
    display: inline-block;
    content: '';
    width: 2rem;
    height: 3.6rem;
    background: #fff url(/assets/images/icon_sprevm.png) no-repeat 50% / 2rem auto;
  }
  .main-visual .slick-next:before {
    display: inline-block;
    content: '';
    width: 2rem;
    height: 3.6rem;
    background: url(/assets/images/icon_snextm.png) no-repeat 50% / 2rem auto;
  }

  /* business slide */
  .business .slick-prev, .business .slick-next {
    display: none !important;
  }

  /* select */
  .footer select {
    -webkit-appearance: none; /* 네이티브 외형 감추기 */
    -moz-appearance: none;
    appearance: none;
    background: url(/assets/images/icon_mselect.png) no-repeat;/* 화살표 모양의 이미지 */
    background-position: right 4rem center;
    background-size: 3.6rem 2rem;
    line-height: 1 !important;
  }
  .footer select::-ms-expand { display: none; } /* IE 10, 11의 네이티브 화살표 숨기기 */

  /* page title */
  .page-title {
    font-size: 4.8rem;
    margin: 10rem 0 6rem;
  }

  .page-title-bar {
    width: 5.6rem;
    height: 0.4rem;
    margin: 0 auto 6rem;
  }

  /* tab */
  .sub-tab {
    margin-top: 4rem !important;
  }
  .sub-tab > li {
    min-width: 50%;
  }
  .sub-tab > li > a {
    height: 12rem;
    font-size: 3rem;
    line-height: 3.6rem;
  }

  /* sub-tab */
  .submenu-tab-ul {
    margin: 8rem 0 3.5rem !important;
  }
  .submenu-tab-ul .nav-item {
    max-width: 100%;
    min-width: calc(100% /2);
    height: 12rem;
    margin-right: 0px;
  }
  .submenu-tab-ul .nav-item > span {
    font-size: 3rem;
    line-height: 12rem;
    height: 12rem;
    border-radius: 0;
  }
  .submenu-tab-ul .nav-item > span.active {
    color: #fff;
    font-weight: bold;
    background: #0176dc;
  }

  /* pagination */
  .pagination .page-item .page-link {
    font-size: 2rem;
    line-height: 6rem;
    width: 6rem;
    height: 6rem;
    margin-right: 1rem;
    border-radius: 0.4rem;
  }

  /* list-table */
  .list-table {
    /* width: 100%; */
    border-top: 0.4rem solid #2b3038;
  }
  .list-table th {
    font-size: 3rem;
    /* font-weight: 500; */
    /* color: #2b3038; */
    /* line-height: 18px; */
    /* text-align: center; */
    height: 10rem;
    /* background-color: #f8f9f9; */
    /* border: 1px solid #e9e9e9; */
  }
  .list-table td {
    font-size: 3rem;
    /* color: #2b3038; */
    /* line-height: 18px; */
    /* text-align: center; */
    height: 10rem;
    /* border: 1px solid #e9e9e9; */
  }

  /* modal */
  .modal-header {
    height: 9rem;
    padding: 3rem 4rem !important;
    border-radius: 10px 10px 0 0 !important;
  }
  .modal-header .modal-title {
    font-size: 3rem;
    line-height: 3rem;
  }
  .modal-header .close {
    width: 2.5rem;
    height: 2.5rem;
    background: url(/assets/images/icon_close.png) no-repeat 50% / 2.5rem;
  }
  .modal-body {padding: 4rem !important;}
  .modal-footer {
    padding: 0 4rem 4rem !important;
  }
  .modal-footer .btn1 {
    float: left;
    font-size: 2.8rem;
    width: 24rem;
    height: 9rem;
  }
  .modal-footer .btn1.blue {
    float: right;
    width: 38rem;
  }

  .modal-lock .modal-dialog {
    width: calc(100% - 8rem);
    max-width: calc(100% - 8rem);
  }
  .modal-company .modal-dialog {
    width: 100%;
    max-width: 100%;
  }
}
